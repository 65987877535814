import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import userEvent from '@testing-library/user-event';
import AuthProvider from '../../components/authProvider';
import HeaderLoggedIn from '../../components/HeaderLoggedIn';
import Link from '@mui/material/Link';
import Footer from '../../components/Footer';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function PracticasMasterAccesoAbogacia() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({});

    function handleUserLoggedIn(user){
      setCurrentUser(user)
      console.log("logged in")
  }
  
  function handleUserNotLoggedIn(user){
      console.log("Not logged in")
  }
  
  function handleUserNotRegistered(user){

      console.log("Not registered")
  }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
            <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}
         
      <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >
         
          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                    <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>
                  <Stack sx= {{minHeight: "525px", textAlign: "justify"}}>

                    <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                      <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", paddingRight: "80px"}}>
                  Prácticas Máster Acceso a la Abogacía.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  Las Prácticas del Máster de Acceso a la Abogacía son, para la inmensa mayoría de los estudiantes, la vía de acceso a la abogacía de los negocios. El lugar en el que 
                  el futuro abogado realiza estas prácticas tiene, en la gran mayoría de los casos, un impacto determinante en su carrera profesional.
           
  
  
                      </Typography>
                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingBottom: "20px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  Conseguir una plaza de Prácticas de Máster de Acceso en uno de los despachos despachos de mayor prestigio no es fácil. Y tampoco es fácil elegir en qué despacho hacer las 
                  prácticas, pues son muchos los despachos que ofrecen programas de altísima calidad. 
  
  
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingBottom: "40px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  En Univerlaw ofrecemos a los futuros abogados toda la información -y los medios- que necesitan para lograr una de estas plazas y elegir el despacho adecuado. 
  
  
                      </Typography>
                      
                  </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                        
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
   
                 
      
   </Stack>

   <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>

   <Stack sx={{paddingBottom: "30px", bgcolor: "#ebeef5", opacity: "0.9", paddingLeft: "20%", paddingRight: "20%", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
       
          

       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "75px", paddingBottom: "30px", textAlign: "center"}}>
               Los mejores programas de prácticas de máster de acceso a la abogacía


                   </Typography>

                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textAlign: "justify"}}>
                  El Grupo Jobs&Law publica, desde el año 2015, los rankings de "Mejores despachos de abogados para trabajar en España", que constituyen un referente en el sector legal. 
                  Cada año hacemos miles de encuestas a abogados de los principales despachos, y les preguntamos qué les gusta, y qué no, de trabajar allí. Los resultados se publican de forma 
                  transparente en nuestros rankings, que permiten comprobar en qué aspectos destaca cada despacho.  
                  
           
  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textAlign: "justify"}}>
                  Este año 2024 hemos pedido tamnbién su opinión a los estudiantes que han hecho sus prácticas del máster de acceso a la abogacía en los principales despachos de abogados. 
                  Gracias a sus respuestas, te presentamos el ranking de "Mejores programas de prácticas de máster de acceso a la abogacía".  
                  
           
  
  
                      </Typography>




                      <Button href="https://jobsandlaw.com/rankings" variant="contained" sx={{marginTop: "40px", marginBottom: "40px", alignItems: "center", color:"white", bgcolor: "#4a5b82", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600", width: "700px"}}>Publicación de resultados en septiembre 2024</Button>
  


</Stack>



<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Adquiere los conocimientos necesarios para acceder a los mejores programas de prácticas


                   </Typography>

                   <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Nuestros másteres


                   </Typography>

       </Grid>
                 
                  </Item>
          </Grid>
          </Box>
 





 
          <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >
         
         <Grid xs={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
           <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
 <Grid item xs={12} sm={12} md={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
   <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>

<Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Law School | Derecho de los negocios


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Formación especializada diseñada para el éxito en la abogacía de élite


</Typography>

</Stack>
   </Grid>
           
       
                 <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                 <Stack sx={{height: "50px", bgcolor: "#4a5b82", opacity: "0.9"}}></Stack>
                 <Stack sx= {{textAlign: "justify"}}>

                   <Grid container>
          
                     <Grid item xs={12} sm={12} md={12}>
                     <Stack sx= {{textAlign: "center", bgcolor: "white", opacity: "0.9", paddingLeft: "20px", paddingRight: "20px"}}>
                     <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px"}}>
                  Prácticas Máster Acceso a la Abogacía.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textAlign: "center"}}>
                  Las Prácticas del Máster de Acceso a la Abogacía son, para la inmensa mayoría de los estudiantes, la vía de acceso a la abogacía de los negocios. El lugar en el que 
                  el futuro abogado realiza estas prácticas tiene, en la gran mayoría de los casos, un impacto determinante en su carrera profesional.
           
  
  
                      </Typography>
                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingBottom: "20px", textAlign: "center"}}>
                  Conseguir una plaza de Prácticas de Máster de Acceso en uno de los despachos despachos de mayor prestigio no es fácil. Y tampoco es fácil elegir en qué despacho hacer las 
                  prácticas, pues son muchos los despachos que ofrecen programas de altísima calidad. 
  
  
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingBottom: "40px", textAlign: "center"}}>
                  En Univerlaw ofrecemos a los futuros abogados toda la información -y los medios- que necesitan para lograr una de estas plazas y elegir el despacho adecuado. 
  
  
                      </Typography>
                     
                 </Stack>
                       </Grid>
                 
                   </Grid>
  
                
     
  </Stack>



  <Stack sx={{paddingBottom: "30px", bgcolor: "#ebeef5", opacity: "0.9", justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center", paddingLeft: "20px", paddingRight: "20px"}}>
      
         

  <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "75px", paddingBottom: "30px", textAlign: "center"}}>
               Los mejores programas de prácticas de máster de acceso a la abogacía


                   </Typography>

                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textAlign: "justify"}}>
                  El Grupo Jobs&Law publica, desde el año 2015, los rankings de "Mejores despachos de abogados para trabajar en España", que constituyen un referente en el sector legal. 
                  Cada año hacemos miles de encuestas a abogados de los principales despachos, y les preguntamos qué les gusta, y qué no, de trabajar allí. Los resultados se publican de forma 
                  transparente en nuestros rankings, que permiten comprobar en qué aspectos destaca cada despacho.  
                  
           
  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textAlign: "justify"}}>
                  Este año 2024 hemos pedido tamnbién su opinión a los estudiantes que han hecho sus prácticas del máster de acceso a la abogacía en los principales despachos de abogados. 
                  Gracias a sus respuestas, te presentamos el ranking de "Mejores programas de prácticas de máster de acceso a la abogacía".  
                  
           
  
  
                      </Typography>




                      <Button href="https://jobsandlaw.com/rankings" variant="contained" sx={{marginTop: "40px", marginBottom: "40px", alignItems: "center", color:"white", bgcolor: "#4a5b82", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600", width: "80%"}}>Publicación de resultados en septiembre 2024</Button>
  


 










 

</Stack>




                
                 </Item>
         </Grid>
         </Box> 
          













         <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >

         <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
       
                        
       
 
  

     <Item sx={{marginRight: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "390px", maxHeight: "600px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Derecho Procesal Civil
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
           Defiende a empresas multinacionales en sus litigios más complejos
   
   
               </Typography>
               <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Saber más
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
           Matrícula abierta
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
 
       <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Fiscalidad de las Empresas
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
           Asesora a grandes multinacionales en sus cuestiones fiscales clave
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 2 septiembre 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
  
 
 
     <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Derecho Concursal
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
           Asesora a deudores y acreedores en escenarios de insolvencia
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 3 junio 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
  
   
 
                         </Stack>
 
                         <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
      
     
   
 
     <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Tech Law
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
           Domina el derecho de las nuevas tecnologías y asesora a las BigTech
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 2 septiembre 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
  
   
 
     <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> White Collar Law
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
           Conviértete en un abogado penalista experto especializado en delitos económicos
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 2 septiembre 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
 
   
  
 
 
                         </Stack>
                
  
    
  
  


          <Stack  sx={{bgcolor: "#4a5b82", paddingBottom: "30px", paddingTop: "50px", marginTop: "100px"}}>


<Typography variant="h3" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "45px", paddingBottom: "50px", textAlign: "center"}}>
        Blog: <br></br> Resuelve tus dudas


            </Typography>
            
            <Stack direction="row" sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center"}} > 

       
     
   
  
 
    <Item sx={{bgcolor: "#d5dbe8", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px", marginBottom: "30px"}}>
          ¿Me contratarán después de las prácticas de máster de acceso?
  
  
              </Typography>

              <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "0px", marginBottom: "30px"}}>
              La probabilidad de que continúes en el despacho tras las prácticas depende de dos factores: tu desempeño y el despacho que hayas elegido.
  
  
              </Typography>
         
              <Button variant="contained" href="./me-contrataran-despues-de-las-practicas-de-master-de-acceso/" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Leer
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 

      <Item sx={{bgcolor: "#d5dbe8", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px", alignItems: "center"}}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px", marginBottom: "30px"}}>
          ¿A qué tipo de despacho puedo acceder?
  
  
              </Typography>
              <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "0px", marginBottom: "30px"}}>
              No renuncies a nada, e intenta hacer tus prácticas del máster de acceso a la abogacía en el tipo de despacho que más te guste. No obstante, también debes ser realista: haz un análisis objetivo de tu perfil y estate preparado para buscar alternativas.
  
  
              </Typography>
           
         
              <Button variant="contained" href="./en-que-tipo-de-despacho-tengo-posibilidades-de-hacer-las-practicas-del-master-de-acceso/" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Leer
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
   

      <Item sx={{bgcolor: "#d5dbe8", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px", alignItems: "center"}}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px", marginBottom: "30px"}}>
          ¿En qué despacho hago las prácticas del máster de acceso?
  
  
              </Typography>

              <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "0px", marginBottom: "30px"}}>
              El despacho en el que hagas tus prácticas de máster de acceso a la abogacía marcará tu carrera profesional. Aquí te ofrecemos las claves que debes tener en cuenta para elegir el despacho idóneo para ti.
  
  
              </Typography>
         
              <Button variant="contained" href="./en-que-despacho-hago-las-practicas-del-master-de-acceso/" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Leer
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  
  


 
  



  




         </Stack>  


                     <Stack direction="row" sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center"}} > 

       
     
   
  
 
    <Item sx={{bgcolor: "#d5dbe8", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px", marginBottom: "30px"}}>
          ¿Cuándo comienzo a buscar mis prácticas del máster?
  
  
              </Typography>

              <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "0px", marginBottom: "30px"}}>
              El proceso de elección del despacho en el que harás las prácticas de máster de acceso es competitivo y agobiante. Asegúrate de empezar los procesos en el momento adecuado y no pierdas oportunidades.
  
  
              </Typography>
         
         
              <Button variant="contained" href="./cuando-comienzo-buscar-practicas-master-acceso/" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Leer
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


   



  
  


 
  



  




         </Stack>    

     

           

</Stack>




</Box>











<Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >


     <Stack sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center"}} > 
     <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Adquiere los conocimientos necesarios para acceder a los mejores programas de prácticas


                   </Typography>

                   <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Nuestros másteres


                   </Typography>
       
     
   
  
 
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

  
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

      <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>


         </Stack>       
  
    
 



 


</Box>




<Footer/>






        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default PracticasMasterAccesoAbogacia