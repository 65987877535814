import React, { useEffect } from 'react'
import { useState } from 'react';
import AuthProvider from '../components/authProvider'
import TextField from '@mui/material/TextField';

import { v4 as uuid } from 'uuid';
import { insertNewPaginaPenal, updatePaginaPenal } from '../firebase/firebase';
import { getPaginasPenal } from '../firebase/firebase';
import Pagina from '../components/Pagina';
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';
import { CssBaseline, Divider, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../customCKEditor.css";
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router';
import Image from 'mui-image';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { storage } from '../firebase/firebase';
import { uploadBytes } from 'firebase/storage';
import {ref, listAll, getDownloadURL} from 'firebase/storage';

function CreatorPenal() {


  
  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

  const navigate = useNavigate();

    const [title, setTitle] = useState("")
    const [currentUser, setCurrentUser] = useState({})
    const [paginas, setPaginas] = useState([])
    const [numSteps, setNumSteps] = useState(1)
    const [step1, setStep1] = useState("")
    const [step2, setStep2] = useState("")
    const [step3, setStep3] = useState("")
    const steps = [];
    const [texto, setTexto] = useState("")
    const [texto2, setTexto2] = useState("")
    const [texto3, setTexto3] = useState("")
    const [curso, setCurso] = useState("penal")
    const [paginar, setPaginar] = useState()
    const [tema, setTema] = useState(0)
    const [nombreTema, setNombreTema] = useState("")
    const [nombreDiapo, setNombreDiapo] = useState("")
    const [capitulo, setCapitulo] = useState(0)

    const [numId, setNumId] = useState()
    const [pags, setPags] = useState([]);

    const [tipoDiapo, setTipoDiapo] = useState("")
    const [introTestIntro, setIntroTestIntro] = useState("")
    const [pregunta1TestIntro, setPregunta1TestIntro] = useState("")
    const [res1Pre1, setRes1Pre1] = useState("")
    const [res2Pre1, setRes2Pre1] = useState("")
    const [res3Pre1, setRes3Pre1] = useState("")
    const [res4Pre1, setRes4Pre1] = useState("")
    const [resPre1, setResPre1] = useState("")
    const [pregunta2TestIntro, setPregunta2TestIntro] = useState("")
    const [res1Pre2, setRes1Pre2] = useState("")
    const [res2Pre2, setRes2Pre2] = useState("")
    const [res3Pre2, setRes3Pre2] = useState("")
    const [res4Pre2, setRes4Pre2] = useState("")
    const [resPre2, setResPre2] = useState("")
    const [pregunta3TestIntro, setPregunta3TestIntro] = useState("")
    const [res1Pre3, setRes1Pre3] = useState("")
    const [res2Pre3, setRes2Pre3] = useState("")
    const [res3Pre3, setRes3Pre3] = useState("")
    const [res4Pre3, setRes4Pre3] = useState("")
    const [resPre3, setResPre3] = useState("")
    const [pregunta4TestIntro, setPregunta4TestIntro] = useState("")
    const [res1Pre4, setRes1Pre4] = useState("")
    const [res2Pre4, setRes2Pre4] = useState("")
    const [res3Pre4, setRes3Pre4] = useState("")
    const [res4Pre4, setRes4Pre4] = useState("")
    const [resPre4, setResPre4] = useState("")
    const [pregunta5TestIntro, setPregunta5TestIntro] = useState("")
    const [res1Pre5, setRes1Pre5] = useState("")
    const [res2Pre5, setRes2Pre5] = useState("")
    const [res3Pre5, setRes3Pre5] = useState("")
    const [res4Pre5, setRes4Pre5] = useState("")
    const [resPre5, setResPre5] = useState("")
    const [pregunta6TestIntro, setPregunta6TestIntro] = useState("")
    const [res1Pre6, setRes1Pre6] = useState("")
    const [res2Pre6, setRes2Pre6] = useState("")
    const [res3Pre6, setRes3Pre6] = useState("")
    const [res4Pre6, setRes4Pre6] = useState("")
    const [resPre6, setResPre6] = useState("")
    const [pregunta7TestIntro, setPregunta7TestIntro] = useState("")
    const [res1Pre7, setRes1Pre7] = useState("")
    const [res2Pre7, setRes2Pre7] = useState("")
    const [res3Pre7, setRes3Pre7] = useState("")
    const [res4Pre7, setRes4Pre7] = useState("")
    const [resPre7, setResPre7] = useState("")
    const [pregunta8TestIntro, setPregunta8TestIntro] = useState("")
    const [res1Pre8, setRes1Pre8] = useState("")
    const [res2Pre8, setRes2Pre8] = useState("")
    const [res3Pre8, setRes3Pre8] = useState("")
    const [res4Pre8, setRes4Pre8] = useState("")
    const [resPre8, setResPre8] = useState("")
    const [pregunta9TestIntro, setPregunta9TestIntro] = useState("")
    const [res1Pre9, setRes1Pre9] = useState("")
    const [res2Pre9, setRes2Pre9] = useState("")
    const [res3Pre9, setRes3Pre9] = useState("")
    const [res4Pre9, setRes4Pre9] = useState("")
    const [resPre9, setResPre9] = useState("")
    const [pregunta10TestIntro, setPregunta10TestIntro] = useState("")
    const [res1Pre10, setRes1Pre10] = useState("")
    const [res2Pre10, setRes2Pre10] = useState("")
    const [res3Pre10, setRes3Pre10] = useState("")
    const [res4Pre10, setRes4Pre10] = useState("")
    const [resPre10, setResPre10] = useState("")
    const [documentoPrincipal, setDocumentoPrincipal] = useState("")
    const [documento2Principal, setDocumento2Principal] = useState("")
    const [documento3Principal, setDocumento3Principal] = useState("")
    const [documento4Principal, setDocumento4Principal] = useState("")
    const [documento5Principal, setDocumento5Principal] = useState("")
    const [nombredoc1, setnombredoc1] = useState("")
    const [nombredoc2, setnombredoc2] = useState("")
    const [nombredoc3, setnombredoc3] = useState("")
    const [nombredoc4, setnombredoc4] = useState("")
    const [nombredoc5, setnombredoc5] = useState("")









    function handleButtonRemoveStep2 () {
      const newNumSteps = numSteps - 1;
      setNumSteps(newNumSteps);
      setStep1(""); 
      setStep2(""); 
      setStep3(""); 

     }



    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [index, setIndex] = useState(1)

  const ordenes = []
const modifiedOrdenes = paginas.map(function(element) {
  ordenes.push(element.paginar)
})

console.log(ordenes)

const [mainDocUpload, setMainDocUpload] = useState(null)
const [docList, setDocList] = useState([])
const [main2DocUpload, setMain2DocUpload] = useState(null)
const [doc2List, setDoc2List] = useState([])
const [main3DocUpload, setMain3DocUpload] = useState(null)
const [doc3List, setDoc3List] = useState([])
const [main4DocUpload, setMain4DocUpload] = useState(null)
const [doc4List, setDoc4List] = useState([])
const [main5DocUpload, setMain5DocUpload] = useState(null)
const [doc5List, setDoc5List] = useState([])

const docListRef = ref(storage, "documents/")
const doc2ListRef = ref(storage, "documents/")
const doc3ListRef = ref(storage, "documents/")
const doc4ListRef = ref(storage, "documents/")
const doc5ListRef = ref(storage, "documents/")


const uploadMainDocument = () => {

  if (mainDocUpload == null) return;

    const docRef = ref(storage, `documents/${curso + paginar + "documentoprincipal"}`);
  uploadBytes(docRef, mainDocUpload).then(() => {
  
    console.log(mainDocUpload)
    setDocumentoPrincipal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documentoprincipal"}`)
  })   
}


const uploadMain2Document = () => {

  if (main2DocUpload == null) return;

    const docRef = ref(storage, `documents/${curso + paginar + "documento2"}`);
  uploadBytes(docRef, main2DocUpload).then(() => {
  
    console.log(main2DocUpload)
    setDocumento2Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento2"}`)
  })   
}

const uploadMain3Document = () => {

  if (main3DocUpload == null) return;

    const docRef = ref(storage, `documents/${curso + paginar + "documento3"}`);
  uploadBytes(docRef, main3DocUpload).then(() => {

    console.log(main3DocUpload)
    setDocumento3Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento3"}`)
  })   
}

const uploadMain4Document = () => {

  if (main4DocUpload == null) return;

    const docRef = ref(storage, `documents/${curso + paginar + "documento4"}`);
  uploadBytes(docRef, main4DocUpload).then(() => {
   
    console.log(main4DocUpload)
    setDocumento4Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento4"}`)
  })   
}

const uploadMain5Document = () => {

  if (main5DocUpload == null) return;

    const docRef = ref(storage, `documents/${curso + paginar + "documento5"}`);
  uploadBytes(docRef, main5DocUpload).then(() => {
    
    console.log(main5DocUpload)
    setDocumento5Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento5"}`)
  })   
}

useEffect(() => {
  listAll(docListRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url)=> {
        setDocList((prev) => [...prev, url] )
      } )
    } )
  } )
}, [])

useEffect(() => {
  listAll(doc2ListRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url)=> {
        setDoc2List((prev) => [...prev, url] )
      } )
    } )
  } )
}, [])

useEffect(() => {
  listAll(doc3ListRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url)=> {
        setDoc3List((prev) => [...prev, url] )
      } )
    } )
  } )
}, [])

useEffect(() => {
  listAll(doc4ListRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url)=> {
        setDoc4List((prev) => [...prev, url] )
      } )
    } )
  } )
}, [])

useEffect(() => {
  listAll(doc5ListRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url)=> {
        setDoc5List((prev) => [...prev, url] )
      } )
    } )
  } )
}, [])

const ordered = [...paginas].sort((a,b) => a.paginar - b.paginar);

console.log(ordered)

function findOrder (pagina, index1) {
  if (pagina.docId === numId) {
    
    console.log(index1)
    return pagina.docId === numId
  }
}

let myOrder = ordered.find((pagina, index1) => findOrder(pagina, index1));

console.log(ordered.indexOf(myOrder))
const thisIndex = ordered.indexOf(myOrder)
console.log(thisIndex)


console.log(myOrder)

console.log(numId)

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === numSteps - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === numSteps;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    console.log(index)
    setIndex(index+1)
    console.log(index)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIndex(index-1)
    console.log(index)
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    nextSlide()
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };



    async function handleUserLoggedIn(user){
      setCurrentUser(user);  
      const resPaginas = await getPaginasPenal(user.uid);
      setPaginas([...resPaginas]);
      console.log("logged in")
    }
    
    function handleUserNotLoggedIn(user){
        console.log("Not logged in")
    }
    
    function handleUserNotRegistered(user){
        console.log("Not registered")
    }

    function handleSubmit (e) {
       e.preventDefault();
       setDocumentoPrincipal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documentoprincipal"}`)
       setDocumento2Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento2"}`)
       setDocumento3Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento3"}`)
       setDocumento4Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento4"}`)
       setDocumento5Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento5"}`)
       steps.push(step1)
       steps.push(step2)
       uploadMainDocument()
       uploadMain2Document()
       uploadMain3Document()
       uploadMain4Document()
       uploadMain5Document()
       addPagina()
       console.log(steps)
       

       

       }

       function handleChange(e) {
        const value = e.target.value;
        if(e.target.name === "title") {
            setTitle(value);
            console.log(title)
        }

        if(e.target.name === "step1") {
          setStep1(value);
          console.log(step1)
          steps.push(step1)

          if (step2 === "") {
            setNumSteps(1);
            steps.push(step1)
          }
        
      }
    

    if(e.target.name === "step2") {
      setStep2(value);
      console.log(step2)
      steps.push(step2)
      console.log(steps)

      if (step2 !== "" && step3 === "") {
        setNumSteps(2);
        steps.push(step2)
      console.log(steps)
      }
    
      if (step2 === "") {
        setNumSteps(1)
        steps.push(step2)
      console.log(steps)
      }
  }

  if(e.target.name === "step3") {
    setStep3(value);
    console.log(step3)
    steps.push(step3)
    console.log(steps)

    if (step3 !== ""  && step2 !== "") {
      setNumSteps(3);
    }
  
    if (step3 === "") {
      setNumSteps(2)
    }
}


if(e.target.name === "curso") {
  setCurso(value);
  console.log(step1)

}

if(e.target.name === "paginar") {
  setPaginar(value);
  console.log(step1)

}

if(e.target.name === "tema") {
  setTema(value);
  console.log(step1)

}

if(e.target.name === "nombreTema") {
  setNombreTema(value);
  console.log(step1)

}

if(e.target.name === "nombreDiapo") {
  setNombreDiapo(value);
  console.log(step1)

}

if(e.target.name === "capitulo") {
  setCapitulo(value);
  console.log(step1)

}

if(e.target.name === "res1pre1") {
  setRes1Pre1(value);
  console.log(step1)

}

if(e.target.name === "res2pre1") {
  setRes2Pre1(value);
  console.log(step1)

}

if(e.target.name === "res3pre1") {
  setRes3Pre1(value);
  console.log(step1)

}

if(e.target.name === "res4pre1") {
  setRes4Pre1(value);
  console.log(step1)

}

if(e.target.name === "res1pre2") {
  setRes1Pre2(value);
  console.log(step1)

}

if(e.target.name === "res2pre2") {
  setRes2Pre2(value);
  console.log(step1)

}

if(e.target.name === "res3pre2") {
  setRes3Pre2(value);
  console.log(step1)

}

if(e.target.name === "res4pre2") {
  setRes4Pre2(value);
  console.log(step1)

}

if(e.target.name === "res1pre3") {
  setRes1Pre3(value);
  console.log(step1)

}

if(e.target.name === "res2pre3") {
  setRes2Pre3(value);
  console.log(step1)

}

if(e.target.name === "res3pre3") {
  setRes3Pre3(value);
  console.log(step1)

}

if(e.target.name === "res4pre3") {
  setRes4Pre3(value);
  console.log(step1)

}

if(e.target.name === "res1pre4") {
  setRes1Pre4(value);
  console.log(step1)

}

if(e.target.name === "res2pre4") {
  setRes2Pre4(value);
  console.log(step1)

}

if(e.target.name === "res3pre4") {
  setRes3Pre4(value);
  console.log(step1)

}

if(e.target.name === "res4pre4") {
  setRes4Pre4(value);
  console.log(step1)

}

if(e.target.name === "res1pre5") {
  setRes1Pre5(value);
  console.log(step1)

}

if(e.target.name === "res2pre5") {
  setRes2Pre5(value);
  console.log(step1)

}

if(e.target.name === "res3pre5") {
  setRes3Pre5(value);
  console.log(step1)

}

if(e.target.name === "res4pre5") {
  setRes4Pre5(value);
  console.log(step1)

}

if(e.target.name === "res1pre6") {
  setRes1Pre6(value);
  console.log(step1)

}

if(e.target.name === "res2pre6") {
  setRes2Pre6(value);
  console.log(step1)

}

if(e.target.name === "res3pre6") {
  setRes3Pre6(value);
  console.log(step1)

}

if(e.target.name === "res4pre6") {
  setRes4Pre6(value);
  console.log(step1)

}

if(e.target.name === "res1pre2") {
  setRes1Pre2(value);
  console.log(step1)

}

if(e.target.name === "res2pre7") {
  setRes2Pre7(value);
  console.log(step1)

}

if(e.target.name === "res3pre7") {
  setRes3Pre7(value);
  console.log(step1)

}

if(e.target.name === "res4pre7") {
  setRes4Pre7(value);
  console.log(step1)

}

if(e.target.name === "res1pre8") {
  setRes1Pre8(value);
  console.log(step1)

}

if(e.target.name === "res2pre8") {
  setRes2Pre8(value);
  console.log(step1)

}

if(e.target.name === "res3pre8") {
  setRes3Pre8(value);
  console.log(step1)

}

if(e.target.name === "res4pre8") {
  setRes4Pre8(value);
  console.log(step1)

}

if(e.target.name === "res1pre9") {
  setRes1Pre9(value);
  console.log(step1)

}

if(e.target.name === "res2pre9") {
  setRes2Pre9(value);
  console.log(step1)

}

if(e.target.name === "res3pre9") {
  setRes3Pre9(value);
  console.log(step1)

}

if(e.target.name === "res4pre9") {
  setRes4Pre9(value);
  console.log(step1)

}

if(e.target.name === "res1pre10") {
  setRes1Pre10(value);
  console.log(step1)

}

if(e.target.name === "res2pre10") {
  setRes2Pre10(value);
  console.log(step1)

}

if(e.target.name === "res3pre10") {
  setRes3Pre10(value);
  console.log(step1)

}

if(e.target.name === "res4pre10") {
  setRes4Pre10(value);
  console.log(step1)

}

if(e.target.name === "nombredoc1") {
  setnombredoc1(value);
  console.log(step1)

}
if(e.target.name === "nombredoc2") {
  setnombredoc2(value);
  console.log(step1)

}
if(e.target.name === "nombredoc3") {
  setnombredoc3(value);
  console.log(step1)

}
if(e.target.name === "nombredoc4") {
  setnombredoc4(value);
  console.log(step1)

}
if(e.target.name === "nombredoc5") {
  setnombredoc5(value);
  console.log(step1)

}


}

const handleChangeTipoDiapo = (event) => {
  setTipoDiapo(event.target.value);
  console.log(tipoDiapo)

};

const handleChangeResPre1 = (event) => {
  setResPre1(event.target.value);

};

const handleChangeResPre2 = (event) => {
  setResPre2(event.target.value);

};

const handleChangeResPre3 = (event) => {
  setResPre3(event.target.value);

};

const handleChangeResPre4 = (event) => {
  setResPre4(event.target.value);

};

const handleChangeResPre5 = (event) => {
  setResPre5(event.target.value);

};

const handleChangeResPre6 = (event) => {
  setResPre6(event.target.value);

};

const handleChangeResPre7 = (event) => {
  setResPre7(event.target.value);

};

const handleChangeResPre8 = (event) => {
  setResPre8(event.target.value);

};

const handleChangeResPre9 = (event) => {
  setResPre9(event.target.value);

};

const handleChangeResPre10 = (event) => {
  setResPre10(event.target.value);

};

const handleChangeResCorrectaPre1 = (event) => {
  setResPre1(event.target.value);

};

const handleChangeResCorrectaPre2 = (event) => {
  setResPre2(event.target.value);

};

const handleChangeResCorrectaPre3 = (event) => {
  setResPre3(event.target.value);

};

const handleChangeResCorrectaPre4 = (event) => {
  setResPre4(event.target.value);

};

const handleChangeResCorrectaPre5 = (event) => {
  setResPre5(event.target.value);

};

const handleChangeResCorrectaPre6 = (event) => {
  setResPre6(event.target.value);

};

const handleChangeResCorrectaPre7 = (event) => {
  setResPre7(event.target.value);

};

const handleChangeResCorrectaPre8 = (event) => {
  setResPre8(event.target.value);

};

const handleChangeResCorrectaPre9 = (event) => {
  setResPre9(event.target.value);

};

const handleChangeResCorrectaPre10 = (event) => {
  setResPre10(event.target.value);

};

const nextSlide = () => {
    
  console.log(thisIndex)
  const nextIndex = thisIndex+1
  const chosenOne =  ordered[nextIndex]
  console.log(nextIndex)
  console.log(chosenOne)
  console.log(chosenOne.docId)
  navigate(`../masterderechoprocesal/${chosenOne.docId}`);
  window.location.replace('');
}


console.log(step2)
console.log(numSteps)
console.log(step3)

    function addPagina() {
      const newPagina = {
        id: uuid(),
        title: title,
        step1: step1,
        step2: step2,
        step3: step3,
        numSteps: numSteps,
        uid: currentUser.uid,
        texto: texto,
        texto2: texto2,
        texto3: texto3,
        curso: curso,
        paginar: paginar,
        tema: tema,
        nombreTema: nombreTema,
        nombreDiapo: nombreDiapo,
        capitulo: capitulo,
        tipoDiapo: tipoDiapo,
        introTestIntro: introTestIntro,
        pregunta1TestIntro: pregunta1TestIntro,
        res1Pre1: res1Pre1,
        res2Pre1: res2Pre1,
        res3Pre1: res3Pre1,
        res4Pre1: res4Pre1,
        resPre1: resPre1,
        pregunta2TestIntro: pregunta2TestIntro,
        res1Pre2: res1Pre2,
        res2Pre2: res2Pre2,
        res3Pre2: res3Pre2,
        res4Pre2: res4Pre2,
        resPre2: resPre2,
        pregunta3TestIntro: pregunta3TestIntro,
        res1Pre3: res1Pre3,
        res2Pre3: res2Pre3,
        res3Pre3: res3Pre3,
        res4Pre3: res4Pre3,
        resPre3: resPre3,
        pregunta4TestIntro: pregunta4TestIntro,
        res1Pre4: res1Pre4,
        res2Pre4: res2Pre4,
        res3Pre4: res3Pre4,
        res4Pre4: res4Pre4,
        resPre4: resPre4,
        pregunta5TestIntro: pregunta5TestIntro,
        res1Pre5: res1Pre5,
        res2Pre5: res2Pre5,
        res3Pre5: res3Pre5,
        res4Pre5: res4Pre5,
        resPre5: resPre5,
        pregunta6TestIntro: pregunta6TestIntro,
        res1Pre6: res1Pre6,
        res2Pre6: res2Pre6,
        res3Pre6: res3Pre6,
        res4Pre6: res4Pre6,
        resPre6: resPre6,
        pregunta7TestIntro: pregunta7TestIntro,
        res1Pre7: res1Pre7,
        res2Pre7: res2Pre7,
        res3Pre7: res3Pre7,
        res4Pre7: res4Pre7,
        resPre7: resPre7,
        pregunta8TestIntro: pregunta8TestIntro,
        res1Pre8: res1Pre8,
        res2Pre8: res2Pre8,
        res3Pre8: res3Pre8,
        res4Pre8: res4Pre8,
        resPre8: resPre8,
        pregunta9TestIntro: pregunta9TestIntro,
        res1Pre9: res1Pre9,
        res2Pre9: res2Pre9,
        res3Pre9: res3Pre9,
        res4Pre9: res4Pre9,
        resPre9: resPre9,
        pregunta10TestIntro: pregunta10TestIntro,
        res1Pre10: res1Pre10,
        res2Pre10: res2Pre10,
        res3Pre10: res3Pre10,
        res4Pre10: res4Pre10,
        resPre10: resPre10,
        documentoPrincipal: documentoPrincipal,
        documento2Principal: documento2Principal,
        documento3Principal: documento3Principal,
        documento4Principal: documento4Principal,
        documento5Principal: documento5Principal,
        nombredoc1: nombredoc1,
        nombredoc2: nombredoc2,
        nombredoc3: nombredoc3,
        nombredoc4: nombredoc4,
        nombredoc5: nombredoc5,
      
      };
      const res = insertNewPaginaPenal(newPagina)
      newPagina.docId = res.id;
      setTitle("");
      setStep1("");
      setPaginas([...paginas, newPagina])
      
      console.log(paginas)
    }

function handleDelete(){

}

async function handleUpdate(docId, 
  title,
  step1, 
  step2,
  step3,
  numSteps,
  texto,
  texto2,
  texto3,
  curso,
  paginar,
  tema,
  nombreTema,
  nombreDiapo,
  capitulo,
  tipoDiapo,
  introTestIntro,
  pregunta1TestIntro,
  res1Pre1,
  res2Pre1,
  res3Pre1,
  res4Pre1,
  resPre1,
  pregunta2TestIntro,
  res1Pre2,
  res2Pre2,
  res3Pre2,
  resPre2,
  pregunta3TestIntro,
  res1Pre3,
  res2Pre3,
  res3Pre3,
  resPre3,
  pregunta4TestIntro,
  res1Pre4,
  res2Pre4,
  res3Pre4,
  resPre4,
  pregunta5TestIntro,
  res1Pre5,
  res2Pre5,
  res3Pre5,
  resPre5,
  pregunta6TestIntro,
  res1Pre6,
  res2Pre6,
  res3Pre6,
  resPre6,
  pregunta7TestIntro,
  res1Pre7,
  res2Pre7,
  res3Pre7,
  resPre7,
  pregunta8TestIntro,
  res1Pre8,
  res2Pre8,
  res3Pre8,
  resPre8,
  pregunta9TestIntro,
  res1Pre9,
  res2Pre9,
  res3Pre9,
  resPre9,
  pregunta10TestIntro,
  res1Pre10,
  res2Pre10,
  res3Pre10,
  resPre10,
  documentoPrincipal,
  documento2Principal,
  documento3Principal,
  documento4Principal,
  documento5Principal,
  nombredoc1,
  nombredoc2,
  nombredoc3,
  nombredoc4,
  nombredoc5,

){
  
  const pagina = paginas.find((item) => item.docId === docId);
  pagina.title = title;
  pagina.step1 = step1;
  pagina.step2 = step2;
  pagina.step3 = step3;
  pagina.numSteps = numSteps;
  pagina.texto = texto;
  pagina.texto2 = texto2;
  pagina.texto3 = texto3;
  pagina.curso = curso;
  pagina.paginar = paginar;
  pagina.tema = tema;
  pagina.nombreTema = nombreTema;
  pagina.nombreDiapo = nombreDiapo;
  pagina.capitulo = capitulo;
  pagina.tipoDiapo =tipoDiapo;
  pagina.introTestIntro = introTestIntro;
  pagina.pregunta1TestIntro = pregunta1TestIntro;
  pagina.res1Pre1 = res1Pre1;
  pagina.res2Pre1 = res2Pre1;
  pagina.res3Pre1 = res3Pre1;
  pagina.res4Pre1 = res4Pre1;
  pagina.resPre1 = resPre1;
  pagina.pregunta2TestIntro = pregunta2TestIntro;
  pagina.res1Pre2 = res1Pre2;
  pagina.res2Pre2 = res2Pre2;
  pagina.res3Pre2 = res3Pre2;
  pagina.res4Pre2 = res4Pre2;
  pagina.resPre2 = res3Pre2;
  pagina.pregunta3TestIntro = pregunta3TestIntro;
  pagina.res1Pre3 = res1Pre3;
  pagina.res2Pre3 = res2Pre3;
  pagina.res3Pre3 = res3Pre3;
  pagina.res4Pre3 = res4Pre3;
  pagina.resPre3 = resPre3;
  pagina.pregunta4TestIntro = pregunta4TestIntro;
  pagina.res1Pre4 = res1Pre4;
  pagina.res2Pre4 = res2Pre4;
  pagina.res3Pre4 = res3Pre4;
  pagina.res4Pre4 = res4Pre4;
  pagina.resPre4 = res1Pre4;
  pagina.pregunta5TestIntro = pregunta5TestIntro;
  pagina.res1Pre5 = res1Pre5;
  pagina.res2Pre5 = res2Pre5;
  pagina.res3Pre5 = res3Pre5;
  pagina.res4Pre5 = res4Pre5;
  pagina.resPre5 = resPre5;
  pagina.pregunta6TestIntro = pregunta6TestIntro;
  pagina.res1Pre6 = res1Pre6;
  pagina.res2Pre6 = res2Pre6;
  pagina.res3Pre6 = res3Pre6;
  pagina.res4Pre6 = res4Pre6;
  pagina.resPre6 = resPre6;
  pagina.pregunta7TestIntro = pregunta7TestIntro;
  pagina.res1Pre7 = res1Pre7;
  pagina.res2Pre7 = res2Pre7;
  pagina.res3Pre7 = res3Pre7;
  pagina.res4Pre7 = res4Pre7;
  pagina.resPre7 = resPre7;
  pagina.pregunta8TestIntro = pregunta8TestIntro;
  pagina.res1Pre8 = res1Pre8;
  pagina.res2Pre8 = res2Pre8;
  pagina.res3Pre8 = res3Pre8;
  pagina.res4Pre8 = res4Pre8;
  pagina.resPre8 = resPre8;
  pagina.pregunta9TestIntro = pregunta9TestIntro;
  pagina.res1Pre9 = res1Pre9;
  pagina.res2Pre9 = res2Pre9;
  pagina.res3Pre9 = res3Pre9;
  pagina.res4Pre9 = res4Pre9;
  pagina.resPre9 = resPre9;
  pagina.pregunta10TestIntro = pregunta10TestIntro;
  pagina.res1Pre10 = res1Pre10;
  pagina.res2Pre10 = res2Pre10;
  pagina.res3Pre10 = res3Pre10;
  pagina.res4Pre10 = res4Pre10;
  pagina.resPre10 = resPre10;
  pagina.documentoPrincipal = documentoPrincipal;
  pagina.documento2Principal = documento2Principal;
  pagina.documento3Principal = documento3Principal;
  pagina.documento4Principal = documento4Principal;
  pagina.documento5Principal = documento5Principal;
  pagina.nombredoc1 = nombredoc1;
  pagina.nombredoc2 = nombredoc2;
  pagina.nombredoc3 = nombredoc3;
  pagina.nombredoc4 = nombredoc4;
  pagina.nombredoc5 = nombredoc5;

  await updatePaginaPenal(docId, pagina)
  console.log(pagina, docId, title)
}
    
console.log(paginas)
console.log(steps)

const matches = []


function renderLinks() {
  if (paginas.length > 0) {
    return paginas.map((pagina) => (
      <Pagina
        key={pagina.id}
        docId={pagina.docId}
        title={pagina.title}
        step1={pagina.step1}
        step2={pagina.step2}
        step3={pagina.step3}
        numSteps={pagina.numSteps}
        texto={pagina.texto}
        texto2={pagina.texto2}
        texto3={pagina.texto3}
      
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  }
}


console.log(steps)
console.log(index)
console.log(`c${pregunta10TestIntro}c`)


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

console.log(currentUser.uid)

  return (
    
 
     <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
      <CssBaseline>
            <div>

              <Grid  sx={{justifyContent: "center", paddingTop:"30px", paddingBottom: "30px"}} container component="main">
              
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{justifyContent: "center"}}>
                
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{minHeight: "89vh", bgcolor: "#d5e2f7", color: "white", marginRight: "20px", marginLeft: "20px", borderRadius: "5px", padding: "20px", maxWidth: "850px" }}>
            
            <Typography sx={{color: "#4a5b82", paddingTop: "10px"}}>
                Orden
              </Typography>

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidthrespuestaguia
                id="paginar"
                
                name="paginar"
                autoComplete="paginar"
              
                onChange={handleChange}
                value={paginar}
              />
            
            <FormControl sx={{color: "#4a5b82", paddingTop: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Tipo de diapositiva</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={tipoDiapo}
        onChange={handleChangeTipoDiapo}
      ><FormControlLabel value="portada" control={<Radio />} label="Portada" />
                <FormControlLabel value="portadatema" control={<Radio />} label="Portada de tema" />
        <FormControlLabel value="portadacapitulo" control={<Radio />} label="Portada de capítulo" />
        
        <FormControlLabel value="teoria" control={<Radio />} label="Teoría" />
        <FormControlLabel value="testintroductorio" control={<Radio />} label="Test" />
        <FormControlLabel value="respuestaguiada" control={<Radio />} label="Test con respuesta explicada" />
        <FormControlLabel value="preposttest" control={<Radio />} label="Texto breve" />
        <FormControlLabel value="casopractico" control={<Radio />} label="Caso práctico" />

      </RadioGroup>
    </FormControl>

    {tipoDiapo==="portada" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva solo debe emplearse para la portada del máster. 
              </Typography>}

              {tipoDiapo==="portadatema" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva debe emplearse para la portada de cada tema. 
              </Typography>}

              {tipoDiapo==="portadacapitulo" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva debe emplearse para la portada de cada capítulo. 
              </Typography>}

              {tipoDiapo==="teoria" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva debe emplearse para las diapositivas que contengan explicaciones teóricas.  
              </Typography>}

              {tipoDiapo==="testintroductorio" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva debe emplearse para tests no evaluables (hasta diez preguntas) en los que no se proporcione una explicación sobre la respuesta correcta.
              </Typography>}

              {tipoDiapo==="respuestaguiada" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva debe emplearse para casos prácticos no evaluables en los que se presenta al alumno un caso práctico y se le hace una pregunta que debe responder seleccionando alguna de las respuestas que se le ofrecen. 
                Una vez que el alumno da la respuesta, accede a una explicación sobre la respuesta correcta. 
              </Typography>}

              {tipoDiapo==="preposttest" && <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Este tipo de diapositiva debe usarse para incluir pequeños textos que guíen al alumno entre diapositivas. Por ejemplo, debe emplearse para dar paso a tests o casos prácticos. 
              </Typography>}


              { tipoDiapo !== "portada" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Indica el número de tema:
              </Typography>


              <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="tema"
                
                name="tema"
                autoComplete="tema"
              
                onChange={handleChange}
                value={tema}
              />


              <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                ¿Cuál es el título de este tema?
              </Typography>
     



<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombreTema"
                
                name="nombreTema"
                autoComplete="nombreTema"
              
                onChange={handleChange}
                value={nombreTema}
              /></div>}

{tipoDiapo === "portadatema" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "30px", paddingBottom: "20px"}}>
                Introduce el texto de presentación del tema:
              </Typography>

            
              

<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>



             

             
</div>}


{(tipoDiapo !== "portada" && tipoDiapo !== "portadatema")  && <div>
<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Indica el número de capítulo:
              </Typography>

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="capitulo"
                
                name="capitulo"
                autoComplete="capitulo"
              
                onChange={handleChange}
                value={capitulo}
              />
            
            <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                ¿Cuál es el título de este capítulo?
              </Typography>
     

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="title"
                
                name="title"
                autoComplete="step1"
              
                onChange={handleChange}
                value={title}
              /></div>}
<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Título de la diapositiva:
              </Typography>
<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombreDiapo"
                
                name="nombreDiapo"
                autoComplete="nombreDiapo"
              
                onChange={handleChange}
                value={nombreDiapo}
              />

{tipoDiapo === "teoria" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta diapositiva puede tener entre 1 y 3 pasos. Crea los pasos a continuación.
              </Typography>

            
              <Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Título del paso 1:
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="step1"
                
                name="step1"
                autoComplete="step1"
               
                onChange={handleChange}
              />
<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Contenido del paso 1:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={texto} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Título del paso 2:
              </Typography>
                <TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="step2"
                
                name="step2"
                autoComplete="step2"
               
                onChange={handleChange}
                value={step2}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Contenido del paso 2:
              </Typography>
              <Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={texto2} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto2(data)
}}
/> 
</Stack>
<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Título del paso 3:
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="step3"
                
                name="step3"
                autoComplete="step3"
               
                onChange={handleChange}
              />
              <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Contenido del paso 3:
              </Typography>
              <Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={texto3} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto3(data)
}}
/> 
</Stack>
</div>}



{tipoDiapo === "testintroductorio" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Este test introductorio puede tener hasta diez preguntas / casos prácticos.
              </Typography>

            
              
<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Enunciado del test / caso práctico:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 1:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta1TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta1TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre1"
                
                name="res1pre1"
                autoComplete="res1pre1"
                value={res1Pre1}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre1"
                
                name="res2pre1"
                autoComplete="res2pre1"
                value={res2Pre1}
                onChange={handleChange}
              />

{res2Pre1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre1"
                
                name="res3pre1"
                autoComplete="res3pre1"
                value={res3Pre1}
                onChange={handleChange}
              /></div>}

{res3Pre1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre1"
                
                name="res4pre1"
                autoComplete="res4pre1"
                value={res4Pre1}
                onChange={handleChange}
              /></div>}


              <FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre1}
        onChange={handleChangeResCorrectaPre1}
      >
        <FormControlLabel value={res1Pre1} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre1} control={<Radio />} label="Segunda" />
        {res3Pre1 !== "" && <FormControlLabel value={res3Pre1} control={<Radio />} label="Tercera" />}
        {res4Pre1 !== "" && <FormControlLabel value={res4Pre1} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl>


{pregunta1TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 2:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta2TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta2TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre2"
                
                name="res1pre2"
                autoComplete="res1pre2"
                value={res1Pre2}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre2"
                
                name="res2pre2"
                autoComplete="res2pre2"
                value={res2Pre2}
                onChange={handleChange}
              />

{res2Pre2 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre2"
                
                name="res3pre2"
                autoComplete="res3pre2"
                value={res3Pre2}
                onChange={handleChange}
              /></div>}

{res3Pre2 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre2"
                
                name="res4pre2"
                autoComplete="res4pre2"
                value={res4Pre2}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre2}
        onChange={handleChangeResCorrectaPre2}
      >
        <FormControlLabel value={res1Pre2} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre2} control={<Radio />} label="Segunda" />
        {res3Pre2 !== "" && <FormControlLabel value={res3Pre2} control={<Radio />} label="Tercera" />}
        {res4Pre2 !== "" && <FormControlLabel value={res4Pre2} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta2TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 3:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta3TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta3TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre3"
                
                name="res1pre3"
                autoComplete="res1pre3"
                value={res1Pre3}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre3"
                
                name="res2pre3"
                autoComplete="res2pre3"
                value={res2Pre3}
                onChange={handleChange}
              />

{res2Pre3 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre3"
                
                name="res3pre3"
                autoComplete="res3pre3"
                value={res3Pre3}
                onChange={handleChange}
              /></div>}

{res3Pre3 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre3"
                
                name="res4pre3"
                autoComplete="res4pre3"
                value={res4Pre3}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre3}
        onChange={handleChangeResCorrectaPre3}
      >
        <FormControlLabel value={res1Pre3} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre3} control={<Radio />} label="Segunda" />
        {res3Pre3 !== "" && <FormControlLabel value={res3Pre3} control={<Radio />} label="Tercera" />}
        {res4Pre3 !== "" && <FormControlLabel value={res4Pre3} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta3TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 4:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta4TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta4TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre4"
                
                name="res1pre4"
                autoComplete="res1pre4"
                value={res1Pre4}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre4"
                
                name="res2pre4"
                autoComplete="res2pre4"
                value={res2Pre4}
                onChange={handleChange}
              />

{res2Pre4 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre4"
                
                name="res3pre4"
                autoComplete="res3pre4"
                value={res3Pre4}
                onChange={handleChange}
              /></div>}

{res3Pre4 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre4"
                
                name="res4pre4"
                autoComplete="res4pre4"
                value={res4Pre4}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre4}
        onChange={handleChangeResCorrectaPre4}
      >
        <FormControlLabel value={res1Pre4} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre4} control={<Radio />} label="Segunda" />
        {res3Pre4 !== "" && <FormControlLabel value={res3Pre4} control={<Radio />} label="Tercera" />}
        {res4Pre4 !== "" && <FormControlLabel value={res4Pre4} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta4TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 5:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta5TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta5TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre5"
                
                name="res1pre5"
                autoComplete="res1pre5"
                value={res1Pre5}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre5"
                
                name="res2pre5"
                autoComplete="res2pre5"
                value={res2Pre5}
                onChange={handleChange}
              />

{res2Pre5 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre5"
                
                name="res3pre5"
                autoComplete="res3pre5"
                value={res3Pre5}
                onChange={handleChange}
              /></div>}

{res3Pre5 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre5"
                
                name="res4pre5"
                autoComplete="res4pre5"
                value={res4Pre5}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre5}
        onChange={handleChangeResCorrectaPre5}
      >
        <FormControlLabel value={res1Pre5} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre5} control={<Radio />} label="Segunda" />
        {res3Pre5 !== "" && <FormControlLabel value={res3Pre5} control={<Radio />} label="Tercera" />}
        {res4Pre5 !== "" && <FormControlLabel value={res4Pre5} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta5TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 6:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta6TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta6TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre6"
                
                name="res1pre6"
                autoComplete="res1pre6"
                value={res1Pre6}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre6"
                
                name="res2pre6"
                autoComplete="res2pre6"
                value={res2Pre6}
                onChange={handleChange}
              />

{res2Pre6 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre6"
                
                name="res3pre6"
                autoComplete="res3pre6"
                value={res3Pre6}
                onChange={handleChange}
              /></div>}

{res3Pre6 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre6"
                
                name="res4pre6"
                autoComplete="res4pre6"
                value={res4Pre6}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre6}
        onChange={handleChangeResCorrectaPre6}
      >
        <FormControlLabel value={res1Pre6} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre6} control={<Radio />} label="Segunda" />
        {res3Pre6 !== "" && <FormControlLabel value={res3Pre6} control={<Radio />} label="Tercera" />}
        {res4Pre6 !== "" && <FormControlLabel value={res4Pre6} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta6TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 7:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta7TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta7TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre7"
                
                name="res1pre7"
                autoComplete="res1pre7"
                value={res1Pre7}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre7"
                
                name="res2pre7"
                autoComplete="res2pre7"
                value={res2Pre7}
                onChange={handleChange}
              />

{res2Pre7 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre7"
                
                name="res3pre7"
                autoComplete="res3pre7"
                value={res3Pre7}
                onChange={handleChange}
              /></div>}

{res3Pre7 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre7"
                
                name="res4pre7"
                autoComplete="res4pre7"
                value={res4Pre7}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre7}
        onChange={handleChangeResCorrectaPre7}
      >
        <FormControlLabel value={res1Pre7} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre7} control={<Radio />} label="Segunda" />
        {res3Pre7 !== "" && <FormControlLabel value={res3Pre7} control={<Radio />} label="Tercera" />}
        {res4Pre7 !== "" && <FormControlLabel value={res4Pre7} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta7TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 8:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta8TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta8TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre8"
                
                name="res1pre8"
                autoComplete="res1pre8"
                value={res1Pre8}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre8"
                
                name="res2pre8"
                autoComplete="res2pre8"
                value={res2Pre8}
                onChange={handleChange}
              />

{res2Pre8 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre8"
                
                name="res3pre8"
                autoComplete="res3pre8"
                value={res3Pre8}
                onChange={handleChange}
              /></div>}

{res3Pre8 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre8"
                
                name="res4pre8"
                autoComplete="res4pre8"
                value={res4Pre8}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre8}
        onChange={handleChangeResCorrectaPre8}
      >
        <FormControlLabel value={res1Pre8} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre8} control={<Radio />} label="Segunda" />
        {res3Pre8 !== "" && <FormControlLabel value={res3Pre8} control={<Radio />} label="Tercera" />}
        {res4Pre8 !== "" && <FormControlLabel value={res4Pre8} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}


{pregunta8TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 9:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta9TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta9TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre9"
                
                name="res1pre9"
                autoComplete="res1pre9"
                value={res1Pre9}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre9"
                
                name="res2pre9"
                autoComplete="res2pre9"
                value={res2Pre9}
                onChange={handleChange}
              />

{res2Pre9 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre9"
                
                name="res3pre9"
                autoComplete="res3pre9"
                value={res3Pre9}
                onChange={handleChange}
              /></div>}

{res3Pre9 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre9"
                
                name="res4pre9"
                autoComplete="res4pre9"
                value={res4Pre9}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre9}
        onChange={handleChangeResCorrectaPre9}
      >
        <FormControlLabel value={res1Pre9} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre9} control={<Radio />} label="Segunda" />
        {res3Pre9 !== "" && <FormControlLabel value={res3Pre9} control={<Radio />} label="Tercera" />}
        {res4Pre9 !== "" && <FormControlLabel value={res4Pre9} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta9TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 10:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta10TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta10TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre10"
                
                name="res1pre10"
                autoComplete="res1pre10"
                value={res1Pre10}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre10"
                
                name="res2pre10"
                autoComplete="res2pre10"
                value={res2Pre10}
                onChange={handleChange}
              />

{res2Pre10 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre10"
                
                name="res3pre10"
                autoComplete="res3pre10"
                value={res3Pre10}
                onChange={handleChange}
              /></div>}

{res3Pre10 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre10"
                
                name="res4pre10"
                autoComplete="res4pre10"
                value={res4Pre10}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre10}
        onChange={handleChangeResCorrectaPre10}
      >
        <FormControlLabel value={res1Pre10} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre10} control={<Radio />} label="Segunda" />
        {res3Pre10 !== "" && <FormControlLabel value={res3Pre10} control={<Radio />} label="Tercera" />}
        {res4Pre10 !== "" && <FormControlLabel value={res4Pre10} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}


             

             
</div>}

{tipoDiapo === "respuestaguiada" && <div> 

            
              
<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Enunciado:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta1TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta1TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre1"
                
                name="res1pre1"
                autoComplete="res1pre1"
                value={res1Pre1}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre1"
                
                name="res2pre1"
                autoComplete="res2pre1"
                value={res2Pre1}
                onChange={handleChange}
              />

{res2Pre1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre1"
                
                name="res3pre1"
                autoComplete="res3pre1"
                value={res3Pre1}
                onChange={handleChange}
              /></div>}

{res3Pre1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre1"
                
                name="res4pre1"
                autoComplete="res4pre1"
                value={res4Pre1}
                onChange={handleChange}
              /></div>}


              <FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre1}
        onChange={handleChangeResCorrectaPre1}
      >
        <FormControlLabel value={res1Pre1} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre1} control={<Radio />} label="Segunda" />
        {res3Pre1 !== "" && <FormControlLabel value={res3Pre1} control={<Radio />} label="Tercera" />}
        {res4Pre1 !== "" && <FormControlLabel value={res4Pre1} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl>


<div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Explicación al alumno del análisis que debía haber realizado para identificar la respuesta correcta
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta2TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta2TestIntro(data)
}}
/> 
</Stack>

</div>




             

             
</div>}


{tipoDiapo === "preposttest" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "30px", paddingBottom: "20px"}}>
                Introduce el texto.
              </Typography>

            
              

<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>



             

             
</div>}



{tipoDiapo === "casopractico" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "30px", paddingBottom: "20px"}}>
                Presentación e instrucciones del caso práctico.
              </Typography>

            
              

<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 

</Stack>


<div> <Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Este test introductorio puede tener hasta diez preguntas / casos prácticos.
              </Typography>

            
              
<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Enunciado:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 1:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta1TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta1TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre1"
                
                name="res1pre1"
                autoComplete="res1pre1"
                value={res1Pre1}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre1"
                
                name="res2pre1"
                autoComplete="res2pre1"
                value={res2Pre1}
                onChange={handleChange}
              />

{res2Pre1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre1"
                
                name="res3pre1"
                autoComplete="res3pre1"
                value={res3Pre1}
                onChange={handleChange}
              /></div>}

{res3Pre1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre1"
                
                name="res4pre1"
                autoComplete="res4pre1"
                value={res4Pre1}
                onChange={handleChange}
              /></div>}


              <FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre1}
        onChange={handleChangeResCorrectaPre1}
      >
        <FormControlLabel value="primera" control={<Radio />} label="Primera" />
        <FormControlLabel value="segunda" control={<Radio />} label="Segunda" />
        {res3Pre1 !== "" && <FormControlLabel value={res3Pre2} control={<Radio />} label="Tercera" />}
        {res4Pre1 !== "" && <FormControlLabel value={res4Pre2} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl>


{pregunta1TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 2:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta2TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta2TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre2"
                
                name="res1pre2"
                autoComplete="res1pre2"
                value={res1Pre2}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre2"
                
                name="res2pre2"
                autoComplete="res2pre2"
                value={res2Pre2}
                onChange={handleChange}
              />

{res2Pre2 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre2"
                
                name="res3pre2"
                autoComplete="res3pre2"
                value={res3Pre2}
                onChange={handleChange}
              /></div>}

{res3Pre2 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre2"
                
                name="res4pre2"
                autoComplete="res4pre2"
                value={res4Pre2}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre2}
        onChange={handleChangeResCorrectaPre2}
      >
        <FormControlLabel value="primera" control={<Radio />} label="Primera" />
        <FormControlLabel value="segunda" control={<Radio />} label="Segunda" />
        {res3Pre2 !== "" && <FormControlLabel value={res3Pre2} control={<Radio />} label="Tercera" />}
        {res4Pre2 !== "" && <FormControlLabel value={res4Pre2} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta2TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 3:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta3TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta3TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre3"
                
                name="res1pre3"
                autoComplete="res1pre3"
                value={res1Pre3}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre3"
                
                name="res2pre3"
                autoComplete="res2pre3"
                value={res2Pre3}
                onChange={handleChange}
              />

{res2Pre3 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre3"
                
                name="res3pre3"
                autoComplete="res3pre3"
                value={res3Pre3}
                onChange={handleChange}
              /></div>}

{res3Pre3 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre3"
                
                name="res4pre3"
                autoComplete="res4pre3"
                value={res4Pre3}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre3}
        onChange={handleChangeResCorrectaPre3}
      >
        <FormControlLabel value={res1Pre3} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre3} control={<Radio />} label="Segunda" />
        {res3Pre3 !== "" && <FormControlLabel value={res3Pre3} control={<Radio />} label="Tercera" />}
        {res4Pre3 !== "" && <FormControlLabel value={res4Pre3} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta3TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 4:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta4TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta4TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre4"
                
                name="res1pre4"
                autoComplete="res1pre4"
                value={res1Pre4}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre4"
                
                name="res2pre4"
                autoComplete="res2pre4"
                value={res2Pre4}
                onChange={handleChange}
              />

{res2Pre4 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre4"
                
                name="res3pre4"
                autoComplete="res3pre4"
                value={res3Pre4}
                onChange={handleChange}
              /></div>}

{res3Pre4 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre4"
                
                name="res4pre4"
                autoComplete="res4pre4"
                value={res4Pre4}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre4}
        onChange={handleChangeResCorrectaPre4}
      >
        <FormControlLabel value={res1Pre4} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre4} control={<Radio />} label="Segunda" />
        {res3Pre4 !== "" && <FormControlLabel value={res3Pre4} control={<Radio />} label="Tercera" />}
        {res4Pre4 !== "" && <FormControlLabel value={res4Pre4} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta4TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 5:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta5TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta5TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre5"
                
                name="res1pre5"
                autoComplete="res1pre5"
                value={res1Pre5}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre5"
                
                name="res2pre5"
                autoComplete="res2pre5"
                value={res2Pre5}
                onChange={handleChange}
              />

{res2Pre5 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre5"
                
                name="res3pre5"
                autoComplete="res3pre5"
                value={res3Pre5}
                onChange={handleChange}
              /></div>}

{res3Pre5 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre5"
                
                name="res4pre5"
                autoComplete="res4pre5"
                value={res4Pre5}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre5}
        onChange={handleChangeResCorrectaPre5}
      >
        <FormControlLabel value={res1Pre5} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre5} control={<Radio />} label="Segunda" />
        {res3Pre5 !== "" && <FormControlLabel value={res3Pre5} control={<Radio />} label="Tercera" />}
        {res4Pre5 !== "" && <FormControlLabel value={res4Pre5} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta5TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 6:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta6TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta6TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre6"
                
                name="res1pre6"
                autoComplete="res1pre6"
                value={res1Pre6}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre6"
                
                name="res2pre6"
                autoComplete="res2pre6"
                value={res2Pre6}
                onChange={handleChange}
              />

{res2Pre6 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre6"
                
                name="res3pre6"
                autoComplete="res3pre6"
                value={res3Pre6}
                onChange={handleChange}
              /></div>}

{res3Pre6 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre6"
                
                name="res4pre6"
                autoComplete="res4pre6"
                value={res4Pre6}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre6}
        onChange={handleChangeResCorrectaPre6}
      >
        <FormControlLabel value={res1Pre6} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre6} control={<Radio />} label="Segunda" />
        {res3Pre6 !== "" && <FormControlLabel value={res3Pre6} control={<Radio />} label="Tercera" />}
        {res4Pre6 !== "" && <FormControlLabel value={res4Pre6} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta6TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 7:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta7TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta7TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre7"
                
                name="res1pre7"
                autoComplete="res1pre7"
                value={res1Pre7}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre7"
                
                name="res2pre7"
                autoComplete="res2pre7"
                value={res2Pre7}
                onChange={handleChange}
              />

{res2Pre7 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre7"
                
                name="res3pre7"
                autoComplete="res3pre7"
                value={res3Pre7}
                onChange={handleChange}
              /></div>}

{res3Pre7 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre7"
                
                name="res4pre7"
                autoComplete="res4pre7"
                value={res4Pre7}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre7}
        onChange={handleChangeResCorrectaPre7}
      >
        <FormControlLabel value={res1Pre7} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre7} control={<Radio />} label="Segunda" />
        {res3Pre7 !== "" && <FormControlLabel value={res3Pre7} control={<Radio />} label="Tercera" />}
        {res4Pre7 !== "" && <FormControlLabel value={res4Pre7} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta7TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 8:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta8TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta8TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre8"
                
                name="res1pre8"
                autoComplete="res1pre8"
                value={res1Pre8}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre8"
                
                name="res2pre8"
                autoComplete="res2pre8"
                value={res2Pre8}
                onChange={handleChange}
              />

{res2Pre8 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre8"
                
                name="res3pre8"
                autoComplete="res3pre8"
                value={res3Pre8}
                onChange={handleChange}
              /></div>}

{res3Pre8 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre8"
                
                name="res4pre8"
                autoComplete="res4pre8"
                value={res4Pre8}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre8}
        onChange={handleChangeResCorrectaPre8}
      >
        <FormControlLabel value={res1Pre8} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre8} control={<Radio />} label="Segunda" />
        {res3Pre8 !== "" && <FormControlLabel value={res3Pre8} control={<Radio />} label="Tercera" />}
        {res4Pre8 !== "" && <FormControlLabel value={res4Pre8} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}


{pregunta8TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 9:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta9TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta9TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre9"
                
                name="res1pre9"
                autoComplete="res1pre9"
                value={res1Pre9}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre9"
                
                name="res2pre9"
                autoComplete="res2pre9"
                value={res2Pre9}
                onChange={handleChange}
              />

{res2Pre9 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre9"
                
                name="res3pre9"
                autoComplete="res3pre9"
                value={res3Pre9}
                onChange={handleChange}
              /></div>}

{res3Pre9 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre9"
                
                name="res4pre9"
                autoComplete="res4pre9"
                value={res4Pre9}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre9}
        onChange={handleChangeResCorrectaPre9}
      >
        <FormControlLabel value={res1Pre9} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre9} control={<Radio />} label="Segunda" />
        {res3Pre9 !== "" && <FormControlLabel value={res3Pre9} control={<Radio />} label="Tercera" />}
        {res4Pre9 !== "" && <FormControlLabel value={res4Pre9} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}

{pregunta9TestIntro !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Pregunta / caso práctico 10:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={pregunta10TestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  setPregunta10TestIntro(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta pregunta puede tener un máximo de cuatro alternativas de respuesta.
              </Typography>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Primera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res1pre10"
                
                name="res1pre10"
                autoComplete="res1pre10"
                value={res1Pre10}
                onChange={handleChange}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Segunda posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res2pre10"
                
                name="res2pre10"
                autoComplete="res2pre10"
                value={res2Pre10}
                onChange={handleChange}
              />

{res2Pre10 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Tercera posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res3pre10"
                
                name="res3pre10"
                autoComplete="res3pre10"
                value={res3Pre10}
                onChange={handleChange}
              /></div>}

{res3Pre10 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Cuarta posible respuesta
              </Typography>
<TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="res4pre10"
                
                name="res4pre10"
                autoComplete="res4pre10"
                value={res4Pre10}
                onChange={handleChange}
              /></div>}

<FormControl sx={{color: "#4a5b82", paddingTop: "40px", paddingBottom: "40px"}}>
      <FormLabel id="demo-row-radio-buttons-group-label">Respuesta correcta</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={resPre10}
        onChange={handleChangeResCorrectaPre10}
      >
        <FormControlLabel value={res1Pre10} control={<Radio />} label="Primera" />
        <FormControlLabel value={res2Pre10} control={<Radio />} label="Segunda" />
        {res3Pre10 !== "" && <FormControlLabel value={res3Pre10} control={<Radio />} label="Tercera" />}
        {res4Pre10 !== "" && <FormControlLabel value={res4Pre10} control={<Radio />} label="Cuarta" />}
      </RadioGroup>
    </FormControl></div>}


             

             
</div>



<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Sube los documentos del caso práctico. Debes subir en primer lugar el documento principal. Puedes subir hasta cinco documentos.
              </Typography>

              <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Nombre del documento principal.
              </Typography>  

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombredoc1"
                
                name="nombredoc1"
                autoComplete="nombredoc1"
              
                onChange={handleChange}
                value={nombredoc1}
              />

<div className="App">
      <input
        type="file"
        onChange={(event) => {
          setMainDocUpload(event.target.files[0]);
          setDocumentoPrincipal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documentoprincipal"}`)
      
        }}
      />
 
     
    </div>

{nombredoc1 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Nombre del documento 2.
              </Typography>  

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombredoc2"
                
                name="nombredoc2"
                autoComplete="nombredoc2"
              
                onChange={handleChange}
                value={nombredoc2}
              />
              
              <div className="App">
      <input
        type="file"
        onChange={(event) => {
          setMain2DocUpload(event.target.files[0]);
          setDocumento2Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento2"}`)
        
        }}
      />

     
    </div>

    
              
              </div> }

{nombredoc2 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Nombre del documento 3.
              </Typography>  

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombredoc3"
                
                name="nombredoc3"
                autoComplete="nombredoc3"
              
                onChange={handleChange}
                value={nombredoc3}
              />
              
              <div className="App">
      <input
        type="file"
        onChange={(event) => {
          setMain3DocUpload(event.target.files[0]);
          setDocumento3Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento3"}`)
          
        }}
      />
     
     
    </div>

   
              
              
              </div>
              
              
              }

{nombredoc3 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Nombre del documento 4.
              </Typography>  

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombredoc4"
                
                name="nombredoc4"
                autoComplete="nombredoc4"
              
                onChange={handleChange}
                value={nombredoc4}
              />
              
              <div className="App">
      <input
        type="file"
        onChange={(event) => {
          setMain4DocUpload(event.target.files[0]);
          setDocumento4Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento4"}`)
          
        }}
      />
   
     
    </div>
              
              </div>}

{nombredoc4 !== "" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Nombre del documento 5.
              </Typography>  

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="nombredoc5"
                
                name="nombredoc5"
                autoComplete="nombredoc5"
              
                onChange={handleChange}
                value={nombredoc5}
              />
              
              <div className="App">
      <input
        type="file"
        onChange={(event) => {
          setMain5DocUpload(event.target.files[0]);
          setDocumento5Principal(`https://firebasestorage.googleapis.com/v0/b/jobsandlaw-5e0ba.appspot.com/documents/${curso + paginar + "documento5"}`)
        }}
      />
      
     
    </div>
              
              </div>}



    
             

             
</div>}


{tipoDiapo === "portada" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "30px", paddingBottom: "20px"}}>
                Introduce el texto de presentación del máster:
              </Typography>

            
              

<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>



             

             
</div>}


{tipoDiapo === "portadacapitulo" && <div> <Typography sx={{color: "#4a5b82", paddingTop: "30px", paddingBottom: "20px"}}>
                Introduce el texto de presentación del capítulo:
              </Typography>

            
              

<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={introTestIntro} onChange={(event, editor) => {
  const data = editor.getData()
  
  setIntroTestIntro(data)
}}
/> 
</Stack>



             

             
</div>}



                      <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                Enviar
              </Button>
             
         </Box>


            </Grid>
            





















           
         </Grid>
    
    </div>
    </CssBaseline>
    </AuthProvider> 

  )
}

export default CreatorPenal
