import React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Progressbar from '../components/Progressbar';
import Grid from '@mui/material/Unstable_Grid2';

function ContinuarMaster ({docId, master, status, paginar, diaposCompletadas, activeStep, numSteps, step2, thisIndex, ordered, tipoDiapo}) {
  const [status2, setCurrentStatus2] = useState(status)
  const [currentDiaposCompletadas, setCurrentDiaposCompletadas] = useState(diaposCompletadas)
  const navigate = useNavigate();

console.log(currentDiaposCompletadas)



useEffect(() => {
  setCurrentDiaposCompletadas([...diaposCompletadas])
}, [])

const max = currentDiaposCompletadas.reduce((accumulator, currentValue) => {
    return Math.max(accumulator, currentValue);
}, currentDiaposCompletadas[0]);



    const nextSlide = () => {
    
      console.log(thisIndex)
      const nextIndex = thisIndex+1
      const chosenOne =  ordered[nextIndex]
      console.log(nextIndex)
      console.log(chosenOne)
      console.log(chosenOne.docId)
      console.log(currentDiaposCompletadas)
      navigate(`../masterderechoprocesal/${chosenOne.docId}`);
      window.location.replace('');
    }

    const cuantasDiapos = ordered.length;

    const cuantasDiaposCompletadas = diaposCompletadas.length;

    const porcentajeCompletado = Math.trunc((cuantasDiaposCompletadas * 100) / (cuantasDiapos + 1700));

function handleClick () {
    console.log(currentDiaposCompletadas)
console.log(max)

console.log(ordered)

const next = ordered.find((element) => element.paginar > Number(max))

const nextUrl = next.docId;
navigate(`/masterderechoprocesal/${nextUrl}`)

console.log(next)
console.log(nextUrl)

}console.log(cuantasDiapos)
console.log(porcentajeCompletado)

  return (


    
         <Grid xs={12} sx={{paddingBottom: "30px", opacity: "0.9"}}>
         <Stack alignItems="center">
     <Stack direction="column"   justifyContent="center"
    alignItems="center" sx= {{textAlign: "center", borderRadius: "5px", textAlign: "center", border: "0px"}}>
                  <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>Progreso</Typography>  
              <Stack sx={{width: "500px"}}>
              <Progressbar 
                  bgcolor="#ebeef5"
                  progress={porcentajeCompletado}
                  height={30}
              />
              </Stack>
           </Stack>
           <Button onClick={handleClick} variant="contained" sx={{marginTop: "20px", marginBottom: "20px", alignItems: "center", color:"#4a5b82", bgcolor: "#ebeef5", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600",
          ':hover': {
            bgcolor: '#6b81b5', // theme.palette.primary.main
            color: 'white',
          }}}>Continuar Máster</Button>
           </Stack>
   
   
  
  
       
  </Grid>
  
  
        
        
      
      
      
     
      )








  
}

export default ContinuarMaster