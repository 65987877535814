import React from 'react'
import { useParams } from "react-router-dom";
import { getPaginas, updateAlumno } from '../firebase/firebase';
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs  } from 'firebase/firestore';
import { useState, useEffect, useMemo, useRef } from 'react';
import AuthProvider from '../components/authProvider'
import Pagina from '../components/Pagina';
import { v4 as uuid } from 'uuid';
import { insertNewPagina, updatePagina } from '../firebase/firebase';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';
import { CssBaseline, Divider, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../customCKEditor.css";
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import { useNavigate } from 'react-router';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Image from 'mui-image';
import Link from '@mui/material/Link';
import { getAlumnos } from '../firebase/firebase';
import Alumno from '../components/Alumno';
import { Viewer, PageChangeEvent } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {ref, listAll, getDownloadURL} from 'firebase/storage';
import { storage } from '../firebase/firebase';
import JoditEditor from 'jodit-react';
import Test from '../components/Test';
import CasoPractico from '../components/CasoPractico';
import IndiceComp from '../components/IndiceComp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MatriculaComp from '../components/MatriculaComp';




function MasterDerechoProcesal({id, getPageId}) {

    let params = useParams();
    const db= getFirestore()
    const navigate = useNavigate();
    
    const [currentUser, setCurrentUser] = useState({})
    const [paginas, setPaginas] = useState([])
    
    const [numSteps, setNumSteps] = useState(1)
    const [step1, setStep1] = useState("")
    const [step2, setStep2] = useState("")
    const [step3, setStep3] = useState("")
    const steps = [];
    const [texto, setTexto] = useState("")
    const [texto2, setTexto2] = useState("")
    const [texto3, setTexto3] = useState("")
    const [curso, setCurso] = useState("procesal")
    const [paginar, setPaginar] = useState()
    const [tema, setTema] = useState()
    const [nombreTema, setNombreTema] = useState("")
    const [nombreDiapo, setNombreDiapo] = useState("")
    const [capitulo, setCapitulo] = useState()
    const [textoAlumno, setTextoAlumno] = useState("")
    

    const [numId, setNumId] = useState()

    const [tipoDiapo, setTipoDiapo] = useState("")
    const [introTestIntro, setIntroTestIntro] = useState("")
    const [pregunta1TestIntro, setPregunta1TestIntro] = useState("")
    const [res1Pre1, setRes1Pre1] = useState("")
    const [res2Pre1, setRes2Pre1] = useState("")
    const [res3Pre1, setRes3Pre1] = useState("")
    const [res4Pre1, setRes4Pre1] = useState("")
    const [resPre1, setResPre1] = useState("")
    const [pregunta2TestIntro, setPregunta2TestIntro] = useState("")
    const [res1Pre2, setRes1Pre2] = useState("")
    const [res2Pre2, setRes2Pre2] = useState("")
    const [res3Pre2, setRes3Pre2] = useState("")
    const [res4Pre2, setRes4Pre2] = useState("")
    const [resPre2, setResPre2] = useState("")
    const [pregunta3TestIntro, setPregunta3TestIntro] = useState("")
    const [res1Pre3, setRes1Pre3] = useState("")
    const [res2Pre3, setRes2Pre3] = useState("")
    const [res3Pre3, setRes3Pre3] = useState("")
    const [res4Pre3, setRes4Pre3] = useState("")
    const [resPre3, setResPre3] = useState("")
    const [pregunta4TestIntro, setPregunta4TestIntro] = useState("")
    const [res1Pre4, setRes1Pre4] = useState("")
    const [res2Pre4, setRes2Pre4] = useState("")
    const [res3Pre4, setRes3Pre4] = useState("")
    const [res4Pre4, setRes4Pre4] = useState("")
    const [resPre4, setResPre4] = useState("")
    const [pregunta5TestIntro, setPregunta5TestIntro] = useState("")
    const [res1Pre5, setRes1Pre5] = useState("")
    const [res2Pre5, setRes2Pre5] = useState("")
    const [res3Pre5, setRes3Pre5] = useState("")
    const [res4Pre5, setRes4Pre5] = useState("")
    const [resPre5, setResPre5] = useState("")
    const [pregunta6TestIntro, setPregunta6TestIntro] = useState("")
    const [res1Pre6, setRes1Pre6] = useState("")
    const [res2Pre6, setRes2Pre6] = useState("")
    const [res3Pre6, setRes3Pre6] = useState("")
    const [res4Pre6, setRes4Pre6] = useState("")
    const [resPre6, setResPre6] = useState("")
    const [pregunta7TestIntro, setPregunta7TestIntro] = useState("")
    const [res1Pre7, setRes1Pre7] = useState("")
    const [res2Pre7, setRes2Pre7] = useState("")
    const [res3Pre7, setRes3Pre7] = useState("")
    const [res4Pre7, setRes4Pre7] = useState("")
    const [resPre7, setResPre7] = useState("")
    const [pregunta8TestIntro, setPregunta8TestIntro] = useState("")
    const [res1Pre8, setRes1Pre8] = useState("")
    const [res2Pre8, setRes2Pre8] = useState("")
    const [res3Pre8, setRes3Pre8] = useState("")
    const [res4Pre8, setRes4Pre8] = useState("")
    const [resPre8, setResPre8] = useState("")
    const [pregunta9TestIntro, setPregunta9TestIntro] = useState("")
    const [res1Pre9, setRes1Pre9] = useState("")
    const [res2Pre9, setRes2Pre9] = useState("")
    const [res3Pre9, setRes3Pre9] = useState("")
    const [res4Pre9, setRes4Pre9] = useState("")
    const [resPre9, setResPre9] = useState("")
    const [pregunta10TestIntro, setPregunta10TestIntro] = useState("")
    const [res1Pre10, setRes1Pre10] = useState("")
    const [res2Pre10, setRes2Pre10] = useState("")
    const [res3Pre10, setRes3Pre10] = useState("")
    const [res4Pre10, setRes4Pre10] = useState("")
    const [resPre10, setResPre10] = useState("")
    const [documentoPrincipal, setDocumentoPrincipal] = useState("")
    
    const [documento2Principal, setDocumento2Principal] = useState("")
    const [documento3Principal, setDocumento3Principal] = useState("")
    const [documento4Principal, setDocumento4Principal] = useState("")
    const [documento5Principal, setDocumento5Principal] = useState("")
    const [nombredoc1, setnombredoc1] = useState("")
    const [nombredoc2, setnombredoc2] = useState("")
    const [nombredoc3, setnombredoc3] = useState("")
    const [nombredoc4, setnombredoc4] = useState("")
    const [nombredoc5, setnombredoc5] = useState("")

    const [currentDoc, setCurrentDoc] = useState(1)


    const [resAlumnoPre4, setResAlumnoPre4] = useState("")

    const [pags, setPags] = useState([]);
    const [title, setTitle] = useState("")
    const [programa, setPrograma] = useState(false)
    const [apuntes, setApuntes] = useState(false)
    const [dudas, setDudas] = useState(false)
    const [alumnos, setAlumnos] = useState([])
    const [diaposCompletadas, setDiaposCompletadas] = useState([])
    const [respuestas, setRespuestas] = useState({})
    const [docList, setDocList] = useState([])
    const docListRef = ref(storage, "documents/")

    async function handleUpdate2 (docId2, 
      master,
      status,
      diaposCompletadas,
      respuestas,
      
     
    ){
      
      const alumno = alumnos.find((item) => item.docId === docId2);
      alumno.master = master;
      alumno.status = status;
      alumno.diaposCompletadas = diaposCompletadas;
      alumno.respuestas = respuestas;
      alumno.textoAlumno = textoAlumno;
    
    
      await updateAlumno(docId2, alumno)
      console.log(alumno, docId2, master, diaposCompletadas, respuestas)
    }

    useEffect(() => {
    getPaginas();
    getAlumnos();
    console.log(params.pageId);
    console.log(alumnos)
    console.log(currentUser) 
  }, [])



    async function handleUserLoggedIn(user){
        setCurrentUser(user); 
        console.log(currentUser) 
        const resPaginas = await getPaginas(user.uid);
        setPaginas([...resPaginas]);
        console.log(paginas)

        const resAlumno = await getAlumnos(user.uid)
        setAlumnos([...resAlumno])
         console.log(alumnos)

      }

   
      
      function handleUserNotLoggedIn(user){
          console.log("Not logged in")
      }
      
      function handleUserNotRegistered(user){
          console.log("Not registered")
      }



  const fetchPost = async () => {
       
    await getDocs(collection(db, "paginas"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setPags(newData);                
            console.log(pags, newData);
        })
   
}

  useEffect(()=>{
    fetchPost();
}, [])
  
  const selectedTrabajo = pags.filter((doc => doc.id===params.pageId));
  
    const docRef = doc(db, "paginas", params.pageId)
  
  
      
    useEffect(() => {
        getDoc(docRef)
        .then((doc) => {
        
        const objeto = doc.data();
        setTitle(objeto.title);
        setStep1(objeto.step1);
        setStep2(objeto.step2);
        setStep3(objeto.step3);
        setTexto(objeto.texto);
        setTexto2(objeto.texto2);
        setTexto3(objeto.texto3);
        setNumSteps(objeto.numSteps);
        setCurso(objeto.curso);
        setPaginar(objeto.paginar);
        setTema(objeto.tema);
        setNombreTema(objeto.nombreTema);
        setNombreDiapo(objeto.nombreDiapo);
        setCapitulo(objeto.capitulo);

        setNumId(params.pageId)
        setTipoDiapo(objeto.tipoDiapo);
        setIntroTestIntro(objeto.introTestIntro);
        setPregunta1TestIntro(objeto.pregunta1TestIntro);
        setRes1Pre1(objeto.res1Pre1);
        setRes2Pre1(objeto.res2Pre1);
        setRes3Pre1(objeto.res3Pre1);
        setRes4Pre1(objeto.res4Pre1);
        setResPre1(objeto.resPre1);
        setPregunta2TestIntro(objeto.pregunta2TestIntro);
        setRes1Pre2(objeto.res1Pre2);
        setRes2Pre2(objeto.res2Pre2);
        setRes3Pre2(objeto.res3Pre2);
        setRes4Pre2(objeto.res4Pre2);
        setResPre2(objeto.resPre2);
        setPregunta3TestIntro(objeto.pregunta3TestIntro);
        setRes1Pre3(objeto.res1Pre3);
        setRes2Pre3(objeto.res2Pre3);
        setRes3Pre3(objeto.res3Pre3);
        setRes4Pre3(objeto.res4Pre3);
        setResPre3(objeto.resPre3);
        setPregunta4TestIntro(objeto.pregunta4TestIntro);
        setRes1Pre4(objeto.res1Pre4);
        setRes2Pre4(objeto.res2Pre4);
        setRes3Pre4(objeto.res3Pre4);
        setRes4Pre4(objeto.res4Pre4);
        setResPre4(objeto.resPre4);
        setPregunta5TestIntro(objeto.pregunta5TestIntro);
        setRes1Pre5(objeto.res1Pre5);
        setRes2Pre5(objeto.res2Pre5);
        setRes3Pre5(objeto.res3Pre5);
        setRes4Pre5(objeto.res4Pre5);
        setResPre5(objeto.resPre5);
        setPregunta6TestIntro(objeto.pregunta6TestIntro);
        setRes1Pre6(objeto.res1Pre6);
        setRes2Pre6(objeto.res2Pre6);
        setRes3Pre6(objeto.res3Pre6);
        setRes4Pre6(objeto.res4Pre6);
        setResPre6(objeto.resPre6);
        setPregunta7TestIntro(objeto.pregunta7TestIntro);
        setRes1Pre7(objeto.res1Pre7);
        setRes2Pre7(objeto.res2Pre7);
        setRes3Pre7(objeto.res3Pre7);
        setRes4Pre7(objeto.res4Pre7);
        setResPre7(objeto.resPre7);
        setPregunta8TestIntro(objeto.pregunta8TestIntro);
        setRes1Pre8(objeto.res1Pre8);
        setRes2Pre8(objeto.res2Pre8);
        setRes3Pre8(objeto.res3Pre8);
        setRes4Pre8(objeto.res4Pre8);
        setResPre8(objeto.resPre8);
        setPregunta9TestIntro(objeto.pregunta9TestIntro);
        setRes1Pre9(objeto.res1Pre9);
        setRes2Pre9(objeto.res2Pre9);
        setRes3Pre9(objeto.res3Pre9);
        setRes4Pre9(objeto.res4Pre9);
        setResPre9(objeto.resPre9);
        setPregunta10TestIntro(objeto.pregunta10TestIntro);
        setRes1Pre10(objeto.res1Pre10);
        setRes2Pre10(objeto.res2Pre10);
        setRes3Pre10(objeto.res3Pre10);
        setRes4Pre10(objeto.res4Pre10);
        setResPre10(objeto.resPre10);
        setDocumentoPrincipal(objeto.documentoPrincipal);
        setDocumento2Principal(objeto.documento2Principal);
        setDocumento3Principal(objeto.documento3Principal);
        setDocumento4Principal(objeto.documento4Principal);
        setDocumento5Principal(objeto.documento5Principal);
        setnombredoc1(objeto.nombredoc1);
        setnombredoc2(objeto.nombredoc2);
        setnombredoc3(objeto.nombredoc3);
        setnombredoc4(objeto.nombredoc4);
        setnombredoc5(objeto.nombredoc5);



        })
      }, [pags])

      console.log(selectedTrabajo);
      console.log(numId)
      console.log(params.pageId)

   
      






      
  
  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };



    function handleButtonRemoveStep2 () {
      const newNumSteps = numSteps - 1;
      setNumSteps(newNumSteps);
      setStep1(""); 
      setStep2(""); 
      setStep3(""); 

     }



    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [index, setIndex] = useState(1)

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === numSteps - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === numSteps;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    console.log(index)
    setIndex(index+1)
    console.log(index)
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIndex(index-1)
    console.log(index)
    
  };

  const openPrograma = () => {
    setPrograma(!programa);
    
  };

  const openApuntes = () => {
    setApuntes(!apuntes);
    
  };

  const openDudas = () => {
    setDudas(!dudas);
    
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    
    
    
  
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  function indice() {
    
    return ordered.map((pagina, index) => (
      <div>
        {index === 0 && 
        <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
        <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "20px"}}>Tema 1: {nombreTema} </Typography>
        </Link>}
        {index === 2 && 
        <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
        <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "18px"}}>Capítulo 1: {title} </Typography>
        </Link>}
          {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada") && <Typography> Título {ordered[index].tema}: {ordered[index].nombreTema} </Typography>}

          <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
          {(index !== 0 && index !== 2 && ordered[index].title !== ordered[index - 1].title) && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "20px", fontSize: "18px"}} > Capítulo {ordered[index].capitulo}: {ordered[index].title} </Typography>}
          </Link>

          <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
            
      {ordered[index].tipoDiapo !== "portada" && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "14px"}} >{pagina.nombreDiapo}</Typography>}
      </Link>
      
      
      
      </div>
    ));
  
}



    function handleSubmit (e) {
       e.preventDefault();
       steps.push(step1)
       steps.push(step2)
       handleUpdate(numId, 
        title,
        step1, 
        step2,
        step3,
        numSteps,
        texto,
        texto2,
        texto3,
        curso,
        paginar,
        tema,
        nombreTema,
        nombreDiapo,
        capitulo,
        tipoDiapo,
        introTestIntro,
        pregunta1TestIntro,
        res1Pre1,
        res2Pre1,
        res3Pre1,
        res4Pre1,
        resPre1,
        pregunta2TestIntro,
        res1Pre2,
        res2Pre2,
        res3Pre2,
        res4Pre2,
        resPre2,
        pregunta3TestIntro,
        res1Pre3,
        res2Pre3,
        res3Pre3,
        res4Pre3,
        resPre3,
        pregunta4TestIntro,
        res1Pre4,
        res2Pre4,
        res3Pre4,
        res4Pre4,
        resPre4,
        pregunta5TestIntro,
        res1Pre5,
        res2Pre5,
        res3Pre5,
        res4Pre5,
        resPre5,
        pregunta6TestIntro,
        res1Pre6,
        res2Pre6,
        res3Pre6,
        res4Pre6,
        resPre6,
        pregunta7TestIntro,
        res1Pre7,
        res2Pre7,
        res3Pre7,
        res4Pre7,
        resPre7,
        pregunta8TestIntro,
        res1Pre8,
        res2Pre8,
        res3Pre8,
        res4Pre8,
        resPre8,
        pregunta9TestIntro,
        res1Pre9,
        res2Pre9,
        res3Pre9,
        res4Pre9,
        resPre9,
        pregunta10TestIntro,
        res1Pre10,
        res2Pre10,
        res3Pre10,
        res4Pre10,
        resPre10,
      )
       console.log(steps)

       }

    function handleChange(e) {
        const value = e.target.value;
        if(e.target.name === "title") {
            setTitle(value);
            console.log(title)
        }

        if(e.target.name === "step1") {
          setStep1(value);
          console.log(step1)
          steps.push(step1)

          if (step2 === "") {
            setNumSteps(1);
            steps.push(step1)
          }
        
      }

      
    

    if(e.target.name === "step2") {
      setStep2(value);
      console.log(step2)
      steps.push(step2)
      console.log(steps)

      if (step2 !== "" && step3 === "") {
        setNumSteps(2);
        steps.push(step2)
      console.log(steps)
      }
    
      if (step2 === "") {
        setNumSteps(1)
        steps.push(step2)
      console.log(steps)
      }
  }

  if(e.target.name === "step3") {
    setStep3(value);
    console.log(step3)
    steps.push(step3)
    console.log(steps)

    if (step3 !== ""  && step2 !== "") {
      setNumSteps(3);
    }
  
    if (step3 === "") {
      setNumSteps(2)
    }
}


if(e.target.name === "curso") {
  setCurso(value);
  console.log(step1)

}

if(e.target.name === "paginar") {
  setPaginar(value);
  console.log(step1)

}

if(e.target.name === "tema") {
  setTema(value);
  console.log(step1)

}

if(e.target.name === "nombreTema") {
  setNombreTema(value);
  console.log(step1)

}

if(e.target.name === "nombreDiapo") {
  setNombreDiapo(value);
  console.log(step1)

}

if(e.target.name === "capitulo") {
  setCapitulo(value);
  console.log(step1)

}

if(e.target.name === "nombreTema") {
  setNombreTema(value);
  console.log(step1)

}

if(e.target.name === "capitulo") {
  setCapitulo(value);
  console.log(step1)

}

if(e.target.name === "res1pre1") {
  setRes1Pre1(value);
  console.log(step1)

}

if(e.target.name === "res2pre1") {
  setRes2Pre1(value);
  console.log(step1)

}

if(e.target.name === "res3pre1") {
  setRes3Pre1(value);
  console.log(step1)

}

if(e.target.name === "res4pre1") {
  setRes4Pre1(value);
  console.log(step1)

}

if(e.target.name === "res1pre2") {
  setRes1Pre2(value);
  console.log(step1)

}

if(e.target.name === "res2pre2") {
  setRes2Pre2(value);
  console.log(step1)

}

if(e.target.name === "res3pre2") {
  setRes3Pre2(value);
  console.log(step1)

}

if(e.target.name === "res4pre2") {
  setRes4Pre2(value);
  console.log(step1)

}

if(e.target.name === "res1pre3") {
  setRes1Pre3(value);
  console.log(step1)

}

if(e.target.name === "res2pre3") {
  setRes2Pre3(value);
  console.log(step1)

}

if(e.target.name === "res3pre3") {
  setRes3Pre3(value);
  console.log(step1)

}

if(e.target.name === "res4pre3") {
  setRes4Pre3(value);
  console.log(step1)

}

if(e.target.name === "res1pre4") {
  setRes1Pre4(value);
  console.log(step1)

}

if(e.target.name === "res2pre4") {
  setRes2Pre4(value);
  console.log(step1)

}

if(e.target.name === "res3pre4") {
  setRes3Pre4(value);
  console.log(step1)

}

if(e.target.name === "res4pre4") {
  setRes4Pre4(value);
  console.log(step1)

}

if(e.target.name === "res1pre2") {
  setRes1Pre2(value);
  console.log(step1)

}

if(e.target.name === "res2pre5") {
  setRes2Pre5(value);
  console.log(step1)

}

if(e.target.name === "res3pre5") {
  setRes3Pre5(value);
  console.log(step1)

}

if(e.target.name === "res4pre5") {
  setRes4Pre5(value);
  console.log(step1)

}

if(e.target.name === "res1pre6") {
  setRes1Pre6(value);
  console.log(step1)

}

if(e.target.name === "res2pre6") {
  setRes2Pre6(value);
  console.log(step1)

}

if(e.target.name === "res3pre6") {
  setRes3Pre6(value);
  console.log(step1)

}

if(e.target.name === "res4pre6") {
  setRes4Pre6(value);
  console.log(step1)

}

if(e.target.name === "res1pre2") {
  setRes1Pre2(value);
  console.log(step1)

}

if(e.target.name === "res2pre7") {
  setRes2Pre7(value);
  console.log(step1)

}

if(e.target.name === "res3pre7") {
  setRes3Pre7(value);
  console.log(step1)

}

if(e.target.name === "res4pre7") {
  setRes4Pre7(value);
  console.log(step1)

}

if(e.target.name === "res1pre8") {
  setRes1Pre8(value);
  console.log(step1)

}

if(e.target.name === "res2pre8") {
  setRes2Pre8(value);
  console.log(step1)

}

if(e.target.name === "res3pre8") {
  setRes3Pre8(value);
  console.log(step1)

}

if(e.target.name === "res4pre8") {
  setRes4Pre8(value);
  console.log(step1)

}

if(e.target.name === "res1pre9") {
  setRes1Pre9(value);
  console.log(step1)

}

if(e.target.name === "res2pre9") {
  setRes2Pre9(value);
  console.log(step1)

}

if(e.target.name === "res3pre9") {
  setRes3Pre9(value);
  console.log(step1)

}

if(e.target.name === "res4pre9") {
  setRes4Pre9(value);
  console.log(step1)

}

if(e.target.name === "res1pre10") {
  setRes1Pre10(value);
  console.log(step1)

}

if(e.target.name === "res2pre10") {
  setRes2Pre10(value);
  console.log(step1)

}

if(e.target.name === "res3pre10") {
  setRes3Pre10(value);
  console.log(step1)

}

if(e.target.name === "res4pre10") {
  setRes4Pre10(value);
  console.log(step1)

}

}

const handleChangeResPre1 = (event) => {
  setResPre1(event.target.value);

};

const handleChangeEditor = (event) => {
  setTextoAlumno(event.target.value);
  console.log(textoAlumno)

};

const handleChangeResPre2 = (event) => {
  setResPre2(event.target.value);

};

const handleChangeResPre3 = (event) => {
  setResPre3(event.target.value);

};

const handleChangeResPre4 = (event) => {
  setResPre4(event.target.value);

};



const handleChangeResPre5 = (event) => {
  setResPre5(event.target.value);

};

const handleChangeResPre6 = (event) => {
  setResPre6(event.target.value);

};

const handleChangeResPre7 = (event) => {
  setResPre7(event.target.value);

};

const handleChangeResPre8 = (event) => {
  setResPre8(event.target.value);

};

const handleChangeResPre9 = (event) => {
  setResPre9(event.target.value);

};

const handleChangeResPre10 = (event) => {
  setResPre10(event.target.value);

};
console.log(step2)
console.log(numSteps)
console.log(step3)

    function addPagina() {
      const newPagina = {
        id: uuid(),
        title: title,
        step1: step1,
        step2: step2,
        step3: step3,
        numSteps: numSteps,
        uid: currentUser.uid,
        texto: texto,
        texto2: texto2,
        texto3: texto3,
        curso: curso,
        paginar: paginar,
        tema: tema,
        nombreTema: nombreTema,
        nombreDiapo: nombreDiapo,
        capitulo: capitulo,
        tipoDiapo: tipoDiapo,
        introTestIntro: introTestIntro,
        pregunta1TestIntro: pregunta1TestIntro,
        res1Pre1: res1Pre1,
        res2Pre1: res2Pre1,
        res3Pre1: res3Pre1,
        res4Pre1: res4Pre1,
        resPre1: resPre1,
        pregunta2TestIntro: pregunta2TestIntro,
        res1Pre2: res1Pre2,
        res2Pre2: res2Pre2,
        res3Pre2: res3Pre2,
        res4Pre2: res4Pre2,
        resPre2: resPre2,
        pregunta3TestIntro: pregunta3TestIntro,
        res1Pre3: res1Pre3,
        res2Pre3: res2Pre3,
        res3Pre3: res3Pre3,
        res4Pre3: res4Pre3,
        resPre3: resPre3,
        pregunta4TestIntro: pregunta4TestIntro,
        res1Pre4: res1Pre4,
        res2Pre4: res2Pre4,
        res3Pre4: res3Pre4,
        res4Pre4: res4Pre4,
        resPre4: resPre4,
        pregunta5TestIntro: pregunta5TestIntro,
        res1Pre5: res1Pre5,
        res2Pre5: res2Pre5,
        res3Pre5: res3Pre5,
        res4Pre5: res4Pre5,
        resPre5: resPre5,
        pregunta6TestIntro: pregunta6TestIntro,
        res1Pre6: res1Pre6,
        res2Pre6: res2Pre6,
        res3Pre6: res3Pre6,
        res4Pre6: res4Pre6,
        resPre6: resPre6,
        pregunta7TestIntro: pregunta7TestIntro,
        res1Pre7: res1Pre7,
        res2Pre7: res2Pre7,
        res3Pre7: res3Pre7,
        res4Pre7: res4Pre7,
        resPre7: resPre7,
        pregunta8TestIntro: pregunta8TestIntro,
        res1Pre8: res1Pre8,
        res2Pre8: res2Pre8,
        res3Pre8: res3Pre8,
        res4Pre8: res4Pre8,
        resPre8: resPre8,
        pregunta9TestIntro: pregunta9TestIntro,
        res1Pre9: res1Pre9,
        res2Pre9: res2Pre9,
        res3Pre9: res3Pre9,
        res4Pre9: res4Pre9,
        resPre9: resPre9,
        pregunta10TestIntro: pregunta10TestIntro,
        res1Pre10: res1Pre10,
        res2Pre10: res2Pre10,
        res3Pre10: res3Pre10,
        res4Pre10: res4Pre10,
        resPre10: resPre10,
      };
      const res = insertNewPagina(newPagina)
      newPagina.docId = res.id;
      setTitle("");
      setStep1("");
      setPaginas([...paginas, newPagina])
      
      console.log(paginas)
    }

function handleDelete(){

}

async function handleUpdate(docId, 
  title,
  step1, 
  step2,
  step3,
  numSteps,
  texto,
  texto2,
  texto3,
  curso,
  paginar,
  tema,
  nombreTema,
  nombreDiapo,
  capitulo,
  tipoDiapo,
  introTestIntro,
  pregunta1TestIntro,
  res1Pre1,
  res2Pre1,
  res3Pre1,
  res4Pre1,
  resPre1,
  pregunta2TestIntro,
  res1Pre2,
  res2Pre2,
  res3Pre2,
  res4Pre2,
  resPre2,
  pregunta3TestIntro,
  res1Pre3,
  res2Pre3,
  res3Pre3,
  res4Pre3,
  resPre3,
  pregunta4TestIntro,
  res1Pre4,
  res2Pre4,
  res3Pre4,
  res4Pre4,
  resPre4,
  pregunta5TestIntro,
  res1Pre5,
  res2Pre5,
  res3Pre5,
  res4Pre5,
  resPre5,
  pregunta6TestIntro,
  res1Pre6,
  res2Pre6,
  res3Pre6,
  res4Pre6,
  resPre6,
  pregunta7TestIntro,
  res1Pre7,
  res2Pre7,
  res3Pre7,
  res4Pre7,
  resPre7,
  pregunta8TestIntro,
  res1Pre8,
  res2Pre8,
  res3Pre8,
  res4Pre8,
  resPre8,
  pregunta9TestIntro,
  res1Pre9,
  res2Pre9,
  res3Pre9,
  res4Pre9,
  resPre9,
  pregunta10TestIntro,
  res1Pre10,
  res2Pre10,
  res3Pre10,
  res4Pre10,
  resPre10,
  documentoPrincipal,
  documento2Principal,
  documento3Principal,
  documento4Principal,
  documento5Principal,
  nombredoc1,
  nombredoc2,
  nombredoc3,
  nombredoc4,
  nombredoc5,

){
  
  const pagina = paginas.find((item) => item.docId === docId);
  pagina.title = title;
  pagina.step1 = step1;
  pagina.step2 = step2;
  pagina.step3 = step3;
  pagina.numSteps = numSteps;
  pagina.texto = texto;
  pagina.texto2 = texto2;
  pagina.texto3 = texto3;
  pagina.curso = curso;
  pagina.paginar = paginar;
  pagina.tema = tema;
  pagina.nombreTema = nombreTema;
  pagina.nombreDiapo = nombreDiapo;
  pagina.capitulo = capitulo;
  pagina.tipoDiapo =tipoDiapo;
  pagina.introTestIntro = introTestIntro;
  pagina.pregunta1TestIntro = pregunta1TestIntro;
  pagina.res1Pre1 = res1Pre1;
  pagina.res2Pre1 = res2Pre1;
  pagina.res3Pre1 = res3Pre1;
  pagina.res4Pre1 = res4Pre1;
  pagina.resPre1 = resPre1;
  pagina.pregunta2TestIntro = pregunta2TestIntro;
  pagina.res1Pre2 = res1Pre2;
  pagina.res2Pre2 = res2Pre2;
  pagina.res3Pre2 = res3Pre2;
  pagina.res4Pre2 = res4Pre2;
  pagina.resPre2 = res3Pre2;
  pagina.pregunta3TestIntro = pregunta3TestIntro;
  pagina.res1Pre3 = res1Pre3;
  pagina.res2Pre3 = res2Pre3;
  pagina.res3Pre3 = res3Pre3;
  pagina.res4Pre3 = res4Pre3;
  pagina.resPre3 = resPre3;
  pagina.pregunta4TestIntro = pregunta4TestIntro;
  pagina.res1Pre4 = res1Pre4;
  pagina.res2Pre4 = res2Pre4;
  pagina.res3Pre4 = res3Pre4;
  pagina.res4Pre4 = res4Pre4;
  pagina.resPre4 = res1Pre4;
  pagina.pregunta5TestIntro = pregunta5TestIntro;
  pagina.res1Pre5 = res1Pre5;
  pagina.res2Pre5 = res2Pre5;
  pagina.res3Pre5 = res3Pre5;
  pagina.res4Pre5 = res4Pre5;
  pagina.resPre5 = resPre5;
  pagina.pregunta6TestIntro = pregunta6TestIntro;
  pagina.res1Pre6 = res1Pre6;
  pagina.res2Pre6 = res2Pre6;
  pagina.res3Pre6 = res3Pre6;
  pagina.res4Pre6 = res4Pre6;
  pagina.resPre6 = resPre6;
  pagina.pregunta7TestIntro = pregunta7TestIntro;
  pagina.res1Pre7 = res1Pre7;
  pagina.res2Pre7 = res2Pre7;
  pagina.res3Pre7 = res3Pre7;
  pagina.res4Pre7 = res4Pre7;
  pagina.resPre7 = resPre7;
  pagina.pregunta8TestIntro = pregunta8TestIntro;
  pagina.res1Pre8 = res1Pre8;
  pagina.res2Pre8 = res2Pre8;
  pagina.res3Pre8 = res3Pre8;
  pagina.res4Pre8 = res4Pre8;
  pagina.resPre8 = resPre8;
  pagina.pregunta9TestIntro = pregunta9TestIntro;
  pagina.res1Pre9 = res1Pre9;
  pagina.res2Pre9 = res2Pre9;
  pagina.res3Pre9 = res3Pre9;
  pagina.res4Pre9 = res4Pre9;
  pagina.resPre9 = resPre9;
  pagina.pregunta10TestIntro = pregunta10TestIntro;
  pagina.res1Pre10 = res1Pre10;
  pagina.res2Pre10 = res2Pre10;
  pagina.res3Pre10 = res3Pre10;
  pagina.res4Pre10 = res4Pre10;
  pagina.resPre10 = resPre10;
  pagina.documentoPrincipal = documentoPrincipal;
  pagina.documento2Principal = documento2Principal;
  pagina.documento3Principal = documento3Principal;
  pagina.documento4Principal = documento4Principal;
  pagina.documento5Principal = documento5Principal;
  pagina.nombredoc1 = nombredoc1;
  pagina.nombredoc2 = nombredoc2;
  pagina.nombredoc3 = nombredoc3;
  pagina.nombredoc4 = nombredoc4;
  pagina.nombredoc5 = nombredoc5;

  await updatePagina(docId, pagina)
  console.log(pagina, docId, title)
}
    
console.log(paginas)
console.log(steps)

const matches = []


function renderLinks() {
  if (paginas.length > 0) {
    return paginas.map((pagina) => (
      <Pagina
        key={pagina.id}
        docId={pagina.docId}
        title={pagina.title}
        step1={pagina.step1}
        step2={pagina.step2}
        step3={pagina.step3}
        numSteps={pagina.numSteps}
        texto={pagina.texto}
        texto2={pagina.texto2}
        texto3={pagina.texto3}
        curso={pagina.curso}
        paginar={pagina.paginar}
        tema={pagina.tema}
        nombreTema={pagina.nombreTema}
        nombreDiapo={pagina.nombreDiapo}
        capitulo={pagina.capitulo}
   
      
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  }
}

const ordenes = []
const modifiedOrdenes = paginas.map(function(element) {
  ordenes.push(element.paginar)
})

console.log(ordenes)

const paginasProcesal = []
    
const eachjob = paginas.map((doc, index) => {
 
    

    if (doc.curso === "procesal")
    
     {
      paginasProcesal.push(doc)
    }
    
  })
const ordered = [...paginasProcesal].sort((a,b) => a.paginar - b.paginar);
console.log(paginasProcesal)
console.log(ordered)

function findOrder (pagina, index1) {
  if (pagina.docId === numId) {
    
    console.log(index1)
    return pagina.docId === numId
  }
}

let myOrder = ordered.find((pagina, index1) => findOrder(pagina, index1));

console.log(ordered.indexOf(myOrder))
const thisIndex = ordered.indexOf(myOrder)
console.log(thisIndex)


console.log(myOrder)

console.log(numId)

const matches2 = []
console.log(numId)
const chosenPage = paginas.map((pagina, index) => {
  if (pagina.docId === numId) {
    matches2.push(pagina)
    console.log(matches2)
  }
  console.log(pagina.docId)
})

function renderLinks2() {
  if (matches2.length > 0) {
    return matches2.map((pagina) => (
      <Pagina
        key={pagina.id}
        docId={pagina.docId}
        title={pagina.title}
        step1={pagina.step1}
        step2={pagina.step2}
        step3={pagina.step3}
        numSteps={pagina.numSteps}
        texto={pagina.texto}
        texto2={pagina.texto2}
        texto3={pagina.texto3}
        curso={pagina.curso}
        paginar={pagina.paginar}
        tema={pagina.tema}
        nombreTema={pagina.nombreTema}
        nombreDiapo={pagina.nombreDiapo}
        capitulo={pagina.capitulo}
  
      
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  }
}



console.log(steps)
console.log(index)
console.log(step1)
console.log(step2)
console.log(step3)


console.log(paginas)
console.log(diaposCompletadas)


useEffect(() => {
  listAll(docListRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url)=> {
        setDocList((prev) => [...prev, url] )
      } )
    } )
  } )
}, [])

console.log(docList)

const docPrin = docList.find((element) => element.includes(`${curso + paginar + "documentoprincipal"}`))
const docPrin2 = docList.find((element) => element.includes(`${curso + paginar + "documento2"}`))
const docPrin3 = docList.find((element) => element.includes(`${curso + paginar + "documento3"}`))
const docPrin4 = docList.find((element) => element.includes(`${curso + paginar + "documento4"}`))
const docPrin5 = docList.find((element) => element.includes(`${curso + paginar + "documento5"}`))


console.log(`${curso + paginar + "documentoprincipal"}`)




const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handlePageChange = e => {
    localStorage.setItem("current-page", `${e.currentPage}`)
    console.log(e.currentPage)
  
  }

  const  [opcionesCaso, setOpcionesCaso] = useState(1)

 
  const handleDocNum1 = () => {
    setCurrentDoc(1)
  }

  const handleDocNum2 = () => {
    setCurrentDoc(2)
  }

  const handleDocNum3 = () => {
    setCurrentDoc(3)
  }

  const handleDocNum4 = () => {
    setCurrentDoc(4)
  }

  const handleDocNum5 = () => {
    setCurrentDoc(5)
  }

  const handleEnunciado = () => {
    setOpcionesCaso(1);
  };

  const handlePreguntas = () => {
    setOpcionesCaso(2);
  };

  const handleEscrito = (e) => {
    e.preventDefault();
    setOpcionesCaso(3);
  };



  const editor = useRef(null);
  const [content, setContent] = useState(" ");
  const config = {
    readonly: false,
    height: 727
  };
  const handleUpdate9 = (event) => {
    const editorContent = event.target.innerHTML;
    setContent(editorContent);
  };




  return (
    <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
      <CssBaseline>
            <div>




            {tipoDiapo === "casopractico" && 

<Stack sx={{}}>
{(alumnos.map((alumno) => (
<CasoPractico key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} pregunta1TestIntro={pregunta1TestIntro} res1Pre1={res1Pre1} 
res2Pre1={res2Pre1} res3Pre1={res3Pre1} res4Pre1={res4Pre1} pregunta2TestIntro={pregunta2TestIntro} res1Pre2={res1Pre2} 
res2Pre2={res2Pre2} res3Pre2={res3Pre2} res4Pre2={res4Pre2} pregunta3TestIntro={pregunta3TestIntro} res1Pre3={res1Pre3} 
res2Pre3={res2Pre3} res3Pre3={res3Pre3} res4Pre3={res4Pre3} pregunta4TestIntro={pregunta4TestIntro} res1Pre4={res1Pre4} 
res2Pre4={res2Pre4} res3Pre4={res3Pre4} res4Pre4={res4Pre4} pregunta5TestIntro={pregunta5TestIntro} res1Pre5={res1Pre5} 
res2Pre5={res2Pre5} res3Pre5={res3Pre5} res4Pre5={res4Pre5} pregunta6TestIntro={pregunta6TestIntro} res1Pre6={res1Pre6} 
res2Pre6={res2Pre6} res3Pre6={res3Pre6} res4Pre6={res4Pre6} pregunta7TestIntro={pregunta7TestIntro} res1Pre7={res1Pre7} 
res2Pre7={res2Pre7} res3Pre7={res3Pre7} res4Pre7={res4Pre7} pregunta8TestIntro={pregunta8TestIntro} res1Pre8={res1Pre8} 
res2Pre8={res2Pre8} res3Pre8={res3Pre8} res4Pre8={res4Pre8} pregunta9TestIntro={pregunta9TestIntro} res1Pre9={res1Pre9} 
res2Pre9={res2Pre9} res3Pre9={res3Pre9} res4Pre9={res4Pre9} pregunta10TestIntro={pregunta10TestIntro} res1Pre10={res1Pre10} 
res2Pre10={res2Pre10} res3Pre10={res3Pre10} res4Pre10={res4Pre10} resPre1={resPre1} resPre2={resPre2} resPre3={resPre3}
resPre4={resPre4} resPre5={resPre5} resPre6={resPre6} resPre7={resPre7} resPre8={resPre8} resPre9={resPre9} resPre10={resPre10} introTestIntro={introTestIntro}
activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} nombredoc1 = {nombredoc1} 
nombredoc2 = {nombredoc2} nombredoc3 = {nombredoc3} nombredoc4 = {nombredoc4} nombredoc5 = {nombredoc5} opcionesCaso = {opcionesCaso} handleEnunciado={handleEnunciado} handlePreguntas={handlePreguntas} handleEscrito={handleEscrito} docPrin={docPrin}
handlePageChange={handlePageChange} docPrin2={docPrin2} docPrin3={docPrin3} docPrin4={docPrin4} docPrin5={docPrin5} handleDocNum1={handleDocNum1} handleDocNum2={handleDocNum2} handleDocNum3={handleDocNum3} handleDocNum4={handleDocNum4}
handleDocNum5={handleDocNum5} textoAlumno={alumno.textoAlumno}  onUpdate={handleUpdate2} />
)))}
</Stack>

}


{tipoDiapo === "ultimaprueba" && 
<div>
<MatriculaComp />
</div>
}


              <Grid paddingTop="30px" sx={{justifyContent: "center", paddingRight: "20px", paddingLeft: "20px", bgcolor: "#edf0f5", minHeight: "100vh"}} container component="main">








              {(tipoDiapo === "teoria" || tipoDiapo === "testintroductorio" || tipoDiapo === "preposttest" || tipoDiapo === "respuestaguiada") && <Grid xs={4} sx={{justifyContent: "center", paddingRight: "20px", paddingLeft: "20px"}}>
          <Item sx={{height: "89vh", bgcolor: "white"}}>
          <Stack sx={{bgcolor: "#4a5b82", borderRadius: "3px 3px 0px 0px", paddingTop: "10px", paddingBottom: "10px"}}>
          
        <Link href="../dashboardmasterderechoprocesal" >  <Image
src="/images/logoblanco.png" style={{width: "100px"}} sx={{marginLeft: "60px", marginRight: "60px", paddingTop: "10px", paddingBottom: "10px"}}
duration={
0
}

/></Link>
            </Stack>
            <Divider></Divider>
            <Stack sx={{bgcolor: "#4a5b82", borderRadius: "3px 3px 0px 0px", paddingTop: "10px", paddingBottom: "10px"}}>
             
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontFamily: "Lato"}}>
                Máster en Derecho Procesal Civil
                </Typography>
                
            </Stack>
            <Divider></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontSize: "13px", fontFamily: "Lato"}}>
                {nombreTema}
                </Typography>
            </Stack>
            <Divider></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontSize: "12px", fontFamily: "Lato"}}>
                    {title}
                </Typography>
            </Stack>
            <Divider></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontSize: "12px", fontFamily: "Lato"}}>
                    {nombreDiapo}
                </Typography>
            </Stack>

            {(programa !== true && apuntes !== true && dudas !== true) &&
             <div>
            <Grid container spacing={2} sx={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}} >
             
              <Grid xs={4} >
              <Stack direction="column">
                <Item onClick={openPrograma} sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  
                <Button sx={{width: "100%", height: "100%"}}>
                <Stack direction="column">
                  <ListAltIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Programa
                  </Typography>
                  </Stack>
                  </Button>
                  
                </Item>
                </Stack>
              </Grid>
              <Grid xs={4} >
              <Stack direction="column">
                <Item onClick={openApuntes} sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  
                <Button sx={{width: "100%", height: "100%"}}>
                <Stack direction="column">
                <TextSnippetIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Apuntes
                  </Typography>
                  </Stack>
                  </Button>
                  
                </Item>
                </Stack>
              </Grid>
              <Grid xs={4} >
              <Stack direction="column">
                <Item onClick={openDudas} sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  
                <Button sx={{width: "100%", height: "100%"}}>
                <Stack direction="column">
                <ForumIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Dudas
                  </Typography>
                  </Stack>
                  </Button>
                  
                </Item>
                </Stack>
              
              </Grid>


            </Grid>
                          <Stack sx={{alignItems: "center", alignContent: "center", justifyContent: "center", paddingTop: "20px"}} >
                          <Stack direction="column">
                <Item sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center", width: "300px"}}>
                  
                <Button href="../dashboardmasterderechoprocesal" sx={{width: "100%", height: "100%", justifyContent: "center", alignContent: "center", textAlign: "center"}}>
                <Stack direction="column" sx={{justifyContent: "center", alignContent: "center", textAlign: "center"}} >
                <ExitToAppIcon sx={{ fontSize: "60px", color: "#4a5b82", justifyContent: "center", alignContent: "center", textAlign: "center" }} />
                  
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Volver al escritorio
                  </Typography>
                  </Stack>
                  </Button>
                  
                </Item>
                </Stack>
                        </Stack>

                        </div>}

            {programa === true &&<Grid container spacing={2} sx={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}} >
            <Item sx={{bgcolor: "#4a5b82", height: "50vh", width: "100%", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center", overflow:'auto'}}>
              
              {(alumnos.map((alumno) => (
  <IndiceComp key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} diaposCompletadas={alumno.diaposCompletadas} activeStep={activeStep} numSteps={numSteps} step2={step2} ordered={ordered} />
  )))}
          
              </Item>

              <Stack sx={{bgcolor: "white", height: "100%", width: "100%", paddingTop: "10px", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
              <Stack sx={{paddingLeft: "20px", paddingRight: "20px"}}>
              <Button onClick={openPrograma} sx={{color: "#4a5b82"}}>
                Cerrar
              </Button>
              </Stack>
              </Stack>
          
            </Grid>}

            {apuntes === true &&<Grid container spacing={2} sx={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}} >
            <Item sx={{bgcolor: "#f1f28a", height: "50vh", width: "100%", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center", overflow:'auto'}}>
              <Stack sx={{paddingLeft: "20px", paddingRight: "20px"}}>
              <Typography sx={{paddingTop: "20px"}}>
                Podrás guardar tus notas sobre cada diapositiva.
              </Typography>
              <Typography sx={{paddingTop: "10px"}}>
                Esta función no está disponible durante la prueba gratuita.
              </Typography>
              </Stack>
              </Item>

              <Stack sx={{bgcolor: "white", height: "100%", width: "100%", paddingTop: "15px", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
              <Stack sx={{paddingLeft: "20px", paddingRight: "20px"}}>
              <Button onClick={openApuntes} sx={{color: "#4a5b82"}}>
                Cerrar
              </Button>
              </Stack>
              </Stack>
          
            </Grid>}


            {dudas === true &&<Grid container spacing={2} sx={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}} >
            <Item sx={{bgcolor: "#d5e2f7", height: "50vh", width: "100%", paddingTop: "0vh", alignItems: "center", alignContent: "center", justifyContent: "center", overflow:'auto'}}>
              <Stack sx={{paddingLeft: "20px", paddingRight: "20px"}}>
              <Typography sx={{paddingTop: "20px"}}>
                Consulta las dudas que han tenido en esta dispositiva otros alumnos, y las respuestas dadas por los profesores. Si tu duda no está resuelta, plantéala y un profesor te la resolverá lo antes posible.
              </Typography>
              <Typography sx={{paddingTop: "10px"}}>
                Esta función no está disponible durante la prueba gratuita.
              </Typography>
              </Stack>
              </Item>

              <Stack sx={{bgcolor: "white", height: "100%", width: "100%", paddingTop: "15px", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
              <Stack sx={{paddingLeft: "20px", paddingRight: "20px"}}>
              <Button onClick={openDudas} sx={{color: "#4a5b82"}}>
                Cerrar
              </Button>
              </Stack>
              </Stack>
          
            </Grid>}



            
 
          { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
            <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
                <CardContent>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                    Tus notas
                </Typography>
                </CardContent>
            </Card>
            </Stack>*/}
            </Item>
        </Grid>}

        {(tipoDiapo === "portada") && <Grid xs={4} sx={{justifyContent: "center", paddingRight: "20px", paddingLeft: "20px"}}>
        
            <Item sx={{height: "89vh", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
            <Stack sx= {{height: "84vh", bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
            <Image src="/images/ciudad.jpg" style={{height: "100%", opacity: "0.2"}} sx={{}} />
                  <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px"}}>
                  Máster en <br/> Derecho Procesal Civil
  
  
                      </Typography>
                  <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
                  Todo lo que necesitas saber para defender a tus clientes en la jurisdicción civil
  
  
                      </Typography>
                      <Box sx={{width: "100px", margin: 'auto'}} >
            <Image src="/images/logoblanco.png" style={{width: "100%"}} sx={{paddingTop: "10px", alignContent: "center"}} />
            </Box>
                  </Stack>
  
  
    
  
              
   
            { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
              <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
                  <CardContent>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                      Tus notas
                  </Typography>
                  </CardContent>
              </Card>
              </Stack>*/}


              </Item>
          
        </Grid>}

        {(tipoDiapo === "portadatema") && <Grid xs={4} sx={{justifyContent: "center", paddingRight: "20px", paddingLeft: "20px"}}>
        
        <Item sx={{height: "89vh", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
        <Stack sx= {{height: "84vh", bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
        
        <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "5px", fontSize: "40px", textAlign: "center"}}>
              Tema {tema}:


                  </Typography>
              <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "40px"}}>
              {nombreTema}


                  </Typography>
                  <Image src="/images/ciudad.jpg" style={{height: "80%", opacity: "0.2"}} sx={{}} />
              
                  <Box sx={{width: "100px", margin: 'auto'}} >
        <Image src="/images/logoblanco.png" style={{width: "100%"}} sx={{paddingTop: "10px", alignContent: "center"}} />
        </Box>
              </Stack>




          

        { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
          <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
              <CardContent>
              <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                  Tus notas
              </Typography>
              </CardContent>
          </Card>
          </Stack>*/}
          </Item>
      
          
           
          

    </Grid>
    }



        {(tipoDiapo === "portada" || tipoDiapo === "portadatema" ) && <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{justifyContent: "center"}}>
            <Item sx={{height: "89vh", bgcolor: "#4a5b82"}}>
              <Box sx={{ width: '100%', padding: "25px" }}>
       
          
              <Stack sx= {{height: "76vh", bgcolor: "#4a5b82", marginTop: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                  <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px"}}>
                  {parse(introTestIntro)}
  
                      </Typography>
                      

                  </Stack>
          
                  {(alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno} activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}

      </Box>

    
      
            </Item>  </Grid>
          }

          {(tipoDiapo === "portadacapitulo") && <Grid xs={4} sx={{justifyContent: "center", paddingRight: "20px", paddingLeft: "20px"}}>
        
        <Item sx={{height: "89vh", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
        <Stack sx= {{height: "84vh", bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
        
        <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "5px", fontSize: "40px", textAlign: "center"}}>
              Capítulo {capitulo}:


                  </Typography>
              <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "40px"}}>
              {title}


                  </Typography>
                  <Image src="/images/ciudad.jpg" style={{height: "80%", opacity: "0.2"}} sx={{}} />
              
                  <Box sx={{width: "100px", margin: 'auto'}} >
        <Image src="/images/logoblanco.png" style={{width: "100%"}} sx={{paddingTop: "10px", alignContent: "center"}} />
        </Box>
              </Stack>




          

        { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
          <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
              <CardContent>
              <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                  Tus notas
              </Typography>
              </CardContent>
          </Card>
          </Stack>*/}
          </Item>
      
          
           
          

    </Grid>
    }


            
 

            {tipoDiapo === "portadacapitulo" && <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{justifyContent: "center"}}>
            <Item sx={{height: "89vh", bgcolor: "#4a5b82"}}>
              <Box sx={{ width: '100%', padding: "25px" }}>
       
          
              <Stack sx= {{height: "76vh", bgcolor: "#4a5b82", marginTop: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                  <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px"}}>
                  {parse(introTestIntro)}
  
                      </Typography>
                      
                    
                 
                  </Stack>
          
                  {(alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
                
             
            

      </Box>
            </Item></Grid>
    }





        {tipoDiapo === "teoria" && <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{justifyContent: "center"}}>
              <Item sx={{minHeight: "89vh", bgcolor: "#4a5b82"}}>
            <Box sx={{padding: "25px" }}>

            <Stepper nonLinear activeStep={activeStep}>
        
          <Step key={step1} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'white', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: '#4a5b82', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step1}
            </StepButton>
          </Step>

          {step2 !== "" && <Step key={step2} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'white', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: '#4a5b82', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step2}
            </StepButton>
          </Step>}

          {(step2 !== "" && step3 !== "") && <Step key={step3} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'white', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: '#4a5b82', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step3}
            </StepButton>
          </Step>}
      </Stepper>




      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'white', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: '#4a5b82', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() &&
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        }

        {activeStep === 0 && 
        <div>
        
            <Stack sx= {{minHeight: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>

                {parse(texto)}


                    </Typography>
                </Stack>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {step2 !== "" && <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1, color: "white" }}
              >
                Anterior
              </Button>}
              <Box sx={{ flex: '1 1 auto' }} />
              {step2 !== "" && <Button onClick={handleNext} disabled={activeStep === numSteps - 1} sx={{ mr: 1, color: "white" }}>
              
                Siguiente
              </Button>}
              {step2 !== "" && (alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}

                  {step2 === "" && (alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}



            </Box>
      </div>
    
    }


{activeStep === 1 && 
               <div>
        
               <Stack sx= {{minHeight: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>
   
                   {parse(texto2)}
   
   
                       </Typography>
                   </Stack>
           
           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
           {step2 !== "" &&  <Button
                   color="inherit"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   sx={{ mr: 1, color: "white" }}
                 >
                   Anterior
                 </Button>}
                 <Box sx={{ flex: '1 1 auto' }} />
                 {step2 !== "" && <Button onClick={handleNext} disabled={activeStep === numSteps - 1} sx={{ mr: 1, color: "white" }}>
              
                Siguiente
              </Button>}
              {step2 !== "" && (alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}

                  {step2 === "" && (alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
               </Box>
         </div>
    
    }

{activeStep === 2 && 
               <div>
        
               <Stack sx= {{minHeight: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>
   
                   {parse(texto3)}
   
   
                       </Typography>
                   </Stack>
           
           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
           {step2 !== "" &&  <Button
                   color="inherit"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   sx={{ mr: 1, color: "white" }}
                 >
                   Anterior
                 </Button>}
                 <Box sx={{ flex: '1 1 auto' }} />
                 {step2 !== "" && <Button onClick={handleNext} disabled={activeStep === numSteps - 1} sx={{ mr: 1, color: "white" }}>
              
                Siguiente
              </Button>}
              {step2 !== "" && (alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}

                  {step2 === "" && (alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
                   
               </Box>
         </div>
    
    }





      </div>
    </Box>
          </Item>
          
          
      
             </Grid>}


             {tipoDiapo === "testintroductorio" && <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{}}>
             
             <Stack sx= {{minHeight: "89vh",bgcolor: "#4a5b82", marginTop: "0px", marginBottom: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px"}}>

       <Stack sx={{paddingBottom: "20px", alignItems: "flex-start", paddingRight: "50px"}}>
       {(alumnos.map((alumno) => (
  <Test key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} pregunta1TestIntro={pregunta1TestIntro} res1Pre1={res1Pre1} 
  res2Pre1={res2Pre1} res3Pre1={res3Pre1} res4Pre1={res4Pre1} pregunta2TestIntro={pregunta2TestIntro} res1Pre2={res1Pre2} 
  res2Pre2={res2Pre2} res3Pre2={res3Pre2} res4Pre2={res4Pre2} pregunta3TestIntro={pregunta3TestIntro} res1Pre3={res1Pre3} 
  res2Pre3={res2Pre3} res3Pre3={res3Pre3} res4Pre3={res4Pre3} pregunta4TestIntro={pregunta4TestIntro} res1Pre4={res1Pre4} 
  res2Pre4={res2Pre4} res3Pre4={res3Pre4} res4Pre4={res4Pre4} pregunta5TestIntro={pregunta5TestIntro} res1Pre5={res1Pre5} 
  res2Pre5={res2Pre5} res3Pre5={res3Pre5} res4Pre5={res4Pre5} pregunta6TestIntro={pregunta6TestIntro} res1Pre6={res1Pre6} 
  res2Pre6={res2Pre6} res3Pre6={res3Pre6} res4Pre6={res4Pre6} pregunta7TestIntro={pregunta7TestIntro} res1Pre7={res1Pre7} 
  res2Pre7={res2Pre7} res3Pre7={res3Pre7} res4Pre7={res4Pre7} pregunta8TestIntro={pregunta8TestIntro} res1Pre8={res1Pre8} 
  res2Pre8={res2Pre8} res3Pre8={res3Pre8} res4Pre8={res4Pre8} pregunta9TestIntro={pregunta9TestIntro} res1Pre9={res1Pre9} 
  res2Pre9={res2Pre9} res3Pre9={res3Pre9} res4Pre9={res4Pre9} pregunta10TestIntro={pregunta10TestIntro} res1Pre10={res1Pre10} 
  res2Pre10={res2Pre10} res3Pre10={res3Pre10} res4Pre10={res4Pre10} resPre1={resPre1} resPre2={resPre2} resPre3={resPre3}
  resPre4={resPre4} resPre5={resPre5} resPre6={resPre6} resPre7={resPre7} resPre8={resPre8} resPre9={resPre9} resPre10={resPre10} introTestIntro={introTestIntro}
   activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
   </Stack>


  </Stack>
       
         
         
     
            </Grid>}


            {tipoDiapo === "preposttest" && <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{}}>
             
             <Stack sx= {{bgcolor: "#4a5b82", marginTop: "0px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px", width: "70%"}}>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", paddingTop: "0px", paddingBottom: "20px", textAlign: "justified" }}>
                 {parse(introTestIntro)}
               </Typography>
                      <Stack sx={{paddingBottom: "20px", alignItems: "flex-end"}}>
                      {(alumnos.map((alumno) => (
  <Alumno key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} textoAlumno={alumno.textoAlumno}  activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
   </Stack>
               
               


  </Stack>
       
         
     
            </Grid>}


            {tipoDiapo === "respuestaguiada" && <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{}}>
             
             <Stack sx= {{minHeight: "89vh",bgcolor: "#4a5b82", marginTop: "0px", marginBottom: "30px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px"}}>
              
              


               <Stack sx={{paddingBottom: "20px", alignItems: "flex-start"}}>
               {(alumnos.map((alumno) => (
  <Test key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} pregunta1TestIntro={pregunta1TestIntro} res1Pre1={res1Pre1} 
  res2Pre1={res2Pre1} res3Pre1={res3Pre1} res4Pre1={res4Pre1} pregunta2TestIntro={pregunta2TestIntro} res1Pre2={res1Pre2} 
  res2Pre2={res2Pre2} res3Pre2={res3Pre2} res4Pre2={res4Pre2} pregunta3TestIntro={pregunta3TestIntro} res1Pre3={res1Pre3} 
  res2Pre3={res2Pre3} res3Pre3={res3Pre3} res4Pre3={res4Pre3} pregunta4TestIntro={pregunta4TestIntro} res1Pre4={res1Pre4} 
  res2Pre4={res2Pre4} res3Pre4={res3Pre4} res4Pre4={res4Pre4} pregunta5TestIntro={pregunta5TestIntro} res1Pre5={res1Pre5} 
  res2Pre5={res2Pre5} res3Pre5={res3Pre5} res4Pre5={res4Pre5} pregunta6TestIntro={pregunta6TestIntro} res1Pre6={res1Pre6} 
  res2Pre6={res2Pre6} res3Pre6={res3Pre6} res4Pre6={res4Pre6} pregunta7TestIntro={pregunta7TestIntro} res1Pre7={res1Pre7} 
  res2Pre7={res2Pre7} res3Pre7={res3Pre7} res4Pre7={res4Pre7} pregunta8TestIntro={pregunta8TestIntro} res1Pre8={res1Pre8} 
  res2Pre8={res2Pre8} res3Pre8={res3Pre8} res4Pre8={res4Pre8} pregunta9TestIntro={pregunta9TestIntro} res1Pre9={res1Pre9} 
  res2Pre9={res2Pre9} res3Pre9={res3Pre9} res4Pre9={res4Pre9} pregunta10TestIntro={pregunta10TestIntro} res1Pre10={res1Pre10} 
  res2Pre10={res2Pre10} res3Pre10={res3Pre10} res4Pre10={res4Pre10} resPre1={resPre1} resPre2={resPre2} resPre3={resPre3}
  resPre4={resPre4} resPre5={resPre5} resPre6={resPre6} resPre7={resPre7} resPre8={resPre8} resPre9={resPre9} resPre10={resPre10} introTestIntro={introTestIntro}
   activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
   </Stack>


  </Stack>
       
         
         
     
            </Grid>}



        
        
           
         </Grid>
  
    </div>
    
    </CssBaseline>
    </AuthProvider> 
  )
}

export default MasterDerechoProcesal