import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { auth, registerNewUser, userExists } from '../firebase/firebase';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';


export default function AuthProvider({children, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered}) {

    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async(user) => {
            if(user){
                const isRegistered = await userExists(user.uid);
                if(isRegistered){
                    onUserLoggedIn(user)
                } else {
                    await registerNewUser({
                        uid: user.uid,
                        displayName: user.displayName,
                        processCompleted: true,
                    })
                    onUserNotRegistered(user);
                }
            } else {
                onUserNotLoggedIn();
            }
        });
    }, [navigate]);



    return <div>{children} </div>
}