import React from 'react'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Header from './Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';

import Link from '@mui/material/Link';
import { getPaginas } from '../firebase/firebase';
import AuthProvider from './authProvider';
import { v4 as uuid } from 'uuid';
import { updatePagina } from '../firebase/firebase';
import Pagina from './Pagina';

import { getAlumnos } from '../firebase/firebase';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import HeaderLoggedIn from './HeaderLoggedIn';
import TextField from '@mui/material/TextField';
import { insertNewMatricula } from '../firebase/firebase';
import Autocomplete from '@mui/material/Autocomplete';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const formasPago = ['Un solo plazo de 1050 euros', 'Dos plazos semestrales de 525 euros cada uno', '12 plazos mensuales de 87,5 euros cada uno', "24 plazos mensuales de 43,75 euros cada uno"];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

  

function MatriculaComp(getPageId) {
    


  let params = useParams();
  
      const theme = createTheme({
        palette: {
          primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
          },
        },
      });
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
       
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    
      const section = {
        height: "100%",
        paddingTop: 5,
        backgroundColor: "#fff"
      };
    
        const [title, setTitle] = useState("")
        const [currentUser, setCurrentUser] = useState({})
        const [paginas, setPaginas] = useState([])
        const [numSteps, setNumSteps] = useState(1)
        const [step1, setStep1] = useState("")
        const [step2, setStep2] = useState("")
        const [step3, setStep3] = useState("")
        const steps = [];
        const [texto, setTexto] = useState("")
        const [texto2, setTexto2] = useState("")
        const [texto3, setTexto3] = useState("")
        const [introTestIntro, setIntroTestIntro] = useState("")
        const [alumnos, setAlumnos] = useState([])
        const [inicioSesión, setInicioSesión] = useState("iniciosesion")
        const [nombreCompleto, setNombreCompleto] = useState("")
        const [telefono, setTelefono] = useState("")
        const [email, setEmail] = useState("")
        const [matriculas, setMatriculas] = useState([])
        const [formularioEnviado, setFormularioEnviado] = useState(false)
    
        function handleButtonRemoveStep2 () {
          const newNumSteps = numSteps - 1;
          setNumSteps(newNumSteps);
          setStep1(""); 
          setStep2(""); 
          setStep3(""); 
    
         }
    
    
         useEffect(() => {
          getPaginas();
          getAlumnos();
          console.log(params.pageId);
          console.log(alumnos)
          console.log(currentUser) 
        }, [])

        const [activeStep, setActiveStep] = React.useState(0);
      const [completed, setCompleted] = React.useState({});
      const [index, setIndex] = useState(1)
    
      const totalSteps = () => {
        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
      const isLastStep = () => {
        return activeStep === numSteps - 1;
      };
    
      const allStepsCompleted = () => {
        return completedSteps() === numSteps;
      };
    
      const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
        console.log(index)
        setIndex(index+1)
        console.log(index)
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setIndex(index-1)
        console.log(index)
      };
    
      const handleStep = (step) => () => {
        setActiveStep(step);
      };
    
      const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      };
    
      const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
      };
    
    
    
        async function handleUserLoggedIn(user){
          setCurrentUser(user);  
          const resPaginas = await getPaginas(user.uid);
          setPaginas([...resPaginas]);
          console.log("logged in")

          
        const resAlumno = await getAlumnos(user.uid)
        setAlumnos([...resAlumno])
         console.log(alumnos)
        }
        
        function handleUserNotLoggedIn(user){
            console.log("Not logged in")
        }
        
        function handleUserNotRegistered(user){
            console.log("Not registered")
        }
    
        function handleSubmit (e) {
           e.preventDefault();
           steps.push(step1)
           steps.push(step2)
           addPagina()
           console.log(steps)
           setFormularioEnviado(true)
    
           }
    
        function handleChange(e) {
            const value = e.target.value;
            if(e.target.name === "title") {
                setTitle(value);
                console.log(title)
            }
    
            if(e.target.name === "step1") {
              setStep1(value);
              console.log(step1)
              steps.push(step1)
    
              if (step2 === "") {
                setNumSteps(1);
                steps.push(step1)
              }
            
          }
        
    
        if(e.target.name === "step2") {
          setStep2(value);
          console.log(step2)
          steps.push(step2)
          console.log(steps)
    
          if (step2 !== "" && step3 === "") {
            setNumSteps(2);
            steps.push(step2)
          console.log(steps)
          }
        
          if (step2 === "") {
            setNumSteps(1)
            steps.push(step2)
          console.log(steps)
          }
      }
    
      if(e.target.name === "step3") {
        setStep3(value);
        console.log(step3)
        steps.push(step3)
        console.log(steps)
    
        if (step3 !== ""  && step2 !== "") {
          setNumSteps(3);
        }
      
        if (step3 === "") {
          setNumSteps(2)
        }
    }
    
    }
    
    
    console.log(step2)
    console.log(numSteps)
    console.log(step3)
    

    
    function handleDelete(){
    
    }
    
    async function handleUpdate(docId, 
      title,
      step1, 
      step2,
      step3,
      numSteps,
      texto,
      texto2,
      texto3,
    ){
      
      const pagina = paginas.find((item) => item.docId === docId);
      pagina.title = title;
      pagina.step1 = step1;
      pagina.step2 = step2;
      pagina.step3 = step3;
      pagina.numSteps = numSteps;
      pagina.texto = texto;
      pagina.texto2 = texto2;
      pagina.texto3 = texto3;
    
      await updatePagina(docId, pagina)
      console.log(pagina, docId, title)
    }
        
    console.log(paginas)
    console.log(steps)
    
    const matches = []
    
    
    function renderLinks() {
      if (paginas.length > 0) {
        return paginas.map((pagina) => (
          <Pagina
            key={pagina.id}
            docId={pagina.docId}
            title={pagina.title}
            step1={pagina.step1}
            step2={pagina.step2}
            step3={pagina.step3}
            numSteps={pagina.numSteps}
            texto={pagina.texto}
            texto2={pagina.texto2}
            texto3={pagina.texto3}
          
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        ));
      }
    }
    
    const paginasProcesal = []
    
    const eachjob = paginas.map((doc, index) => {
     
        
    
        if (doc.curso === "procesal")
        
         {
          paginasProcesal.push(doc)
        }
        
      })
    console.log(paginasProcesal)
    const ordered = [...paginasProcesal].sort((a,b) => a.paginar - b.paginar);
    
    function indice() {
        
      return ordered.map((pagina, index) => (
        <div>
          {index === 0 && 
          <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "20px"}}>Tema 1: {pagina.nombreTema} </Typography>
          </Link>}
          {index === 2 && 
          <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "18px"}}>Capítulo 1: {ordered[index].title}</Typography>
          </Link>}
            {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada") && <Typography> Título {ordered[index].tema}: {ordered[index].nombreTema} </Typography>}
    
            <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
            {(index !== 0 && index !== 2 && ordered[index].title !== ordered[index - 1].title) && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "20px", fontSize: "18px"}} > Capítulo {ordered[index].capitulo}: {ordered[index].title} </Typography>}
            </Link>
    
            <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
              
        {ordered[index].tipoDiapo !== "portada" && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "14px"}} >{pagina.nombreDiapo}</Typography>}
        </Link>
        
        
        
        </div>
      ));
    
    }
    
    
    console.log(steps)
    console.log(index)
    console.log(paginas)
    console.log(ordered)
    console.log(paginasProcesal)

    const handleChangeInicioSesion = (event, newAlignment) => {
      setInicioSesión(newAlignment);
    };
    
    function handleClick () {
    

    }

    function handleChange(e) {
      const value = e.target.value;
      if(e.target.name === "nombreCompleto") {
          setNombreCompleto(value);
          
      }

      if(e.target.name === "telefono") {
        setTelefono(value);
        
    }

    if(e.target.name === "email") {
      setEmail(value);
      
  }
    }


    function addPagina() {
      const newMatricula = {
        id: uuid(),
        nombreCompleto: nombreCompleto,
        telefono: telefono,
        email: email,
       
      };
      const res = insertNewMatricula(newMatricula)
      newMatricula.docId = res.id;
      setTitle("");
      setStep1("");
      setMatriculas([...paginas, newMatricula])
      
      console.log(paginas)
    }

  
    return (
      <div>
             <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}

      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >


          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82"}}>
                <Stack sx={{backgroundImage: `url(${"/images/ciudad.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{bgcolor: "#4a5b82", opacity: "0.8"}}>

                      </Grid>



                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{minHeight: "700px", textAlign: "center", padding: "20px", bgcolor: "#4a5b82", opacity: "0.8"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "230px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Máster en Derecho <br></br> Procesal Civil


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Adquiere los conocimientos teóricos y prácticos necesarios <br></br> para convertirte en un experto abogado procesalista


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2} sx= {{bgcolor: "#4a5b82", opacity: "0.8"}}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Duración:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        210 horas
                        </Typography>
                        </Stack>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                      <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Modalidad:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        100% online
                        </Typography>
                        </Stack>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                      <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Formato:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        Part-time
                        </Typography>
                        </Stack>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                      <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Modalidad:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        100% online
                        </Typography>
                        </Stack>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
           
                 



 




       </Stack>
                  </Item>
                  
          </Grid>
     


<Grid item xs={12} sm={12} md={12} lg={12} xl={12} >



{Object.keys(currentUser).length === 0 && <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>
<Stack sx={{alignItems: "center"}} >


</Stack>


<Stack sx={{alignItems: "center"}} >


      
</Stack>
            
</Grid>}




<Grid container>

<Grid xs={2}  >


</Grid>

<Grid xs={8}  >
<Stack sx={{alignItems: "center"}}>
<Stack sx={{bgcolor: "#4a5b82", color: "#0c2b5c", width: "700px", marginTop: "25px", marginLeft: "20px", marginRight: "20px", borderRadius: "5px", padding: "20px", alignContent: "center", textAlign: "center", alignItems: "center", justifyContent: "center", marginBottom: "50px" }}>

<Typography sx={{fontSize: "16px", color: "#d5dbe8", fontFamily: "Lato", fontWeight: "500", paddingTop: "35px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", textAlign: "center"}}>Esperamos que hayas disfrutado del primer tema del Máster en Derecho Procesal Civil. 
Este es solo el primer paso para convertirte en un experto abogado procesalista. </Typography>

<Typography sx={{fontSize: "16px", color: "#d5dbe8", fontFamily: "Lato", fontWeight: "500", paddingTop: "0px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", textAlign: "center"}}>El objetivo del máster es proporcionarte los conocimientos sobre derecho procesal 
equivalentes a los de un abogado de uno de los principales despachos de abogados de cinco años de experiencia. Como has visto en el primer tema, no solo aprenderás la teoría, sino que te enfrentarás a multitud de casos prácticos que te enseñarán a pensar como un abogado 
procesalista. Si completas el máster con éxito, estarás preparado para enfrentarte a cualquier reto que se te plantee en tu carrera como abogado procesalista especializado en Derecho de los negocios.</Typography>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>Solicitud de matrícula</Typography>

{!formularioEnviado && <div>

<Typography variant="h6" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>Completa el formulario y recibirás <br/> en tu
correo electrónico toda la información necesaria <br/> para completar el proceso de matrícula</Typography>


<Stack sx={{alignItems: "center" }}>

<Box component="form" noValidate onSubmit={handleSubmit} sx={{width: "500px", color: "white", marginRight: "20px", marginLeft: "20px", borderRadius: "5px", padding: "20px", alignContent: "center", textAlign: "center"}}>
            
            <Typography sx={{color: "#d5dbe8", paddingTop: "10px"}}>
                Nombre completo
              </Typography>

            <TextField   
                sx={{input: {color: "grey", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                fullWidth
                margin="normal"
          size="small"
                fullWidthrespuestaguia
                id="nombreCompleto"
                
                name="nombreCompleto"
                autoComplete="nombreCompleto"
              
                onChange={handleChange}
                value={nombreCompleto}
              />
         <Typography sx={{color: "#d5dbe8", paddingTop: "30px"}}>
                Teléfono de contacto
              </Typography>
              
            <TextField   
                sx={{input: {color: "grey", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                fullWidth
                margin="normal"
          size="small"
                fullWidthrespuestaguia
                id="telefono"
                
                name="telefono"
                autoComplete="telefono"
              
                onChange={handleChange}
                value={telefono}
              />

         <Typography sx={{color: "#d5dbe8", paddingTop: "30px"}}>
                Correo electrónico
              </Typography>

<TextField   
                sx={{input: {color: "grey", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green", paddingBottom: "30px"}}
                fullWidth
                margin="normal"
          size="small"
                fullWidthrespuestaguia
                id="email"
                
                name="email"
                autoComplete="email"
              
                onChange={handleChange}
                value={email}
              />
            
          



                      <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#d5dbe8", color: "#4a5b82", marginTop: "30px", width: "300px", '&.MuiButton-root:hover':{bgcolor: '#f0f1f5'} }}

              >
                Enviar
              </Button>
             
         </Box>

         </Stack> 
         </div>}


         {formularioEnviado && <div>

<Typography variant="h6" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>¡Solicitud enviada! <br/> Recibirás noticias nuestras en menos de 24 horas <br/> Comprueba tu carpeta de correo no deseado</Typography>


<Stack sx={{alignItems: "center" }}>


             
 

         </Stack> 
         </div>}

         </Stack>






</Stack>

</Grid>

<Grid xs={2}  >

</Grid>



</Grid>

              

              </Grid>


 










   




          














                
  
    
  
  
          <Grid container spacing={2} sx={{height: '100%'}}>
       
           
            
          </Grid>
          </Box>




          <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >


<Grid xs={12} sx={{}}>
  <Item sx={{bgcolor: "#4a5b82"}}>
      <Stack sx={{backgroundImage: `url(${"/images/ciudad.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
  <Grid container>
            <Grid item xs={2} sm={2} md={2} sx= {{bgcolor: "#4a5b82", opacity: "0.8"}}>

            </Grid>



            <Grid item xs={8} sm={8} md={8}>
              <Stack sx= {{minHeight: "700px", textAlign: "center", padding: "20px", bgcolor: "#4a5b82", opacity: "0.8"}}>

<Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "60px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Máster en Derecho <br></br> Procesal Civil


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Adquiere los conocimientos teóricos y prácticos necesarios <br></br> para convertirte en un experto abogado procesalista


</Typography>

</Stack>
              </Grid>
              
              <Grid item xs={2} sm={2} md={2} sx= {{bgcolor: "#4a5b82", opacity: "0.8"}}>
              
              </Grid>
          </Grid>
  
     
       








</Stack>
        </Item>
        
</Grid>



<Grid item xs={12} sm={12} md={12} lg={12} xl={12} >



{Object.keys(currentUser).length === 0 && <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>
<Stack sx={{alignItems: "center"}} >


</Stack>


<Stack sx={{alignItems: "center"}} >



</Stack>
  
</Grid>}




<Grid container>

<Grid xs={2}  >


</Grid>

<Grid xs={8}  >
<Stack sx={{alignItems: "center", marginLeft: "60px", marginRight: "60px"}}>
<Stack sx={{bgcolor: "#4a5b82", color: "#0c2b5c", marginTop: "25px", marginLeft: "20px", marginRight: "20px", borderRadius: "5px", padding: "20px", alignContent: "center", textAlign: "center", alignItems: "center", justifyContent: "center", marginBottom: "50px", marginLeft: "60px", marginRight: "60px" }}>

<Typography sx={{fontSize: "16px", color: "#d5dbe8", fontFamily: "Lato", fontWeight: "500", paddingTop: "35px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", textAlign: "center"}}>Esperamos que hayas disfrutado del primer tema del Máster en Derecho Procesal Civil. 
Este es solo el primer paso para convertirte en un experto abogado procesalista. </Typography>

<Typography sx={{fontSize: "16px", color: "#d5dbe8", fontFamily: "Lato", fontWeight: "500", paddingTop: "0px", paddingBottom: "25px", paddingLeft: "25px", paddingRight: "25px", textAlign: "center"}}>El objetivo del máster es proporcionarte los conocimientos sobre derecho procesal 
equivalentes a los de un abogado de uno de los principales despachos de abogados de cinco años de experiencia. Como has visto en el primer tema, no solo aprenderás la teoría, sino que te enfrentarás a multitud de casos prácticos que te enseñarán a pensar como un abogado 
procesalista. Si completas el máster con éxito, estarás preparado para enfrentarte a cualquier reto que se te plantee en tu carrera como abogado procesalista especializado en Derecho de los negocios.</Typography>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>Solicitud de matrícula</Typography>

{!formularioEnviado && <div>

<Typography variant="h6" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>Completa el formulario y recibirás <br/> en tu
correo electrónico toda la información necesaria <br/> para completar el proceso de matrícula</Typography>


<Stack sx={{alignItems: "center" }}>

<Box component="form" noValidate onSubmit={handleSubmit} sx={{color: "white", marginRight: "20px", marginLeft: "20px", borderRadius: "5px", padding: "20px", alignContent: "center", textAlign: "center"}}>
  
  <Typography sx={{color: "#d5dbe8", paddingTop: "10px"}}>
      Nombre completo
    </Typography>

  <TextField   
      sx={{input: {color: "grey", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
      fullWidth
      margin="normal"
size="small"
      fullWidthrespuestaguia
      id="nombreCompleto"
      
      name="nombreCompleto"
      autoComplete="nombreCompleto"
    
      onChange={handleChange}
      value={nombreCompleto}
    />
<Typography sx={{color: "#d5dbe8", paddingTop: "30px"}}>
      Teléfono de contacto
    </Typography>
    
  <TextField   
      sx={{input: {color: "grey", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
      fullWidth
      margin="normal"
size="small"
      fullWidthrespuestaguia
      id="telefono"
      
      name="telefono"
      autoComplete="telefono"
    
      onChange={handleChange}
      value={telefono}
    />

<Typography sx={{color: "#d5dbe8", paddingTop: "30px"}}>
      Correo electrónico
    </Typography>

<TextField   
      sx={{input: {color: "grey", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green", paddingBottom: "30px"}}
      fullWidth
      margin="normal"
size="small"
      fullWidthrespuestaguia
      id="email"
      
      name="email"
      autoComplete="email"
    
      onChange={handleChange}
      value={email}
    />
  




            <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2, backgroundColor: "#d5dbe8", color: "#4a5b82", marginTop: "30px", width: "300px", '&.MuiButton-root:hover':{bgcolor: '#f0f1f5'} }}

    >
      Enviar
    </Button>
   
</Box>

</Stack> 
</div>}


{formularioEnviado && <div>

<Typography variant="h6" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", textAlign: "center"}}>¡Solicitud enviada! <br/> Recibirás noticias nuestras en menos de 24 horas <br/> Comprueba tu carpeta de correo no deseado</Typography>


<Stack sx={{alignItems: "center" }}>


   


</Stack> 
</div>}

</Stack>






</Stack>

</Grid>

<Grid xs={2}  >

</Grid>



</Grid>

    

    </Grid>

































      




<Grid container spacing={2} sx={{height: '100%'}}>

 
  
</Grid>
</Box>





        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default MatriculaComp