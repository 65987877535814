import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import userEvent from '@testing-library/user-event';
import AuthProvider from '../components/authProvider';
import HeaderLoggedIn from '../components/HeaderLoggedIn';
import Link from '@mui/material/Link';
import Footer from '../components/Footer';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function Cursos() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({});

    function handleUserLoggedIn(user){
      setCurrentUser(user)
      console.log("logged in")
  }
  
  function handleUserNotLoggedIn(user){
      console.log("Not logged in")
  }
  
  function handleUserNotRegistered(user){

      console.log("Not registered")
  }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
            <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}
         
      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
         
          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  








                 
                  </Item>
          </Grid>
          </Box>
 





 
          <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >
         
         <Grid xs={12} sx={{}}>
           <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
           <Grid container>
 
                     <Grid item xs={12} sm={12} md={12}>
                       <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
  
  <Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
  Law School | Derecho de los negocios


      </Typography>
  <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
  Formación especializada diseñada para el éxito en la abogacía de élite


      </Typography>
     
  </Stack>
                       </Grid>
                       
    
                   </Grid>
           
       



  
                
     
  








                
                 </Item>
         </Grid>

         <Stack sx={{minHeight: '50vh', bgcolor: "white", marginTop: "50px", marginBottom: "30px"}}>
     
    
       
                        

  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "0px", justifyContent: "center", marginLeft: "15px", marginRight: "15px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Estamos preparando nuestra oferta <br/> de cursos. Mantente atento.
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Mientras tanto, echa un vistazo a nuestros másteres
  
  
              </Typography>
              <Button variant="contained" href="./nuestrosmasteres" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Ver másteres
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>




                        </Stack>
         </Box> 
          













         <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >

<Grid container spacing={2} sx={{minHeight: '50vh', bgcolor: "white", marginTop: "50px", marginBottom: "30px"}}>
     
    
       
                        
       
       <Grid xs={4}>
    
  </Grid>
  
  <Grid xs={4}>
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "0px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Estamos preparando nuestra oferta <br/> de cursos. Mantente atento.
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Mientras tanto, echa un vistazo a nuestros másteres
  
  
              </Typography>
              <Button variant="contained" href="./nuestrosmasteres" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Ver másteres
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
  </Grid>

  
  <Grid xs={4}>
    
    </Grid>

                        </Grid>
                
  
    
  
  
          <Grid container spacing={2} sx={{height: '100%'}}>
       
           
            
          </Grid>






</Box>











<Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >

<Grid container spacing={2} sx={{minHeight: '50vh', bgcolor: "white", marginTop: "50px", marginBottom: "30px"}}>
     
    
       
                        
       
       <Grid xs={4}>
    
  </Grid>
  
  <Grid xs={4}>
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "0px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Estamos preparando nuestra oferta <br/> de cursos. Mantente atento.
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Mientras tanto, echa un vistazo a nuestros másteres
  
  
              </Typography>
              <Button variant="contained" href="./nuestrosmasteres" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Ver másteres
              </Button>
              
              
          </Stack>

    
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
  </Grid>

  
  <Grid xs={4}>
    
    </Grid>

                        </Grid>
                
  
    
  
  
          <Grid container spacing={2} sx={{height: '100%'}}>
       
           
            
          </Grid>






</Box>




<Footer/>






        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default Cursos