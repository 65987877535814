import React from "react";
import logo from './logo.svg';
import './App.css';
import { Outlet, Link } from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Home from "./pages/Home";
import CursoProcesalPagina1 from "./pages/CursoExpertoDerechoProcesal/CursoProcesalPagina1";
import CursoProcesalPagina2 from "./pages/CursoExpertoDerechoProcesal/CursoExpertoProceslPagina2";
import PortadaCursoExpertoDerechoProcesal from "./pages/CursoExpertoDerechoProcesal/PortadaCursoExpertoDerechoProcesal";
import PlantillaTeoria from "./pages/PlantillaTeoria";
import LoginView from "./pages/LoginView";
import Creator from "./pages/Creator";
import IndiceCreator from "./pages/IndiceCreator";
import SinglePage from "./pages/SinglePage";
import { useState, useEffect } from 'react';
import CreatorPaginaIntermedia from "./pages/CreatorPaginaIntermedia";
import MasterDerechoProcesal from "./pages/masterderechoprocesal";
import PresentacionMasterDerechoProcesal from "./pages/CursoExpertoDerechoProcesal/PresentacionMasterDerechoProcesal";
import Dashboard from "./pages/Dashboard";
import Dashboardmasterderechoprocesal from "./pages/Dashboardmasterderechoprocesal";
import FormularioMatriculaMasterDerechoProcesal from "./pages/CursoExpertoDerechoProcesal/FormularioMatriculaMasterDerechoProcesal";
import { Worker } from '@react-pdf-viewer/core';
import FormularioAdmisionMasterDerechoProcesal from "./pages/CursoExpertoDerechoProcesal/FormularioAdmisionMasterDerechoProcesal";
import NuestrosMasteres from "./pages/NuestrosMasteres";
import Avisolegal from "./pages/AvisoLegal";
import Politicadecookies from "./pages/PoliticaDePrivacidad";
import Cursos from "./pages/Cursos";
import SobreNosotros from "./pages/SobreNosotros";
import Matriculate from "./pages/CursoExpertoDerechoProcesal/Matriculate";
import Smartlook from 'smartlook-client'
import PracticasMasterAccesoAbogacia from "./pages/MasterDerechoProcesal/PracticasMasterAccesoAbogacia";
import MeContrataran from "./pages/Blog/MeContrataran";
import AQueTipo from "./pages/Blog/AQueTipo";
import EnQueDespacho from "./pages/Blog/EnQueDespacho";
import CuandoComienzo from "./pages/Blog/CuandoComienzo";
import EscritorioMasterDerechoPenal from "./pages/Profesores/EscritorioMasterDerechoPenal";
import CreatorPenal from "./pages/CreatorPenal";
import SinglePagePenal from "./pages/SinglePagePenal";
import MasterWhiteCollar from "./pages/masterwhitecollar";
import PresentacionMasterDerechoPenal from "./pages/CursoExpertoDerechoProcesal/PresentacionMasterDerechoPenal";


function App() {

  const [pageId, setPageId] = useState("");

  const getPaginaIdHandler = (id) => {
    console.log(id);
    setPageId(id);
  }

  return (
    <div>

      
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/PracticasMasterAccesoAbogacia" element={<PracticasMasterAccesoAbogacia />} />
    <Route path="/me-contrataran-despues-de-las-practicas-de-master-de-acceso/" element={<MeContrataran />} />
    <Route path="/en-que-tipo-de-despacho-tengo-posibilidades-de-hacer-las-practicas-del-master-de-acceso/" element={<AQueTipo />} />
    <Route path="/en-que-despacho-hago-las-practicas-del-master-de-acceso/" element={<EnQueDespacho />} />
    <Route path="/cuando-comienzo-buscar-practicas-master-acceso/" element={<CuandoComienzo />} />
    <Route path="/NuestrosMasteres" element={<NuestrosMasteres />} />
    <Route path="/AvisoLegal" element={<Avisolegal />} />
    <Route path="/PoliticadePrivacidad" element={<Politicadecookies />} />
    <Route path="/Cursos" element={<Cursos />} />
    <Route path="/Matriculate" element={<Matriculate />} />
    <Route path="/SobreNosotros" element={<SobreNosotros />} />
    <Route path="/Portadacursoexpertoderechoprocesal" element={<PortadaCursoExpertoDerechoProcesal/>} />
    <Route path="/Dashboardmasterderechoprocesal" element={<Dashboardmasterderechoprocesal getPaginaId={getPaginaIdHandler}/>} />
    <Route path="Dashboardmasterderechoprocesal/:pageId" element={<Dashboardmasterderechoprocesal id={pageId} setPageId={setPageId}  />} />
    <Route path="/Formulariomatriculamasterderechoprocesal" element={<FormularioMatriculaMasterDerechoProcesal getPaginaId={getPaginaIdHandler}/>} />
    <Route path="/Formularioadmisionmasterderechoprocesal" element={<FormularioAdmisionMasterDerechoProcesal getPaginaId={getPaginaIdHandler}/>} />
    <Route path="Formulariomatriculamasterderechoprocesal/:pageId" element={<FormularioMatriculaMasterDerechoProcesal id={pageId} setPageId={setPageId}  />} />
    <Route path="/CursoProcesalPagina1" element={<CursoProcesalPagina1 />} />
    <Route path="/CursoProcesalPagina2" element={<CursoProcesalPagina2 />} />
    <Route path="/PlantillaTeoria" element={<PlantillaTeoria />} />
    <Route path="/loginview" element={<LoginView />} />
    <Route path="/Creator" element={<Creator />} />
    <Route path="/CreatorPenal" element={<CreatorPenal />} />
    <Route path="/Creatorpaginaintermedia" element={<CreatorPaginaIntermedia />} />
    <Route path="/IndiceCreator" element={<IndiceCreator getPaginaId={getPaginaIdHandler}/>} />
    <Route path="IndiceCreator/:pageId" element={<SinglePage  id={pageId} setPageId={setPageId}  />} />
    <Route path="/EscritorioMasterDerechoPenal" element={<EscritorioMasterDerechoPenal getPaginaId={getPaginaIdHandler}/>} />
    <Route path="EscritorioMasterDerechoPenal/:pageId" element={<SinglePagePenal id={pageId} setPageId={setPageId}  />} />
    <Route path="/PresentacionMasterDerechoProcesal" element={<PresentacionMasterDerechoProcesal />} />
    <Route path="/PresentacionMasterDerechoPenal" element={<PresentacionMasterDerechoPenal />} />
    <Route path="masterderechoprocesal/:pageId" element={<MasterDerechoProcesal id={pageId} setPageId={setPageId}  />} />
    <Route path="masterwhitecollar/:pageId" element={<MasterWhiteCollar id={pageId} setPageId={setPageId}  />} />
  </Routes>
  </div>);
}

export default App;
