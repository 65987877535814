import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { auth, userExists } from '../firebase/firebase';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import AuthProvider from '../components/authProvider';
import { createUserWithEmailAndPassword} from 'firebase/auth';
import { Auth } from 'firebase/auth';
import { signOut } from 'firebase/auth';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


function LoginView() {
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useState(null);
    const [state, setCurrentState] = useState("No registrado");
    const [isRegistered, setIsRegistered] = useState(false)
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [user, setUser] = useState({});
    const [userCredentials, setUserCredentials] = useState("")

    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser)
    } )

    
    const register = async () => {
        try {
            const user = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
            console.log(user)
        } catch (error) {
            console.log(error.message);
        }
    }

    

    function handleUserLoggedIn(user){
        console.log("logged in")
    }
    
    function handleUserNotLoggedIn(user){
        console.log("Not logged in")
    }
    
    function handleUserNotRegistered(user){

        console.log("Not registered")
    }

   /* useEffect(() => {
        onAuthStateChanged(auth, handleUserStateChanged);
    }, []);

    async function handleUserStateChanged(user){
        if (user) {
            const isRegistered = await userExists(user.uid)
            if(isRegistered){
                navigate("./")
                setCurrentState("Estás registrado")
                console.log(state)
            }
            navigate("./")
            console.log(user.displayName);
            console.log(isRegistered);
        } else {
            console.log("Nadie")
        }
    }
*/


    async function handleOnClick(){
        const googleProvider = new GoogleAuthProvider();
        await singInWithGoogle(googleProvider)
        console.log("clic")
    }

   async  function singInWithGoogle(googleProvider){
        try{
            const res = await signInWithPopup(auth, googleProvider)
            console.log(res)
        } catch (error) {
            console.log(error);
        }
    }

function handleLogOut(){
    signOut(auth).then(()=> {
        console.log("Yeeees")
    }).catch((error) => {

    } )
}
    
function handleLogin(e) {
    e.preventDefault();

    signInWithEmailAndPassword(auth, user.email, user.password)
    .then((userCredential) => {
      // Signed in 
      console.log (userCredential.user);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });



}

  return (
    <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
            <div>
        Hola
        <button onClick={handleOnClick}>Log in</button>
      <Button onClick={handleOnClick} >
        Log in con Google
      </Button>
      
      <input type="text" name="email" placeholder="Email" onChange={(event) => {setRegisterEmail(event.target.value)}} ></input>
      <input type="text" name="password" placeholder="Password" onChange={(event) => {setRegisterPassword(event.target.value)}} ></input>
      <button onClick={register}>Create user</button>
      <button onClick={handleLogin}>LogIn</button>
      <button onClick={handleLogOut}>Fuera</button>
      {user && currentUser } Hola
    </div>
  
    </AuthProvider>)
    
    

}

export default LoginView
