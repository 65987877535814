import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import userEvent from '@testing-library/user-event';
import AuthProvider from '../../components/authProvider';
import HeaderLoggedIn from '../../components/HeaderLoggedIn';
import Link from '@mui/material/Link';
import Footer from '../../components/Footer';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function EnQueDespacho() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({});

    function handleUserLoggedIn(user){
      setCurrentUser(user)
      console.log("logged in")
  }
  
  function handleUserNotLoggedIn(user){
      console.log("Not logged in")
  }
  
  function handleUserNotRegistered(user){

      console.log("Not registered")
  }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
            <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}
         
      <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >
         
          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                    <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>
                  <Stack sx= {{minHeight: "525px", textAlign: "justify"}}>

                    <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                      <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", textAlign: "center"}}>
            ¿En qué despacho hago las prácticas del máster de acceso?
  
  
                      </Typography>

                      <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "30px", paddingLeft: "0px", textAlign: "center"}}>
                      El despacho en el que hagas tus prácticas de máster de acceso a la abogacía marcará tu carrera profesional. Aquí te ofrecemos las claves que debes tener en cuenta para elegir el despacho idóneo para ti.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "60px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                  El despacho en el que hagas tus prácticas de máster de acceso a la abogacía tendrá una importancia muy relevante en el inicio de tu carrera profesional. Un alto porcentaje de los estudiantes de máster de acceso se incorpora a trabajar como abogado junior en el mismo despacho en el que realizó sus prácticas del máster. Cambiar de despacho el primer año después de las prácticas, aunque no es imposible, no es sencillo. Por ello, es importante elegir el despacho en el que se harán las prácticas del máster de acceso con la mayor información posible, minimizando así la probabilidad de no elegir el despacho idóneo.
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                
                      Para tomar la mejor decisión, debes tener en cuenta los siguientes puntos:

  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
            
                     <u> Tipo de despacho:</u>
                  
                                      </Typography>


                                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                      Antes de ponerte a pensar qué despacho concreto prefieres, debes plantearte el tipo de despachos que crees que se adapta mejor a tu perfil y expectativas.
              
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  En primer lugar, debes hacer un ejercicio de honestidad contigo mismo y valorar si cumples los rigurosos requisitos que exigen algunos tipos de despachos (fundamentalmente, los grandes despachos nacionales e internacionales). Sé ambicioso e intenta acceder al tipo de despacho que más te guste, pero sé también realista y valora otras opciones para el caso de que no salga bien.
        
                            </Typography>


                            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                            En segundo lugar, debes valorar qué tipo de despacho se adapta mejor a tus expectativas de carrera. Es muy probable que todavía no sepas qué tipo de carrera vas a querer hacer, y que en todo caso tu opinión al respecto cambie con los años. Pero si ya tienes una idea clara, escoge el tipo de despacho que mejor se adapte a la misma.
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Si por ejemplo tienes claro que quieres jugar en Primera División, y que estás dispuesto a asumir el esfuerzo y presión que ello conlleva, apuesta por los grandes despachos de abogados nacionales e internacionales. Si por el contrario tienes claro que la vida de abogado de gran despacho no es para ti, busca despachos medianos con un tipo de carrera más relejada. También puede darse el caso de que tengas claro desde el principio que la carrera de abogado externo no es para ti, y te veas como abogado interno (in-house) de una compañía.      </Typography>



                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Nuestro consejo es que, si no lo tienes claro, apuestes por los grandes despachos de abogados. Aprenderás mucho, participarás en los asuntos más complejos y relevantes del mercado, “harás currículum”, y siempre tendrás la posibilidad de moverte más tarde a otro tipo de despacho o empresas. Por el contrario, si decides empezar en un despacho mediano, pequeño, o una empresa, y posteriormente quieres moverte a uno de los grandes despachos nacionales o internacionales, te resultará más difícil.

                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Área de Práctica:</u>
                                  </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Otro paso previo antes de analizar qué despacho concreto escoger es pensar el área de práctica que más te interesa, y la importancia que tiene para ti comenzar en uno u otro área de práctica. Es frecuente que muchos despachos –con la salvedad de los grandes despachos nacionales- no saquen todos los años plazas de prácticas de máster de acceso en todas sus áreas de práctica. También es frecuente que los despachos impongan a los recién llegados rotaciones por distintas áreas de práctica, lo que implica que el candidato no sepa con certeza al comienzo de las prácticas en qué área acabará.
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Te recomendamos que des una importancia relativa al área de práctica en el que quieres comenzar tu carrera. Si tienes dos ofertas de dos despachos semejantes, en el que uno te ofrece “tu área de práctica” y otro no, no lo dudes, ¡coge el que te ofrece tu área de práctica! Pero no dejes de ir a un despacho objetivamente “mejor” por el hecho de que no te ofrezca tu área de práctica. Ten en cuenta que estás empezando tu carrera, y que tu conocimiento de ese área de práctica –y del resto- será por lo general prácticamente nulo. Que te gustase la asignatura de procesal no significa que te vaya a gustar ejercer la abogacía en ese área de práctica por encima de cualquier otro, igual que el hecho de que no te gustase la asignatura de derecho administrativo no va a significar que no te guste trabajar en ese área.    </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Si ya has tenido cierta especialización en una área –has hecho un máster específico o prácticas de una duración relevante- da importancia a hacer tus prácticas del máster de acceso en esa área. Si no has tenido esa especialización, intenta en cualquier caso hacer las prácticas en tu área de preferencia, pero mantente abierto a otras posibilidades y no dejes pasar mejores oportunidades por el hecho de que, en este momento, creas que prefieres otro área de práctica.  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Probabilidad de continuar en el despacho tras las prácticas:</u>
                                  </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Como decíamos al inicio del artículo, las prácticas del máster de acceso son el mejor puente para conseguir un empleo en los principales despachos de abogados. La gran mayoría de abogados junior se incorporan a los despachos por esta vía. Por ello es importante que, antes de decidirte por hacer prácticas en uno u otro despacho, trates de tener toda la información posible sobre las posibilidades reales que tienes de incorporarte al despacho tras las prácticas.
                                  </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Los despachos tienen políticas muy diversas a la hora de fichar como abogados junior a los estudiantes de máster de acceso que hicieron las prácticas con ellos. Mientras que en algunos despachos ofrecen plazas de prácticas exclusivamente para aquellas áreas para las que existe una necesidad real de incorporar abogados junior, otros despachos ofrecen más plazas de prácticas, y posteriormente únicamente tienen capacidad para incorporar a algunos de los estudiantes. </Typography>
                
                
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Este es probablemente el factor más determinante que debe guiarte a la hora de decidirte por un despacho u otro. En la medida de lo posible, opta por despachos que en los años anteriores hayan incorporado como juniors a la mayoría de sus estudiantes de prácticas de máster de acceso.  Obviamente esto no te garantiza al 100% continuar en el despacho, pero sí que minimiza las posibilidades de que, pese a haber hecho un buen trabajo, no tengas finalmente la posibilidad de incorporarte por cuestiones ajenas a ti. </Typography>
                
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Tipo de trabajo:</u>
                                  </Typography>
                
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Trata de obtener toda la información posible sobre el tipo de trabajo que se realiza en el área de práctica en el que te han ofrecido hacer las prácticas. Como te decíamos al hablar de áreas de prácticas, probablemente a estas alturas todavía no tengas criterio para poder valorar qué tipo de trabajo es el que más te gustaría hacer. Pero sí que puedes tener una idea clara del tipo de trabajo que te gustaría evitar. En este sentido, es habitual que los abogados traten de evitar (o luchen por salir de ellos) departamentos en los que se hace un tipo de trabajo muy poco variado. No obstante, esto es cuestión de gustos.
                                  </Typography>
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Horarios:</u> </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Si quieres comenzar una carrera en la abogacía de alto nivel, una de las cosas que debes asumir es que tendrás que trabajar mucho, e intensamente. Si lo que buscas es un trabajo cómodo, con horarios bien definidos, y relajado, te aconsejamos buscar otras opciones. </Typography>
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  No obstante, dentro de la dureza intrínseca a la profesión, existen diferencias sustanciales tanto entre despachos como, dentro de un mismo despacho, entre departamentos. Mientras que en algunos despachos no es extraño –incluso puede ser la norma en determinadas épocas- terminar de trabajar de madrugada, en otros despachos estas situaciones solo se producen de forma excepcional. </Typography>
                 
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Aunque el hecho de que quieras iniciar tu carrera en uno de los principales despachos de abogados ya implica que estés dispuesto a trabajar duro, no dejes de tener en cuenta estas diferencias sustanciales entre despachos para tomar tu decisión. </Typography>
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Ambiente:</u> </Typography>
                 
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Vas a pasar muchas horas en el despacho, así que trata de asegurarte de que lo haces en un sitio con un buen ambiente laboral. En general el ambiente en los grandes despachos es bastante bueno. En casi todos ellos hay mucha gente joven, ambiente de camaradería y buen rollo. No obstante, puede haber diferencias importantes tanto entre despachos (algunos se vuelcan especialmente en potenciar el buen ambiente) y departamentos. </Typography>
                 
                 
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Remuneración:</u> </Typography>
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Aunque estemos hablando de prácticas, la remuneración será obviamente otro de los puntos que debas tener en cuenta en tu decisión. Los grandes despachos ofrecen remuneraciones muy atractivas para unas prácticas, que alcanzan niveles próximos a los salarios que perciben los abogados junior. </Typography>
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Al contrario de lo que sucede en otros países, en España los despachos guardan cierto secretismo respecto a la remuneración de sus prácticas. No obstante, no te será difícil descubrir la remuneración que ofrecen cada año los principales despachos, y comparar. </Typography>
                 



                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Te aconsejamos que no prestes especial atención a pequeñas diferencias de remuneración (probablemente diferencias de 100 o 200 euros mensuales a estas alturas de tu carrera no van a ser determinantes), y que te fijes más en la “banda salarial” en la que se sitúa en el despacho. Al fin y al cabo el nivel de remuneración de las prácticas de máster que ofrece cada despacho suele ir en consonancia con el nivel de remuneración que ofrece a sus abogados. La banda salarial en la que se sitúe cada despacho en las prácticas de máster de acceso te permitirá inferir también en qué división en cuanto a remuneración de sus abogados se encuentra. </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Estos son los principales puntos que tienes que valorar para decidir en qué despacho hacer tus prácticas de máster de acceso a la abogacía. Recuerda que puedes conocer la opinión de los estudiantes que ya han hecho sus prácticas de máster de acceso en los principales despachos de abogados viendo los resultados de la encuesta los Programas de Máster de Acceso a la Abogacía Mejor Valorados 2020. </Typography>













                  </Stack>
                        </Grid>
       
                        <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

</Grid>
                    </Grid>
   
                 
      
   </Stack>

   <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>





<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Adquiere los conocimientos necesarios para acceder a los mejores programas de prácticas


                   </Typography>

                   <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Nuestros másteres


                   </Typography>

       </Grid>
                 
                  </Item>
          </Grid>
          </Box>
 





 
          <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >
         
         <Grid xs={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
           <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
 <Grid item xs={12} sm={12} md={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
   <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>

<Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Law School | Derecho de los negocios


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Formación especializada diseñada para el éxito en la abogacía de élite


</Typography>

</Stack>
   </Grid>
           
       
                 <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                 <Stack sx={{height: "50px", bgcolor: "#4a5b82", opacity: "0.9"}}></Stack>
                 <Stack sx= {{textAlign: "justify"}}>

                   <Grid container>
          
                     <Grid item xs={12} sm={12} md={12}>

                     <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9", justifyContent: "center", alignContent: "center", alignItems: "center", paddingLeft: "30px", paddingRight: "30px"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", textAlign: "center"}}>
            ¿En qué despacho hago las prácticas del máster de acceso?
  
  
                      </Typography>

                      <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "30px", paddingLeft: "0px", textAlign: "center"}}>
                      El despacho en el que hagas tus prácticas de máster de acceso a la abogacía marcará tu carrera profesional. Aquí te ofrecemos las claves que debes tener en cuenta para elegir el despacho idóneo para ti.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "60px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                  El despacho en el que hagas tus prácticas de máster de acceso a la abogacía tendrá una importancia muy relevante en el inicio de tu carrera profesional. Un alto porcentaje de los estudiantes de máster de acceso se incorpora a trabajar como abogado junior en el mismo despacho en el que realizó sus prácticas del máster. Cambiar de despacho el primer año después de las prácticas, aunque no es imposible, no es sencillo. Por ello, es importante elegir el despacho en el que se harán las prácticas del máster de acceso con la mayor información posible, minimizando así la probabilidad de no elegir el despacho idóneo.
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                
                      Para tomar la mejor decisión, debes tener en cuenta los siguientes puntos:

  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
            
                     <u> Tipo de despacho:</u>
                  
                                      </Typography>


                                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                      Antes de ponerte a pensar qué despacho concreto prefieres, debes plantearte el tipo de despachos que crees que se adapta mejor a tu perfil y expectativas.
              
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  En primer lugar, debes hacer un ejercicio de honestidad contigo mismo y valorar si cumples los rigurosos requisitos que exigen algunos tipos de despachos (fundamentalmente, los grandes despachos nacionales e internacionales). Sé ambicioso e intenta acceder al tipo de despacho que más te guste, pero sé también realista y valora otras opciones para el caso de que no salga bien.
        
                            </Typography>


                            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                            En segundo lugar, debes valorar qué tipo de despacho se adapta mejor a tus expectativas de carrera. Es muy probable que todavía no sepas qué tipo de carrera vas a querer hacer, y que en todo caso tu opinión al respecto cambie con los años. Pero si ya tienes una idea clara, escoge el tipo de despacho que mejor se adapte a la misma.
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Si por ejemplo tienes claro que quieres jugar en Primera División, y que estás dispuesto a asumir el esfuerzo y presión que ello conlleva, apuesta por los grandes despachos de abogados nacionales e internacionales. Si por el contrario tienes claro que la vida de abogado de gran despacho no es para ti, busca despachos medianos con un tipo de carrera más relejada. También puede darse el caso de que tengas claro desde el principio que la carrera de abogado externo no es para ti, y te veas como abogado interno (in-house) de una compañía.      </Typography>



                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Nuestro consejo es que, si no lo tienes claro, apuestes por los grandes despachos de abogados. Aprenderás mucho, participarás en los asuntos más complejos y relevantes del mercado, “harás currículum”, y siempre tendrás la posibilidad de moverte más tarde a otro tipo de despacho o empresas. Por el contrario, si decides empezar en un despacho mediano, pequeño, o una empresa, y posteriormente quieres moverte a uno de los grandes despachos nacionales o internacionales, te resultará más difícil.

                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Área de Práctica:</u>
                                  </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Otro paso previo antes de analizar qué despacho concreto escoger es pensar el área de práctica que más te interesa, y la importancia que tiene para ti comenzar en uno u otro área de práctica. Es frecuente que muchos despachos –con la salvedad de los grandes despachos nacionales- no saquen todos los años plazas de prácticas de máster de acceso en todas sus áreas de práctica. También es frecuente que los despachos impongan a los recién llegados rotaciones por distintas áreas de práctica, lo que implica que el candidato no sepa con certeza al comienzo de las prácticas en qué área acabará.
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Te recomendamos que des una importancia relativa al área de práctica en el que quieres comenzar tu carrera. Si tienes dos ofertas de dos despachos semejantes, en el que uno te ofrece “tu área de práctica” y otro no, no lo dudes, ¡coge el que te ofrece tu área de práctica! Pero no dejes de ir a un despacho objetivamente “mejor” por el hecho de que no te ofrezca tu área de práctica. Ten en cuenta que estás empezando tu carrera, y que tu conocimiento de ese área de práctica –y del resto- será por lo general prácticamente nulo. Que te gustase la asignatura de procesal no significa que te vaya a gustar ejercer la abogacía en ese área de práctica por encima de cualquier otro, igual que el hecho de que no te gustase la asignatura de derecho administrativo no va a significar que no te guste trabajar en ese área.    </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Si ya has tenido cierta especialización en una área –has hecho un máster específico o prácticas de una duración relevante- da importancia a hacer tus prácticas del máster de acceso en esa área. Si no has tenido esa especialización, intenta en cualquier caso hacer las prácticas en tu área de preferencia, pero mantente abierto a otras posibilidades y no dejes pasar mejores oportunidades por el hecho de que, en este momento, creas que prefieres otro área de práctica.  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Probabilidad de continuar en el despacho tras las prácticas:</u>
                                  </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Como decíamos al inicio del artículo, las prácticas del máster de acceso son el mejor puente para conseguir un empleo en los principales despachos de abogados. La gran mayoría de abogados junior se incorporan a los despachos por esta vía. Por ello es importante que, antes de decidirte por hacer prácticas en uno u otro despacho, trates de tener toda la información posible sobre las posibilidades reales que tienes de incorporarte al despacho tras las prácticas.
                                  </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Los despachos tienen políticas muy diversas a la hora de fichar como abogados junior a los estudiantes de máster de acceso que hicieron las prácticas con ellos. Mientras que en algunos despachos ofrecen plazas de prácticas exclusivamente para aquellas áreas para las que existe una necesidad real de incorporar abogados junior, otros despachos ofrecen más plazas de prácticas, y posteriormente únicamente tienen capacidad para incorporar a algunos de los estudiantes. </Typography>
                
                
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Este es probablemente el factor más determinante que debe guiarte a la hora de decidirte por un despacho u otro. En la medida de lo posible, opta por despachos que en los años anteriores hayan incorporado como juniors a la mayoría de sus estudiantes de prácticas de máster de acceso.  Obviamente esto no te garantiza al 100% continuar en el despacho, pero sí que minimiza las posibilidades de que, pese a haber hecho un buen trabajo, no tengas finalmente la posibilidad de incorporarte por cuestiones ajenas a ti. </Typography>
                
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Tipo de trabajo:</u>
                                  </Typography>
                
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Trata de obtener toda la información posible sobre el tipo de trabajo que se realiza en el área de práctica en el que te han ofrecido hacer las prácticas. Como te decíamos al hablar de áreas de prácticas, probablemente a estas alturas todavía no tengas criterio para poder valorar qué tipo de trabajo es el que más te gustaría hacer. Pero sí que puedes tener una idea clara del tipo de trabajo que te gustaría evitar. En este sentido, es habitual que los abogados traten de evitar (o luchen por salir de ellos) departamentos en los que se hace un tipo de trabajo muy poco variado. No obstante, esto es cuestión de gustos.
                                  </Typography>
                
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Horarios:</u> </Typography>

                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Si quieres comenzar una carrera en la abogacía de alto nivel, una de las cosas que debes asumir es que tendrás que trabajar mucho, e intensamente. Si lo que buscas es un trabajo cómodo, con horarios bien definidos, y relajado, te aconsejamos buscar otras opciones. </Typography>
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  No obstante, dentro de la dureza intrínseca a la profesión, existen diferencias sustanciales tanto entre despachos como, dentro de un mismo despacho, entre departamentos. Mientras que en algunos despachos no es extraño –incluso puede ser la norma en determinadas épocas- terminar de trabajar de madrugada, en otros despachos estas situaciones solo se producen de forma excepcional. </Typography>
                 
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Aunque el hecho de que quieras iniciar tu carrera en uno de los principales despachos de abogados ya implica que estés dispuesto a trabajar duro, no dejes de tener en cuenta estas diferencias sustanciales entre despachos para tomar tu decisión. </Typography>
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Ambiente:</u> </Typography>
                 
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Vas a pasar muchas horas en el despacho, así que trata de asegurarte de que lo haces en un sitio con un buen ambiente laboral. En general el ambiente en los grandes despachos es bastante bueno. En casi todos ellos hay mucha gente joven, ambiente de camaradería y buen rollo. No obstante, puede haber diferencias importantes tanto entre despachos (algunos se vuelcan especialmente en potenciar el buen ambiente) y departamentos. </Typography>
                 
                 
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  <u>Remuneración:</u> </Typography>
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Aunque estemos hablando de prácticas, la remuneración será obviamente otro de los puntos que debas tener en cuenta en tu decisión. Los grandes despachos ofrecen remuneraciones muy atractivas para unas prácticas, que alcanzan niveles próximos a los salarios que perciben los abogados junior. </Typography>
                 
                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Al contrario de lo que sucede en otros países, en España los despachos guardan cierto secretismo respecto a la remuneración de sus prácticas. No obstante, no te será difícil descubrir la remuneración que ofrecen cada año los principales despachos, y comparar. </Typography>
                 



                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Te aconsejamos que no prestes especial atención a pequeñas diferencias de remuneración (probablemente diferencias de 100 o 200 euros mensuales a estas alturas de tu carrera no van a ser determinantes), y que te fijes más en la “banda salarial” en la que se sitúa en el despacho. Al fin y al cabo el nivel de remuneración de las prácticas de máster que ofrece cada despacho suele ir en consonancia con el nivel de remuneración que ofrece a sus abogados. La banda salarial en la que se sitúe cada despacho en las prácticas de máster de acceso te permitirá inferir también en qué división en cuanto a remuneración de sus abogados se encuentra. </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                  Estos son los principales puntos que tienes que valorar para decidir en qué despacho hacer tus prácticas de máster de acceso a la abogacía. Recuerda que puedes conocer la opinión de los estudiantes que ya han hecho sus prácticas de máster de acceso en los principales despachos de abogados viendo los resultados de la encuesta los Programas de Máster de Acceso a la Abogacía Mejor Valorados 2020. </Typography>













                  </Stack>





           
   

                       </Grid>
                 
                   </Grid>
  
                
     
  </Stack>







                
                 </Item>
         </Grid>
         </Box> 
          













         <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >

<Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
       
                        
       
 
  

    <Item sx={{marginRight: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "390px", maxHeight: "600px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

      <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

                        </Stack>

                        <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
  

    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  
 


                        </Stack>

    
  
  
          <Grid container sx={{height: '100%'}}>
       
           
            
          </Grid>






</Box>











<Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >


     <Stack sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center", paddingBottom: "50px"}} > 
     <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20px", paddingRight: "20px"}}>
               Adquiere los conocimientos necesarios para acceder a los mejores programas de prácticas


                   </Typography>

                   <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20px", paddingRight: "20px"}}>
               Nuestros másteres


                   </Typography>
       
     
   
  
 
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

  
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

      <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>


         </Stack>       
  
    
  
  

      
      


           



 



 


</Box>




<Footer/>






        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default EnQueDespacho