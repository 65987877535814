import React from 'react'
import { useParams } from "react-router-dom";
import { getPaginas } from '../firebase/firebase';
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs  } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import AuthProvider from '../components/authProvider'
import Pagina from '../components/Pagina';
import { v4 as uuid } from 'uuid';
import { insertNewPagina, updatePagina } from '../firebase/firebase';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';
import { CssBaseline, Divider, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../customCKEditor.css";
import '@ckeditor/ckeditor5-build-classic/build/translations/es';


function CreatorPaginaIntermedia({id, setPageId}) {

    let params = useParams();
    const db= getFirestore()
    
    const [currentUser, setCurrentUser] = useState({})
    const [paginas, setPaginas] = useState([])
    
    const [numSteps, setNumSteps] = useState(1)
    const [step1, setStep1] = useState("")
    const [step2, setStep2] = useState("")
    const [step3, setStep3] = useState("")
    const steps = [];
    const [texto, setTexto] = useState("")
    const [texto2, setTexto2] = useState("")
    const [texto3, setTexto3] = useState("")


    const [pags, setPags] = useState([]);
    const [title, setTitle] = useState("")

    useEffect(() => {
    getPaginas();
    console.log(params.pageId);
  }, [])

    async function handleUserLoggedIn(user){
        setCurrentUser(user);  
        const resPaginas = await getPaginas(user.uid);
        setPaginas([...resPaginas]);
        console.log("logged in")
      }
      
      function handleUserNotLoggedIn(user){
          console.log("Not logged in")
      }
      
      function handleUserNotRegistered(user){
          console.log("Not registered")
      }




  const fetchPost = async () => {
       
    await getDocs(collection(db, "paginas"))
        .then((querySnapshot)=>{              
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setPags(newData);                
            console.log(pags, newData);
        })
   
}

  useEffect(()=>{
    fetchPost();
}, [])
  
  const selectedTrabajo = pags.filter((doc => doc.id===params.pageId));
  
    const docRef = doc(db, "paginas", params.pageId)
  
  
      
    useEffect(() => {
        getDoc(docRef)
        .then((doc) => {
        
        const objeto = doc.data();
        setTitle(objeto.title);
        setStep1(objeto.step1);
        setStep2(objeto.step2);
        setStep3(objeto.step3);
        setTexto(objeto.texto);
        setTexto2(objeto.texto2);
        setTexto3(objeto.texto3);
        setNumSteps(objeto.numSteps);
     


        })
      }, [pags])

      console.log(selectedTrabajo);


      
  
  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };



    function handleButtonRemoveStep2 () {
      const newNumSteps = numSteps - 1;
      setNumSteps(newNumSteps);
      setStep1(""); 
      setStep2(""); 
      setStep3(""); 

     }



    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [index, setIndex] = useState(1)

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === numSteps - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === numSteps;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    console.log(index)
    setIndex(index+1)
    console.log(index)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIndex(index-1)
    console.log(index)
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };



    async function handleUserLoggedIn(user){
      setCurrentUser(user);  
      const resPaginas = await getPaginas(user.uid);
      setPaginas([...resPaginas]);
      console.log("logged in")
    }
    
    function handleUserNotLoggedIn(user){
        console.log("Not logged in")
    }
    
    function handleUserNotRegistered(user){
        console.log("Not registered")
    }

    function handleSubmit (e) {
       e.preventDefault();
       steps.push(step1)
       steps.push(step2)
       addPagina()
       console.log(steps)

       }

    function handleChange(e) {
        const value = e.target.value;
        if(e.target.name === "title") {
            setTitle(value);
            console.log(title)
        }

        if(e.target.name === "step1") {
          setStep1(value);
          console.log(step1)
          steps.push(step1)

          if (step2 === "") {
            setNumSteps(1);
            steps.push(step1)
          }
        
      }
    

    if(e.target.name === "step2") {
      setStep2(value);
      console.log(step2)
      steps.push(step2)
      console.log(steps)

      if (step2 !== "" && step3 === "") {
        setNumSteps(2);
        steps.push(step2)
      console.log(steps)
      }
    
      if (step2 === "") {
        setNumSteps(1)
        steps.push(step2)
      console.log(steps)
      }
  }

  if(e.target.name === "step3") {
    setStep3(value);
    console.log(step3)
    steps.push(step3)
    console.log(steps)

    if (step3 !== ""  && step2 !== "") {
      setNumSteps(3);
    }
  
    if (step3 === "") {
      setNumSteps(2)
    }
}

}


console.log(step2)
console.log(numSteps)
console.log(step3)

    function addPagina() {
      const newPagina = {
        id: uuid(),
        title: title,
        step1: step1,
        step2: step2,
        step3: step3,
        numSteps: numSteps,
        uid: currentUser.uid,
        texto: texto,
        texto2: texto2,
        texto3: texto3,
      };
      const res = insertNewPagina(newPagina)
      newPagina.docId = res.id;
      setTitle("");
      setStep1("");
      setPaginas([...paginas, newPagina])
      
      console.log(paginas)
    }

function handleDelete(){

}

async function handleUpdate(docId, 
  title,
  step1, 
  step2,
  step3,
  numSteps,
  texto,
  texto2,
  texto3,
){
  
  const pagina = paginas.find((item) => item.docId === docId);
  pagina.title = title;
  pagina.step1 = step1;
  pagina.step2 = step2;
  pagina.step3 = step3;
  pagina.numSteps = numSteps;
  pagina.texto = texto;
  pagina.texto2 = texto2;
  pagina.texto3 = texto3;

  await updatePagina(docId, pagina)
  console.log(pagina, docId, title)
}
    
console.log(paginas)
console.log(steps)

const matches = []


function renderLinks() {
  if (paginas.length > 0) {
    return paginas.map((pagina) => (
      <Pagina
        key={pagina.id}
        docId={pagina.docId}
        title={pagina.title}
        step1={pagina.step1}
        step2={pagina.step2}
        step3={pagina.step3}
        numSteps={pagina.numSteps}
        texto={pagina.texto}
        texto2={pagina.texto2}
        texto3={pagina.texto3}
      
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  }
}


console.log(steps)
console.log(index)
console.log(step1)
console.log(step2)
console.log(step3)

  return (
    <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
      <CssBaseline>
            <div>

              <Grid paddingTop="30px" sx={{justifyContent: "center"}} container component="main">
              
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} sx={{justifyContent: "center"}}>
                
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{minHeight: "89vh", bgcolor: "#d5e2f7", color: "white", marginRight: "20px", marginLeft: "20px", borderRadius: "5px", padding: "20px", maxWidth: "850px" }}>
            <Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                ¿Cuál es el título de esta unidad?
              </Typography>
     

<TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="title"
                
                name="title"
                autoComplete="step1"
              
                onChange={handleChange}
                value={title}
              />

              <Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Esta unidad puede tener entre 1 y 3 pasos. Crea los pasos a continuación.
              </Typography>


              <Typography sx={{color: "#4a5b82", paddingTop: "20px"}}>
                Título del paso 1:
              </Typography>

                <TextField   
                sx={{input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
        
                fullWidth
                id="step1"
                
                name="step1"
                autoComplete="step1"
              
                onChange={handleChange}
                value={step1}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Contenido del paso 1:
              </Typography>
<Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={texto} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto(data)
}}
/> 
</Stack>

<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Título del paso 2:
              </Typography>
                <TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="step2"
                
                name="step2"
                autoComplete="step2"
               
                onChange={handleChange}
                value={step2}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Contenido del paso 2:
              </Typography>
              <Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={texto2} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto2(data)
}}
/> 
</Stack>
<Typography sx={{color: "#4a5b82", paddingTop: "40px"}}>
                Título del paso 3:
              </Typography>
                <TextField   
                sx={{marginTop: "20px", input: {color: "grey", backgroundColor: "rgba(231, 235, 242, 0.5)", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"

                fullWidth
                id="step3"
                
                name="step3"
                autoComplete="step3"
               
                onChange={handleChange}
                value={step3}
              />

<Typography sx={{color: "#4a5b82", paddingTop: "20px", paddingBottom: "20px"}}>
                Contenido del paso 3:
              </Typography>
              <Stack sx={{color: "black"}} >
<CKEditor config={ {
        // Use the German language for this editor.
        language: 'es',
  fontColor: "black",
  fontBackgroundColor: "black"
  
        // ...
    } }   editor={ClassicEditor} data={texto3} onChange={(event, editor) => {
  const data = editor.getData()
  setTexto3(data)
}}
/> 
</Stack>

                      <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "rgba(43, 117, 177, 0.9)", color: "white", marginTop: "70px"}}

              >
                Enviar
              </Button>
             
         </Box>

            </Grid>
            
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{justifyContent: "center"}}>
              <Item sx={{minHeight: "89vh", bgcolor: "#d5e2f7"}}>
            <Box sx={{padding: "25px" }}>

            <Stepper nonLinear activeStep={activeStep}>
        
          <Step key={step1} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#4a5b82', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step1}
            </StepButton>
          </Step>

          {step2 !== "" && <Step key={step2} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#4a5b82', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step2}
            </StepButton>
          </Step>}

          {(step2 !== "" && step3 !== "") && <Step key={step3} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#4a5b82', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step3}
            </StepButton>
          </Step>}
      </Stepper>




      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#4a5b82', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() &&
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        }

        {activeStep === 0 && 
        <div>
        
            <Stack sx= {{minHeight: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>

                {parse(texto)}


                    </Typography>
                </Stack>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {step2 !== "" && <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>}
              <Box sx={{ flex: '1 1 auto' }} />
              {step2 !== "" && <Button onClick={handleNext} disabled={activeStep === numSteps - 1} sx={{ mr: 1, color: "#4a5b82" }}>
              
                Siguiente
              </Button>}
              {step2 !== "" && <Button onClick={handleComplete} sx={{color: "#4a5b82" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {step2 === "" && <Button onClick={handleComplete} sx={{color: "#4a5b82" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}



            </Box>
      </div>
    
    }


{activeStep === 1 && 
               <div>
        
               <Stack sx= {{minHeight: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>
   
                   {parse("<p>Por ejemplo, la jurisdicción civil conoce de asuntos como:</p><ul><li>Una disputa entre un consumidor y una empresa en la que se discute sobre la posible nulidad de una cláusula contractual.</li><li>Un litigio entre dos vecinos sobre la propiedad de una franja de terreno.</li><li>Una reclamación de cantidad de un banco a un deudor que mantiene impagado un préstamo con garantía hipotecaria.</li><li>Un procedimiento de divorcio.</li><li>Una controversia relativa a una herencia.</li><li>Un litigio en el que uno de los socios de una sociedad mercantil defiende que un acuerdo alcanzado por el consejo de administración es nulo.</li></ul>")}
   
   
                       </Typography>
                   </Stack>
           
           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
           {step2 !== "" &&  <Button
                   color="inherit"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   sx={{ mr: 1 }}
                 >
                   Anterior
                 </Button>}
                 <Box sx={{ flex: '1 1 auto' }} />
                 {step2 !== "" && <Button onClick={handleNext} disabled={activeStep === numSteps - 1} sx={{ mr: 1, color: "#4a5b82" }}>
              
                Siguiente
              </Button>}
              {step2 !== "" && <Button onClick={handleComplete} sx={{color: "#4a5b82" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {step2 === "" && <Button onClick={handleComplete} sx={{color: "#4a5b82" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}
               </Box>
         </div>
    
    }

{activeStep === 2 && 
               <div>
        
               <Stack sx= {{minHeight: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>
   
                   {parse("<p>Por el contrario, la jurisdicción civil no conoce de asuntos como:</p><ul><li>Un litigio en el que se discuta si un despido es o no procedente.</li><li>Una controversia relativa a la validez de un expediente de regulación de empleo.</li><li>Un procedimiento en el que se enjuicie si una conducta es constitutiva de estafa.</li><li>Un procedimiento en el que se juzgue a una empresa por un posible delito fiscal.</li><li>Un procedimiento en el que una persona física o jurídica discuta una liquidación de impuestos practicada por la Agencia Tributaria.</li><li>Un procedimiento en el que una persona física impugne el resultado de unas oposiciones.</li></ul>")}
   
   
                       </Typography>
                   </Stack>
           
           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
           {step2 !== "" &&  <Button
                   color="inherit"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   sx={{ mr: 1 }}
                 >
                   Anterior
                 </Button>}
                 <Box sx={{ flex: '1 1 auto' }} />
                 {step2 !== "" && <Button onClick={handleNext} disabled={activeStep === numSteps - 1} sx={{ mr: 1, color: "#4a5b82" }}>
              
                Siguiente
              </Button>}
              {step2 !== "" && <Button onClick={handleComplete} sx={{color: "#4a5b82" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {step2 === "" && <Button onClick={handleComplete} sx={{color: "#4a5b82" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}
                   
               </Box>
         </div>
    
    }





      </div>
    </Box>
          </Item>
          
          
      
             </Grid>
           
         </Grid>
    
    </div>
    </CssBaseline>
    </AuthProvider> 
  )
}

export default CreatorPaginaIntermedia