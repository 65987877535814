import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import userEvent from '@testing-library/user-event';
import AuthProvider from '../../components/authProvider';
import HeaderLoggedIn from '../../components/HeaderLoggedIn';
import Link from '@mui/material/Link';
import Footer from '../../components/Footer';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function AQueTipo() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({});

    function handleUserLoggedIn(user){
      setCurrentUser(user)
      console.log("logged in")
  }
  
  function handleUserNotLoggedIn(user){
      console.log("Not logged in")
  }
  
  function handleUserNotRegistered(user){

      console.log("Not registered")
  }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
            <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}
         
      <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >
         
          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                    <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>
                  <Stack sx= {{minHeight: "525px", textAlign: "justify"}}>

                    <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                      <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", textAlign: "center"}}>
            ¿A qué tipo de despacho puedo acceder?
  
  
                      </Typography>

                      <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "30px", paddingLeft: "0px", paddingRight: "80px", textAlign: "center"}}>
                      No renuncies a nada, e intenta hacer tus prácticas del máster de acceso a la abogacía en el tipo de despacho que más te guste. No obstante, también debes ser realista: haz un análisis objetivo de tu perfil y estate preparado para buscar alternativas.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "60px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                  El mundo de la abogacía de alto nivel es altamente competitivo, y eso aplica también a los procesos de selección para los programas de prácticas de máster de acceso. Los principales despachos de abogados son muy exigentes a la hora de seleccionar a sus abogados en prácticas, y no es sencillo conseguir siquiera participar en sus procesos de selección.
  
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                

                      A la hora de afrontar el momento de buscar un despacho en el que hacer tus prácticas de máster de acceso debes ser honesto contigo mismo y valorar qué tipo de despacho se adapta mejor tu perfil. Tu abanico de opciones dependerá fundamentalmente de cómo haya evolucionado tu formación hasta ese momento. Si has hecho un doble grado, tienes calificaciones excelentes, hablas inglés, y te has preocupado de hacer prácticas durante tus veranos, tendrás muchas más opciones abiertas que si no cumples ninguno de estos requisitos. Sé ambicioso e intenta acceder al programa de prácticas del tipo de despacho que más te atraiga, pero también sé realista y valora opciones alternativas por si no lo logras.


  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
            
                      Hablando en términos generales, los despachos con los requisitos de acceso más duros son los grandes despachos internacionales y los mayores despachos nacionales. Tener buenas calificaciones en la carrera y nivel alto de inglés es un requisito prácticamente imprescindible para entrar siquiera en sus procesos de selección. Si no cumples estos requisitos pero quieres hacer prácticas en estos despachos no dejes de intentarlo, pero sé consciente de que muy probablemente tendrás que buscar opciones alternativas. Tranquilo, ¡las hay!
            
                  
                                      </Typography>


                                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                      En los despachos nacionales e internacionales medianos la dificultad de acceso al programa de prácticas es mucho más irregular. Mientras que una parte de ellos tienen requisitos de acceso tan rigurosos como los despachos más grandes, otros rebajan estos requisitos, y no son tan rigurosos con el expediente. En el caso de algunos de los despachos nacionales medianos también podrán encontrar oportunidades los alumnos sin un nivel de inglés muy alto.
          
              
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "40px", paddingLeft: "0px", textAlign: "center"}}>
                                  Por último, y salvos excepciones –boutiques especializadas de prestigio-, los requisitos de acceso a los programas de prácticas de máster de acceso suelen ser mucho menos exigentes en pequeños despachos.
        
        
                            </Typography>


                  </Stack>
                        </Grid>
       
                        <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

</Grid>
                    </Grid>
   
                 
      
   </Stack>

   <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>





<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Adquiere los conocimientos necesarios para acceder a los mejores programas de prácticas


                   </Typography>

                   <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Nuestros másteres


                   </Typography>

       </Grid>
                 
                  </Item>
          </Grid>
          </Box>
 





 
          <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >
         
         <Grid xs={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
           <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
 <Grid item xs={12} sm={12} md={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
   <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>

<Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Law School | Derecho de los negocios


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Formación especializada diseñada para el éxito en la abogacía de élite


</Typography>

</Stack>
   </Grid>
           
       
                 <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                 <Stack sx={{height: "50px", bgcolor: "#4a5b82", opacity: "0.9"}}></Stack>
                 <Stack sx= {{textAlign: "justify"}}>

                   <Grid container>
          
                     <Grid item xs={12} sm={12} md={12}>



                     <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9", justifyContent: "center", alignContent: "center", alignItems: "center", paddingLeft: "30px", paddingRight: "30px"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", textAlign: "center"}}>
            ¿A qué tipo de despacho puedo acceder?
  
  
                      </Typography>

                      <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "30px", paddingLeft: "0px", paddingRight: "80px", textAlign: "center"}}>
                      No renuncies a nada, e intenta hacer tus prácticas del máster de acceso a la abogacía en el tipo de despacho que más te guste. No obstante, también debes ser realista: haz un análisis objetivo de tu perfil y estate preparado para buscar alternativas.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "60px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                  El mundo de la abogacía de alto nivel es altamente competitivo, y eso aplica también a los procesos de selección para los programas de prácticas de máster de acceso. Los principales despachos de abogados son muy exigentes a la hora de seleccionar a sus abogados en prácticas, y no es sencillo conseguir siquiera participar en sus procesos de selección.
  
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                

                      A la hora de afrontar el momento de buscar un despacho en el que hacer tus prácticas de máster de acceso debes ser honesto contigo mismo y valorar qué tipo de despacho se adapta mejor tu perfil. Tu abanico de opciones dependerá fundamentalmente de cómo haya evolucionado tu formación hasta ese momento. Si has hecho un doble grado, tienes calificaciones excelentes, hablas inglés, y te has preocupado de hacer prácticas durante tus veranos, tendrás muchas más opciones abiertas que si no cumples ninguno de estos requisitos. Sé ambicioso e intenta acceder al programa de prácticas del tipo de despacho que más te atraiga, pero también sé realista y valora opciones alternativas por si no lo logras.


  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
            
                      Hablando en términos generales, los despachos con los requisitos de acceso más duros son los grandes despachos internacionales y los mayores despachos nacionales. Tener buenas calificaciones en la carrera y nivel alto de inglés es un requisito prácticamente imprescindible para entrar siquiera en sus procesos de selección. Si no cumples estos requisitos pero quieres hacer prácticas en estos despachos no dejes de intentarlo, pero sé consciente de que muy probablemente tendrás que buscar opciones alternativas. Tranquilo, ¡las hay!
            
                  
                                      </Typography>


                                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                      En los despachos nacionales e internacionales medianos la dificultad de acceso al programa de prácticas es mucho más irregular. Mientras que una parte de ellos tienen requisitos de acceso tan rigurosos como los despachos más grandes, otros rebajan estos requisitos, y no son tan rigurosos con el expediente. En el caso de algunos de los despachos nacionales medianos también podrán encontrar oportunidades los alumnos sin un nivel de inglés muy alto.
          
              
                                  </Typography>


                                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "40px", paddingLeft: "0px", textAlign: "center"}}>
                                  Por último, y salvos excepciones –boutiques especializadas de prestigio-, los requisitos de acceso a los programas de prácticas de máster de acceso suelen ser mucho menos exigentes en pequeños despachos.
        
        
                            </Typography>


                  </Stack>


           
   

                       </Grid>
                 
                   </Grid>
  
                
     
  </Stack>







                
                 </Item>
         </Grid>
         </Box> 
          













         <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >

<Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
       
                        
       
 
  

    <Item sx={{marginRight: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "390px", maxHeight: "600px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

      <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

                        </Stack>

                        <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
  

    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  
 


                        </Stack>

    
  
  
          <Grid container sx={{height: '100%'}}>
       
           
            
          </Grid>






</Box>











<Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >


     <Stack sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center", paddingBottom: "50px"}} > 
<Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
              Nuestros másteres


                  </Typography>
       
     
   
  
 
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

  
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

      <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>


         </Stack>       
  
    
  
  

      
      


           



 



 


</Box>




<Footer/>






        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default AQueTipo