import React from 'react'
import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import { getPaginas, insertNewAlumnoMasterDerechoPenal } from '../../firebase/firebase';
import AuthProvider from '../../components/authProvider';
import {v4 as uuid} from "uuid";
import { insertNewAlumnoMasterDerechoProcesal } from '../../firebase/firebase';
import HeaderLoggedIn from '../../components/HeaderLoggedIn';
import { useNavigate } from 'react-router';

import LoginDef from '../../components/LoginDef';
import InicioSesionDef from '../../components/InicioSesionDef';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { signOut } from 'firebase/auth';
import TextField from '@mui/material/TextField';
import Footer from '../../components/Footer';
import { getAlumnos } from '../../firebase/firebase';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function PresentacionMasterDerechoPenal() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({})
    const [paginas, setPaginas] = useState([])
    const [inicioSesión, setInicioSesión] = useState("iniciosesion")
    
    const [alumnos, setAlumnos] = useState([])
    const [alumno, setAlumno] = useState(false)
    const [error, setError] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
      getPaginas();
      getAlumnos();
   
      console.log(alumnos)
      console.log(currentUser) 
    }, [])

    async function handleUserLoggedIn(user){
      setCurrentUser(user);  
      const resPaginas = await getPaginas(user.uid);
      setPaginas([...resPaginas]);
      console.log(paginas)

              
      const resAlumno = await getAlumnos(user.uid)
      setAlumnos([...resAlumno])
       console.log(alumnos)
    }
    
    function handleUserNotLoggedIn(user){
        console.log("Not logged in")
    }
    
    function handleUserNotRegistered(user){
        console.log("Not registered")
    }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
    
    function addAlumno() {
      const newAlumno = {
        id: uuid(),
        master: "penal",
        status: "trial",
        diaposCompletadas: [0],
        uid: currentUser.uid,
        respuestas: [],
      }
      const res = insertNewAlumnoMasterDerechoPenal(newAlumno);
      newAlumno.docId = res.id;
      setAlumno(true)
    }

    function handleOnSubmit(){
      addAlumno();
      console.log("click")
      navigate(`../dashboardmasterderechoprocesal/`);
    }

    const handleChangeInicioSesion = (event, newAlignment) => {
      setInicioSesión(newAlignment);
    };

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const auth = getAuth();


    const handleSignUp = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            setCurrentUser(user)
  
  
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setError(errorCode)
            if (errorCode === "auth/weak-password"){
              setError("La contraseña debe tener al menos seis caracteres")
            }
            if (errorCode === "auth/email-already-in-use"){
              setError("Ya estás registrado, inicia sesión")
            }
            // ..
        });
  
    }

    function handleLogOut(){
        signOut(auth).then(()=> {
            console.log("Yeeees")
        }).catch((error) => {
    
        } )
   
    }

    console.log(alumnos)

    return (
      <div>
             <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}

      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >


          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82"}}>
                <Stack sx={{backgroundImage: `url(${"/images/ciudad.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{bgcolor: "#4a5b82", opacity: "0.8"}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{minHeight: "700px", textAlign: "center", padding: "20px", bgcolor: "#4a5b82", opacity: "0.8"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "230px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Máster en Derecho <br></br> Procesal Civil 


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Adquiere los conocimientos teóricos y prácticos necesarios <br></br> para convertirte en un experto abogado procesalista


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2} sx= {{bgcolor: "#4a5b82", opacity: "0.8"}}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Duración:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        210 horas
                        </Typography>
                        </Stack>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                      <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Modalidad:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        100% online
                        </Typography>
                        </Stack>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                      <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        Formato:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        Part-time
                        </Typography>
                        </Stack>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                      <Stack>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                          Idioma:
                        </Typography>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
                        Castellano
                        </Typography>
                        </Stack>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>
                  <Stack sx= {{minHeight: "525px", textAlign: "justify"}}>

                    <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                      <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", paddingRight: "80px"}}>
                  Máster en Derecho <br></br> Procesal Civil.
  
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  Procesal es una práctica que no falta en ninguno de los más prestigiosos despachos de abogados. Cuando una operación societaria se tuerce, cuando las obligaciones de una financiación se incumplen o, en definitiva, cuando se avecina una disputa, entran en juego los abogados procesalistas.


                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  
Todo estudiante de Derecho o abogado tiene unos conocimientos básicos de procesal, obtenidos en la carrera y/o en el máster de acceso. Sin embargo, la diferencia entre estos conocimientos y los que debe tener un abogado procesalista es abismal.

                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  
El objetivo de este Máster es convertir al alumno, parta de la base que parta, en un experto abogado procesalista especializado en Derecho de los negocios. A través de un programa exigente, pero altamente flexible, el alumno adquirirá los conocimientos y habilidades propios de un experto resolviendo los mismos retos a los que se enfrentan los abogados procesalistas de los más prestigiosos despachos de abogados. 



  
  
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "40px", paddingLeft: "0px", paddingRight: "120px", textAlign: "justify"}}>
                  Se trata de un programa eminentemente práctico, en el que no solo aprenderás Derecho procesal: aprenderás a ser abogado procesalista.


  
  
                      </Typography>
                      
                  </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                        
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
   
                 
      
   </Stack>

   <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>

   <Grid xs={12} sx={{paddingBottom: "30px", bgcolor: "#ebeef5", opacity: "0.9"}}>
       
          

       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
               ¿Para quién es este Máster?


                   </Typography>




      





                   <Grid container spacing={0} sx={{height: '100%', paddingBottom: "20px", paddingTop: "50px"}}>
  
  <Grid xs={2}>

</Grid>

<Grid xs={8}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack direction="row"   justifyContent="center"
  alignItems="center" sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

     <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", paddingRight: "30px"}}>
     Estudiantes de Grado o Máster de Acceso...


         </Typography>
         <Divider orientation="vertical" flexItem sx={{bgcolor: "#d5dbe8", margingLeft: "20px"}}>
    
  </Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "30px",}}>
     ...que valoren desarrollar su carrera profesional en el área de Derecho procesal de un despacho de prestigio y quieran contar con una ventaja competitiva a la hora de afrontar los procesos de selección y los primeros años de práctica.


         </Typography>
     
     </Stack>


 </Item>
</Grid>






<Grid xs={2}>

</Grid>
</Grid>



<Grid container spacing={0} sx={{height: '100%', paddingBottom: "30px"}}>
  
  <Grid xs={2}>

</Grid>

<Grid xs={8}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack direction="row"   justifyContent="center"
  alignItems="center" sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

     <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", paddingRight: "30px"}}>
     Abogados junior...


         </Typography>
         <Divider orientation="vertical" flexItem sx={{bgcolor: "#d5dbe8", margingLeft: "20px"}}>
    
  </Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "30px",}}>
     ...que deseen acelerar su formación y adquirir conocimientos equivalentes a los de un abogado de cinco años de experiencia en el área de Derecho procesal de un gran despacho de abogados. 


         </Typography>
     
     </Stack>


 </Item>
</Grid>






<Grid xs={2}>

</Grid>
</Grid>
      



<Grid container spacing={0} sx={{height: '100%', paddingBottom: "100px"}}>
  
  <Grid xs={2}>

</Grid>

<Grid xs={8}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack direction="row"   justifyContent="center"
  alignItems="center" sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

     <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", paddingRight: "30px"}}>
     Abogados especializados en otras áreas de práctica...


         </Typography>
         <Divider orientation="vertical" flexItem sx={{bgcolor: "#d5dbe8", margingLeft: "20px"}}>
    
  </Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "30px",}}>
     ...que deseen adquirir los conocimientos teóricos y prácticos necesarios para representar a sus clientes en cualesquiera litigios en la jurisdicción civil.


         </Typography>
     
     </Stack>


 </Item>
</Grid>






<Grid xs={2}>

</Grid>
</Grid>






     
</Grid>




       </Stack>
                  </Item>
                  
          </Grid>
     
          <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
        Un método de aprendizaje diseñado por abogados <br></br> de los principales despachos del mundo. 


            </Typography>
            <Grid container spacing={0} sx={{height: '100%', paddingBottom: "100px"}}>
            <Grid xs={2}>
           
            </Grid>
            <Grid xs={4}>
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Todos los másteres de Univerlaw están diseñados conjuntamente con abogados senior, counsels o socios de los principales despachos de abogados del mundo, así como con abogados in-house de grandes empresas. Conjuntamente con estos reputados profesionales
           recopilamos, en un solo curso exigente y altamente especializado, todos los conocimientos teóricos y prácticos necesarios para triunfar en la abogacía de los negocios.



               </Typography>
               <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           
           En los másteres de Univerlaw adquirirás los conocimientos que los abogados de los principales despachos buscan en los candidatos que seleccionan para unirse a sus despachos. Adquirirás los conocimientos que los directores de asesoría jurídica requieren 
           a los abogados externos a los que encomiendan sus asuntos más sensibles. En definitiva, te proporcionaremos todas las herramientas necesarias para convertirte en un auténtico experto en el área de práctica.



               </Typography>
            </Grid>
            <Grid xs={4}>
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Aprenderás de forma 100% online, a través de una plataforma diseñada expresamente para la formación en el derecho de los negocios. Puedes formarte de forma intensiva, si el resto de tus obligaciones te lo permite, o de manera más pausada, dedicando solo algunas horas semanales.
               </Typography>
               <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           La formación es eminentemente práctica, basada en el principio "learning by doing". Desde el primer día comenzarás a enfrentarte a casos reales que cada vez irás resolviendo con mayor pericia.
           Te enfrentarás a los mismos retos a los que se enfrenta un abogado junior / asociado de un gran despacho de abogados, pero centrándote en aquellas cuestiones que verdaderamente te permitirán evolucionar. 
               </Typography>

               <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           El objetivo es tan simple como ambicioso: convertirte en un auténtico experto en este área de práctica.
               </Typography>
               
            </Grid>
            <Grid xs={2}>
            </Grid>
            </Grid>

           

</Grid>



<Grid xs={12} sx={{backgroundImage: `url(${"/images/ciudad.jpg"})`, backgroundPosition: "right bottom", backgroundSize: "cover", paddingBottom: "0px", bgcolor: "#4a5b82", opacity: "0.95"}}>
       
          

       <Typography variant="h3" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "55px", textAlign: "center", bgcolor: "#4a5b82", opacity: "0.95"}}>
               Cuando termines el máster sabrás...


                   </Typography>

       <Grid container spacing={0} sx={{height: '100%', paddingBottom: "80px", paddingTop: "40px" ,bgcolor: "#4a5b82", opacity: "0.95"}}>
  
       <Grid xs={1}>
    
  </Grid>
  
  <Grid xs={2}>
  <Item elevation="0" sx={{bgcolor: "#4a5b82", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack variant="column" spacing={0}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
          <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
          Redactar una demanda


              </Typography>
              
              <Box sx={{width: "100px", margin: 'auto'}} >

    </Box>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Diseñar una estrategia procesal


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>

    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginBottom: "20px", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Ejecutar una sentencia


        </Typography>
        
  
    </Stack>
          </Stack>

      </Item>
  </Grid>

  <Grid xs={2}>
  <Item elevation="0" sx={{bgcolor: "#4a5b82", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack variant="column" spacing={0}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
          <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
          Contestar a una demanda


              </Typography>
              
              <Box sx={{width: "100px", margin: 'auto'}} >

    </Box>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Redactar e impugnar recursos en primera instancia


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginBottom: "20px", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Oponerte a una ejecución de sentencia


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>

    
          </Stack>

      </Item>
  </Grid>
  

  <Grid xs={2}>
  <Item elevation="0" sx={{bgcolor: "#4a5b82", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack variant="column" spacing={0}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
          <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
          Representar a tus clientes en cualesquiera incidentes procesales


              </Typography>
              
              <Box sx={{width: "100px", margin: 'auto'}} >

    </Box>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Interrogar a testigos y peritos


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Redactar un recurso de casación


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>
          </Stack>

      </Item>
  </Grid>

  <Grid xs={2}>
  <Item elevation="0" sx={{bgcolor: "#4a5b82", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack variant="column" spacing={0}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
          <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
          Participar en una audiencia previa


              </Typography>
              
              <Box sx={{width: "100px", margin: 'auto'}} >

    </Box>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Redactar un recurso de apelación


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Oponerte a un recurso de casación


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>

      </Item>
  </Grid>

  <Grid xs={2}>
  <Item elevation="0" sx={{bgcolor: "#4a5b82", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack variant="column" spacing={0}>
    <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
          <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
          Participar en un juicio


              </Typography>
              
              <Box sx={{width: "100px", margin: 'auto'}} >

    </Box>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Oponerte a un recurso de apelación


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>
          </Stack>
          <Stack sx= {{ bgcolor: "#d5dbe8", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center", marginTop: "20px"}}>
    
    <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
    Tasar e impugnar costas


        </Typography>
        
        <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
    </Stack>

      </Item>
  </Grid>

  <Grid xs={1}>
    
    </Grid>
</Grid>
</Grid>
 



<Stack sx={{bgcolor: "white", paddingBottom: "0px"}}>
       
          

       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "55px", textAlign: "center"}}>
       El día después del Máster


                   </Typography>


                   <Grid sx={{paddingBottom: "30px"}}>



            <Grid container sx={{height: '100%', paddingBottom: "100px"}}>
            <Grid xs={3}>
           
            </Grid>
            <Grid xs={6}>
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           La mayoría de los estudiantes de Univerlaw deciden realizar nuestros másteres con una meta profesional clara. Esta meta profesional puede ser consistir en conseguir un primer trabajo en alguno de los despachos de abogados de mayor prestigio,
           conseguir un cambio a un despacho con mejores condiciones, cambiar de área de práctica, o simplemente adquirir conocimientos a nivel de experto que ayuden en (y aceleren) el avance en la carrera profesional dentro del actual despacho.

           En Univerlaw no solo te permitiremos adquirir los conocimientos necesarios para esos propósitos, sino que además ponemos a tu disposición todas las herramientas adicionales necesarias para conseguir tu objetivo.  



               </Typography>





             
               
            </Grid>
           
            <Grid xs={3}>
            </Grid>
            </Grid>

            <Grid container spacing={0} sx={{height: '100%', paddingBottom: "100px"}}>
  
  <Grid xs={3}>

</Grid>

<Grid xs={3}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginRight: "20px", minHeight: "440px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Portal privado <br></br> de empleo


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos nuestros másteres incluyen un año de acceso gratuito a Jobs&Law, nuestro portal privado de empleo, en el que podrás encontrar todas las vacantes profesionales de calidad
     abiertas en el mercado.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>

 <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginTop: "20px", marginRight: "20px", minHeight: "475px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Recursos para superar <br></br> los procesos de selección


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos nuestros másteres incluyen una sección voluntaria, diseñada por reclutadores de los principales despachos de abogados, en la te daremos todas las claves para superar los procesos de selección más exigentes.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>


 
</Grid>

<Grid xs={3}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", minHeight: "440px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Perfil profesional <br></br> preferente, para siempre


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos los alumnos de Univerlaw tienen la posibilidad de contar con un perfil profesional preferente en Jobs&Law, que les permitirá ser encontrados de forma sencilla y confidencial por reclutadores.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>

 <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginTop: "20px", minHeight: "475px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Comunidad <br></br> Univerlaw


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Tras completar el máster formarás parte de la Comunidad Univerlaw, conformada por profesores -abogados senior, counsel y socios de despachos de reconocido prestigio-, reclutadores y alumni.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>


     </Stack>


 </Item>

</Grid>




<Grid xs={3}>

</Grid>
</Grid>        

</Grid> 



 
</Stack>


<Grid xs={12} sx={{backgroundImage: `url(${"/images/ciudad.jpg"})`, backgroundPosition: "right bottom", backgroundSize: "cover", paddingBottom: "0px", bgcolor: "#4a5b82", opacity: "0.95"}}>
       
          

       <Typography variant="h3" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "55px", textAlign: "center", bgcolor: "#4a5b82", opacity: "0.95"}}>
               Precio


                   </Typography>

                   <Grid container sx={{height: '100%', paddingBottom: "100px", bgcolor: "#4a5b82", opacity: "0.95"}}>
            <Grid xs={3}>
           
            </Grid>
            <Grid xs={6}>
            <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Creemos que la formación de élite no debe estar restringida a aquellas personas dispuestas a pagar decenas de miles de euros y a sacrificar un año de su carrera profesional. En Univerlaw puedes aprender de los abogados líderes de cada área
           a un precio sin competencia, y beneficiándote de facilidades de pago sea cual sea tu situación.



               </Typography>



               <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "22px", paddingTop: "50px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           El precio total del Máster en Derecho Procesal es de <u>1.050 euros</u>. No hay ningún coste adicional.



               </Typography>

               <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "50px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Ponemos a tu disposición opciones de financiación, sin coste, independientemente de tu situación económica.



               </Typography>





             
               
            </Grid>
           
            <Grid xs={3}>
            </Grid>
            </Grid>
</Grid>



<Stack xs={12} sx={{bgcolor: "white"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
        Prueba gratis el Máster <br></br> antes de inscribirte. 


            </Typography>
            <Grid container spacing={0} sx={{height: '100%', paddingBottom: "90px"}}>
            <Grid xs={3}>
           
            </Grid>
            <Grid xs={6}>
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Tenemos el convencimiento de que el Máster en Derecho Procesal de Univerlaw es el más completo y efectivo impartido en España. No obstante, la exigencia del programa no lo hace apto para todos los perfiles. Aunque podrás aprender a tu ritmo,
           adaptando los tiempos a tus otras obligaciones, completar con éxito el Máster exige constancia, dedicación y capacidad de trabajo.  



               </Typography>
               <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           
           Nuestro objetivo es que todos y cada uno de los alumnos que empiecen el Máster le saquen el máximo partido, se conviertan en auténticos expertos en el área y logren alcanzar sus metas. Por ello, te recomendamos que, antes de inscribirte, completes de forma completamente gratuita el primer 
           tema del Máster. Así podrás confirmar que efectivamente nuestra metodología se adapta a tu perfil y, en definitiva, que este Máster es lo que buscas en tu camino hacia nuevos retos profesionales.



               </Typography>
               <Grid container spacing={0} sx={{}}>
               <Grid xs={3}>
            
            </Grid>
            <Grid xs={6}>

            {Object.keys(currentUser).length === 0 && <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>
<Stack sx={{alignItems: "center"}}>
       <ToggleButtonGroup
      color="primary"
      value={inicioSesión}
      exclusive
      onChange={handleChangeInicioSesion}
      aria-label="Platform"
      sx={{marginTop: "80px"}}
    >
      <ToggleButton value="iniciosesion">Inicia sesión</ToggleButton>
      <ToggleButton value="registrate">Regístrate</ToggleButton>
      
    </ToggleButtonGroup>
    </Stack>



      <Stack sx={{alignItems: "center"}} >

      
                   {inicioSesión !== "iniciosesion" && 
                   
                   
                   <div>
                   <Box component="form" noValidate onSubmit={handleSignUp} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "30px", marginBottom: "30px", borderRadius: "25px", padding: "20px", maxWidth: "450px" }}>
                  <TextField   
                           sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                           margin="normal"
                           required
                           fullWidth
                           id="email"
                           label="E-mail"
                           name="email"
                           autoComplete="email"
                     
                           onChange={(e) => setEmail(e.target.value)}
                         />
           
           <TextField   
                           sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                           margin="normal"
                           required
                           fullWidth
                           id="password"
                           label="Contraseña"
                           name="password"
                           autoComplete="password"
                   
                           onChange={(e) => setPassword(e.target.value)}
                         />
                         <Button
                           type="submit"
                           fullWidth
                           variant="contained"
                           sx={{ mt: 3, mb: 2, backgroundColor: "#4a5b82", color: "white"}}
           
                         >Registrarse</Button>
                                                  <Stack>
                          <Typography sx={{color: "black"}}>{error}</Typography>
                          </Stack>
                         </Box>
           
           
               </div>
                   
                   }
                   {inicioSesión === "iniciosesion" && <InicioSesionDef/>}
      </Stack>
                   
       </Grid>}

       {(Object.keys(currentUser).length !== 0 && alumnos.length === 0) && 
            <Button onClick={handleOnSubmit} variant="contained" sx={{marginTop: "40px", alignItems: "center", color:"white", bgcolor: "#4a5b82", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600"}}>Comienza gratis el Máster en Derecho Procesal</Button>
       }

{(Object.keys(currentUser).length !== 0 && alumnos.length !== 0) && 
<Stack>
            <Button href="./dashboard" variant="contained" sx={{marginTop: "40px", alignItems: "center", color:"white", bgcolor: "#4a5b82", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600"}}>Ir a tus másteres</Button>
            </Stack>}
            </Grid>
            <Grid xs={3}>
            
            </Grid>
            </Grid>
             
               
            </Grid>
           
            <Grid xs={3}>
            </Grid>
            </Grid>

           

</Stack>    




          














                
  
    
  
  
          <Grid container spacing={0} sx={{height: '100%'}}>
       
           
            
          </Grid>

          <Footer/>
          </Box>









          <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >


<Grid xs={12} sx={{}}>
  <Item sx={{bgcolor: "#4a5b82"}}>
      <Stack sx={{backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
  <Grid container>
   
            <Grid item xs={12} sm={12} md={12}>
              <Stack sx= {{textAlign: "center", padding: "20px", bgcolor: "#4a5b82", opacity: "0.8"}}>

<Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "60px", textShadow: '2px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(0, 0, 0, 0.25)'}}>
Máster en Derecho <br></br> Procesal Civil 


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "40px", paddingBottom: "60px", textShadow: '2px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(0, 0, 0, 0.25)'}}>
Adquiere los conocimientos teóricos y prácticos necesarios <br></br> para convertirte en un experto abogado procesalista


</Typography>

</Stack>
              </Grid>
              

          </Grid>
  
        <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
          <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
        <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
     
            <Grid item xs={4} sm={4} md={4} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
              <Stack>
              <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
              Duración:
              </Typography>
              <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
              210 horas
              </Typography>
              </Stack>
            
            </Grid>
            <Grid item xs={4} sm={4} md={4} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
            <Stack>
              <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
              Modalidad:
              </Typography>
              <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
              100% online
              </Typography>
              </Stack>
            
            
            </Grid>
            <Grid item xs={4} sm={4} md={4} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
            <Stack>
              <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
              Formato:
              </Typography>
              <Typography variant="button" sx={{color: "white", fontSize: "15px", paddingBottom: "20px"}} >
              Part-time
              </Typography>
              </Stack>
            
            
            </Grid>
           
     
            </Grid>


        </Stack>
        <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>

        <Stack sx= {{minHeight: "525px", textAlign: "justify"}}>

 
       
    
            <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9"}}>

        <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "40px", paddingBottom: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "center"}}>
        Procesal es una práctica que no falta en ninguno de los más prestigiosos despachos de abogados. Cuando una operación societaria se tuerce, cuando las obligaciones de una financiación se incumplen o, en definitiva, cuando se avecina una disputa, entran en juego los abogados procesalistas.


            </Typography>
            
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "40px", paddingBottom: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "center"}}>
       Todo estudiante de Derecho o abogado tiene unos conocimientos básicos de procesal, obtenidos en la carrera y/o en el máster de acceso. Sin embargo, la diferencia entre estos conocimientos y los que debe tener un abogado procesalista es abismal.


            </Typography>

            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "40px", paddingBottom: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "center"}}>
       
El objetivo de este Máster es convertir al alumno, parta de la base que parta, en un experto abogado procesalista especializado en Derecho de los negocios. A través de un programa exigente, pero altamente flexible, el alumno adquirirá los conocimientos y habilidades propios de un experto resolviendo los mismos retos a los que se enfrentan los abogados procesalistas de los más prestigiosos despachos de abogados. 


            </Typography>

            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "40px", paddingBottom: "40px", paddingLeft: "30px", paddingRight: "30px", textAlign: "center"}}>
        Se trata de un programa eminentemente práctico, en el que no solo aprenderás Derecho procesal: aprenderás a ser abogado procesalista.

            </Typography>
        </Stack>
     
     
   

       

</Stack>



<Grid xs={12} sx={{paddingBottom: "30px", bgcolor: "#ebeef5", opacity: "0.9"}}>



<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
     ¿Para quién es este Máster?


         </Typography>










         <Grid container spacing={0} sx={{height: '100%', paddingBottom: "20px", paddingTop: "50px"}}>

<Grid xs={1}>

</Grid>

<Grid xs={10}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack direction="column"   justifyContent="center"
alignItems="center" sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "20px", paddingRight: "20px"}}>
Estudiantes de Grado o Máster de Acceso...


</Typography>

<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingRight: "20px", paddingLeft: "20px"}}>
...que valoren desarrollar su carrera profesional en el área de Derecho procesal de un despacho de prestigio y quieran contar con una ventaja competitiva a la hora de afrontar los procesos de selección y los primeros años de práctica.


</Typography>

</Stack>


</Item>
</Grid>






<Grid xs={1}>

</Grid>
</Grid>



<Grid container spacing={0} sx={{height: '100%', paddingBottom: "30px"}}>

<Grid xs={1}>

</Grid>

<Grid xs={10}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack direction="column"   justifyContent="center"
alignItems="center" sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", paddingRight: "20px", paddingLeft: "20px"}}>
Abogados junior...


</Typography>
<Divider orientation="vertical" flexItem sx={{bgcolor: "#d5dbe8", margingLeft: "20px"}}>

</Divider>
<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingRight: "20px", paddingLeft: "20px"}}>
...que deseen acelerar su formación y adquirir conocimientos equivalentes a los de un abogado de cinco años de experiencia en el área de Derecho procesal de un gran despacho de abogados. 


</Typography>

</Stack>


</Item>
</Grid>






<Grid xs={1}>

</Grid>
</Grid>




<Grid container spacing={0} sx={{height: '100%', paddingBottom: "100px"}}>

<Grid xs={1}>

</Grid>

<Grid xs={10}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack direction="column"   justifyContent="center"
alignItems="center" sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px", paddingRight: "20px", paddingLeft: "20px"}}>
Abogados especializados en otras áreas de práctica...


</Typography>
<Divider orientation="vertical" flexItem sx={{bgcolor: "#d5dbe8", margingLeft: "20px"}}>

</Divider>
<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", paddingRight: "20px", paddingLeft: "20px"}}>
...que deseen adquirir los conocimientos teóricos y prácticos necesarios para representar a sus clientes en cualesquiera litigios en la jurisdicción civil.


</Typography>

</Stack>


</Item>
</Grid>






<Grid xs={1}>

</Grid>
</Grid>







</Grid>




</Stack>
        </Item>
        
</Grid>

<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
Un método de aprendizaje diseñado por abogados <br></br> de los principales despachos del mundo. 


  </Typography>

 
 
  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 Todos los másteres de Univerlaw están diseñados conjuntamente con abogados senior, counsels o socios de los principales despachos de abogados del mundo, así como con abogados in-house de grandes empresas. Conjuntamente con estos reputados profesionales
 recopilamos, en un solo curso exigente y altamente especializado, todos los conocimientos teóricos y prácticos necesarios para triunfar en la abogacía de los negocios.



     </Typography>
     <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 
 En los másteres de Univerlaw adquirirás los conocimientos que los abogados de los principales despachos buscan en los candidatos que seleccionan para unirse a sus despachos. Adquirirás los conocimientos que los directores de asesoría jurídica requieren 
 a los abogados externos a los que encomiendan sus asuntos más sensibles. En definitiva, te proporcionaremos todas las herramientas necesarias para convertirte en un auténtico experto en el área de práctica.



     </Typography>

  
  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 Aprenderás de forma 100% online, a través de una plataforma diseñada expresamente para la formación en el derecho de los negocios. Puedes formarte de forma intensiva, si el resto de tus obligaciones te lo permite, o de manera más pausada, dedicando solo algunas horas semanales.
     </Typography>
     <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 La formación es eminentemente práctica, basada en el principio "learning by doing". Desde el primer día comenzarás a enfrentarte a casos reales que cada vez irás resolviendo con mayor pericia.
 Te enfrentarás a los mismos retos a los que se enfrenta un abogado junior / asociado de un gran despacho de abogados, pero centrándote en aquellas cuestiones que verdaderamente te permitirán evolucionar. 
     </Typography>

     <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 El objetivo es tan simple como ambicioso: convertirte en un auténtico experto en este área de práctica.
     </Typography>
     

 

 

</Grid>





<Divider></Divider>


<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "0px"}}>



<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "65px", paddingBottom: "35px", textAlign: "center"}}>
El día después del Máster


         </Typography>


         <Grid xs={12} sx={{paddingBottom: "30px"}}>



  <Grid container sx={{height: '100%', paddingBottom: "100px"}}>

  <Grid xs={12}>
  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "center"}}>
 La mayoría de los estudiantes de Univerlaw deciden realizar nuestros másteres con una meta profesional clara. Esta meta profesional puede ser consistir en conseguir un primer trabajo en alguno de los despachos de abogados de mayor prestigio,
 conseguir un cambio a un despacho con mejores condiciones, cambiar de área de práctica, o simplemente adquirir conocimientos a nivel de experto que ayuden en (y aceleren) el avance en la carrera profesional dentro del actual despacho.

 En Univerlaw no solo te permitiremos adquirir los conocimientos necesarios para esos propósitos, sino que además ponemos a tu disposición todas las herramientas adicionales necesarias para conseguir tu objetivo.  



     </Typography>





   
     
  </Grid>
 
 
  </Grid>

  <Stack sx={{height: '100%', paddingBottom: "100px", justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center"}}>




<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "60px", marginRight: "60px", marginTop: "0px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
Portal privado <br></br> de empleo


</Typography>
<Divider sx={{bgcolor: "white", }}></Divider>
<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
Todos nuestros másteres incluyen un año de acceso gratuito a Jobs&Law, nuestro portal privado de empleo, en el que podrás encontrar todas las vacantes profesionales de calidad
abiertas en el mercado.


</Typography>
<Box sx={{width: "100px", margin: 'auto'}} >

</Box>
</Stack>


</Item>

<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "60px", marginRight: "60px", marginTop: "60px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
Recursos para superar <br></br> los procesos de selección


</Typography>
<Divider sx={{bgcolor: "white", }}></Divider>
<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
Todos nuestros másteres incluyen una sección voluntaria, diseñada por reclutadores de los principales despachos de abogados, en la te daremos todas las claves para superar los procesos de selección más exigentes.


</Typography>
<Box sx={{width: "100px", margin: 'auto'}} >

</Box>
</Stack>


</Item>






<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "60px", marginRight: "60px", marginTop: "60px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
Perfil profesional <br></br> preferente, para siempre


</Typography>
<Divider sx={{bgcolor: "white", }}></Divider>
<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
Todos los alumnos de Univerlaw tienen la posibilidad de contar con un perfil profesional preferente en Jobs&Law, que les permitirá ser encontrados de forma sencilla y confidencial por reclutadores.


</Typography>

</Stack>


</Item>

<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center",  marginLeft: "60px", marginRight: "60px", marginTop: "60px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
Comunidad <br></br> Univerlaw


</Typography>
<Divider sx={{bgcolor: "white", }}></Divider>
<Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
Tras completar el máster formarás parte de la Comunidad Univerlaw, conformada por profesores -abogados senior, counsel y socios de despachos de reconocido prestigio-, reclutadores y alumni.


</Typography>
<Box sx={{width: "100px", margin: 'auto'}} >

</Box>
</Stack>


</Item>







</Stack>        

</Grid> 




</Grid>


<Grid xs={12} sx={{backgroundImage: `url(${"/images/ciudad.jpg"})`, backgroundPosition: "right bottom", backgroundSize: "cover", paddingBottom: "0px", bgcolor: "#4a5b82", opacity: "0.95"}}>



<Typography variant="h3" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "55px", textAlign: "center", bgcolor: "#4a5b82", opacity: "0.95"}}>
     Precio


         </Typography>

         <Grid container sx={{height: '100%', paddingBottom: "100px", bgcolor: "#4a5b82", opacity: "0.95"}}>


  <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 Creemos que la formación de élite no debe estar restringida a aquellas personas dispuestas a pagar decenas de miles de euros y a sacrificar un año de su carrera profesional. En Univerlaw puedes aprender de los abogados líderes de cada área
 a un precio sin competencia, y beneficiándote de facilidades de pago sea cual sea tu situación.



     </Typography>



     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "22px", paddingTop: "50px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 El precio total del Máster en Derecho Procesal es de <u>1.050 euros</u>. No hay ningún coste adicional.



     </Typography>

     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "50px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
     Ponemos a tu disposición opciones de financiación, sin coste, independientemente de tu situación económica.



     </Typography>





   
     


  </Grid>
</Grid>



<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "0px"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
Prueba gratis el Máster <br></br> antes de inscribirte. 


  </Typography>
  <Grid container spacing={0} sx={{height: '100%', paddingBottom: "20px"}}>
  
 
  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 Tenemos el convencimiento de que el Máster en Derecho Procesal de Univerlaw es el más completo y efectivo impartido en España. No obstante, la exigencia del programa no lo hace apto para todos los perfiles. Aunque podrás aprender a tu ritmo,
 adaptando los tiempos a tus otras obligaciones, completar con éxito el Máster exige constancia, dedicación y capacidad de trabajo.  



     </Typography>
     <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center", paddingRight: "30px", paddingLeft: "30px"}}>
 
 Nuestro objetivo es que todos y cada uno de los alumnos que empiecen el Máster le saquen el máximo partido, se conviertan en auténticos expertos en el área y logren alcanzar sus metas. Por ello, te recomendamos que, antes de inscribirte, completes de forma completamente gratuita el primer 
 tema del Máster. Así podrás confirmar que efectivamente nuestra metodología se adapta a tu perfil y, en definitiva, que este Máster es lo que buscas en tu camino hacia nuevos retos profesionales.



     </Typography>


  
 

   
     
  </Grid>
 

  </Grid>

  <Stack sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center", paddingTop: "30px", marginBottom: "30px"}}>
     {(Object.keys(currentUser).length !== 0 && alumnos.length === 0) && <Button onClick={handleOnSubmit} variant="contained" sx={{marginTop: "0px", marginBottom: "30px", 
     alignItems: "center", color:"white", bgcolor: "#4a5b82", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600", marginLeft: "30px", marginRight: "30px"}}>
      Comienza gratis el Máster en Derecho Procesal</Button>
}

</Stack>

  {(Object.keys(currentUser).length !== 0 && alumnos.length !== 0) && 
<Stack sx={{justifyContent: "center"}} >
            <Button href="./dashboard" variant="contained" sx={{marginTop: "0px", marginBottom: "30px", marginLeft: "20px", marginRight: "20px", alignItems: "center", color:"white", bgcolor: "#4a5b82", fontSize: "16px", 
            paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", fontFamily: "Lato", fontWeight: "600"}}>Ir a tus másteres</Button>
            </Stack>}

   
            <Stack sx={{}}>
     
          

            {Object.keys(currentUser).length === 0 && <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>
<Stack sx={{alignItems: "center"}}>
       <ToggleButtonGroup
      color="primary"
      value={inicioSesión}
      exclusive
      onChange={handleChangeInicioSesion}
      aria-label="Platform"
      sx={{marginTop: "0px"}}
    >
      <ToggleButton value="iniciosesion">Inicia sesión</ToggleButton>
      <ToggleButton value="registrate">Regístrate</ToggleButton>
      
    </ToggleButtonGroup>
    </Stack>



      <Stack sx={{alignItems: "center"}} >

      
                   {inicioSesión !== "iniciosesion" && 
                   
                   
                   <div>
                   <Box component="form" noValidate onSubmit={handleSignUp} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "30px", marginBottom: "30px", borderRadius: "25px", padding: "20px", maxWidth: "450px" }}>
                  <TextField   
                           sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                           margin="normal"
                           required
                           fullWidth
                           id="email"
                           label="E-mail"
                           name="email"
                           autoComplete="email"
                     
                           onChange={(e) => setEmail(e.target.value)}
                         />
           
           <TextField   
                           sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                           margin="normal"
                           required
                           fullWidth
                           id="password"
                           label="Contraseña"
                           name="password"
                           autoComplete="password"
                   
                           onChange={(e) => setPassword(e.target.value)}
                         />
                         <Button
                           type="submit"
                           fullWidth
                           variant="contained"
                           sx={{ mt: 3, mb: 2, backgroundColor: "#4a5b82", color: "white"}}
           
                         >Registrarse</Button>
                                                 <Stack>
                          <Typography sx={{color: "black"}}>{error}</Typography>
                          </Stack>
                         </Box>
           
           
               </div>
                   
                   }
                   {inicioSesión === "iniciosesion" && <InicioSesionDef/>}
      </Stack>
                   
       </Grid>}




       
     
            </Stack>


















      





</Box>













        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default PresentacionMasterDerechoPenal