import React from 'react'
import Header from '../../components/Header'
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';


const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function PortadaCursoExpertoDerechoProcesal() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
          <Header />
          <Box sx={{ flexGrow: 1, paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", bgcolor: "#f5f5f5" }}>
        <Grid container spacing={2} sx={{height: '100%'}}>
     
          <Grid xs={4}>
            <Item sx={{height: "89vh", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
            <Stack sx= {{height: "84vh", bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
            <Image src="/images/ciudad.jpg" style={{height: "100%", opacity: "0.2"}} sx={{}} />
                  <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px"}}>
                  Máster en <br/> Derecho Procesal Civil
  
  
                      </Typography>
                  <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
                  Todo lo que necesitas saber para defender a tus clientes en la jurisdicción civil
  
  
                      </Typography>
                      <Box sx={{width: "100px", margin: 'auto'}} >
            <Image src="/images/logoblanco.png" style={{width: "100%"}} sx={{paddingTop: "10px", alignContent: "center"}} />
            </Box>
                  </Stack>
  
  
    
  
              
   
            { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
              <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
                  <CardContent>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                      Tus notas
                  </Typography>
                  </CardContent>
              </Card>
              </Stack>*/}
              </Item>
          </Grid>
          <Grid xs={8}>
            <Item sx={{height: "89vh", bgcolor: "#4a5b82"}}>
              <Box sx={{ width: '100%', padding: "25px" }}>
       
          
              <Stack sx= {{height: "76vh", bgcolor: "#4a5b82", marginTop: "0px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                  <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px"}}>
                  Estimado alumno: 
  
                      </Typography>
                      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "0px", paddingBottom: "20px", lineHeight: "30px"}}>
                  Bienvenido al Máster en Derecho Procesal Civil. <br/>
                      </Typography>
                      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "0px", paddingBottom: "20px", lineHeight: "30px"}}>
                  El equipo de Univerlaw, compuesto por abogados de los principales despachos, ha diseñado este máster con el propósito de que cualquier 
                  estudiante o abogado especializado en otro área de práctica pueda convertirse en un auténtico experto en Derecho procesal civil en solo unos meses, y formándose completamente a distancia. El propósito de este máster es
                  que a su finalización el alumno -que lógicamente tendrá que contar con la habilitación legal necesaria para ello- sea plenamente capaz de intervenir en los procedimientos más habituales que se ventilan en la jurisdicción civil, ya sea redactando una demanda, 
                  acudiendo a una vista, o presentando cualquier tipo de recurso. 
  
  
                      </Typography>
                      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "0px", paddingBottom: "20px", lineHeight: "30px"}}>
                  Para alcanzar este objetivo de (casi) nada sirve limitarse a estudiar conceptos teóricos. Un abogado procesalista debe, 
                  por supuesto, conocer las leyes procesales y sustantivas relevantes, pero sobre todo debe saber identificarlas, aplicarlas y utilizarlas en su favor.
                  En este curso aprenderás a hacerlo, siguiendo una metodología eminentemente práctica.

  
  
                      </Typography>
                      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "0px", paddingBottom: "20px", lineHeight: "30px"}}>
                  En definitiva, en este Máster no solo aprenderás Derecho procesal. Aprenderás a ser abogado procesalista.

  
  
                      </Typography>

                  </Stack>
          
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Anterior
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1, color: "white" }}>
                  Siguiente
                </Button>
                
              </Box>

      </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
        </CssBaseline>
      </ThemeProvider>
      </div>
    )
}

export default PortadaCursoExpertoDerechoProcesal
