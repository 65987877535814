import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router';
import Image from 'mui-image';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { signOut } from 'firebase/auth';



const pages = ['Home', 'Jobs', 'SingleJob'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const HeaderLoggedIn = ({isAuth}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [state, setState] = React.useState({
    top: false,
  });


  const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const auth = getAuth();

    function handleSignUp(e) {
        e.preventDefault();
        console.log("Dale!")

        createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log("Estamos dentro bro")
    console.log(user)
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });

    }

    function handleLogOut(){
        signOut(auth).then(()=> {
            navigate("../")
        }).catch((error) => {
    
        } )
    }



  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      <ListItem  disablePadding>
            <ListItemButton  href="./" >
          
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="../dashboard" >
          
              <ListItemText primary="Tus Másteres" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="https://www.jobsandlaw.com" >
          
              <ListItemText primary="Empleos" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./sobrenosotros" >
          
              <ListItemText primary="Sobre Nosotros" />
            </ListItemButton>
          </ListItem>

          <ListItem  disablePadding>
          <Button
                onClick={handleLogOut}
               
                sx={{color: '#004AAD', display: 'block', paddingLeft: "18px", paddingTop: "20px"  }}
              >
                Cerrar sesión
              </Button>
          </ListItem>

         
      </List>
      <Divider />
      
    </Box>
  );

 
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickMenu = () => {

  };

  
console.log(currentUser)
  return (
    <AppBar position="static" style={{ background: 'white'}}>
      <Container maxWidth="xl" sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        
        <Toolbar disableGutters >



        <Grid container componente="main" spacing={0} sx={{justifyContent: "center"}}>
      <Grid item xs={5} sm={5} md={5} square>
      </Grid>
      <Grid item xs={1} sm={1} md={1} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",

             }}
            >
       
       <Button href="./" variant="text" size="small" style={{display: "flex", flexDirection: "column"}}>
       <Image
src="/images/logo.png" style={{width: "100px"}} sx={{marginLeft: "60px", marginRight: "60px", paddingTop: "15px", paddingBottom: "10px"}}
duration={
0
}

/>

    </Button>



      </Box>
      </Grid>
      
      <Grid item xs={1} sm={1} md={1} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",

             }}
            >
      

      </Box>
      </Grid>

      <Grid item xs={4} sm={4} md={4} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "flex-start",
                justifyContent: "center",

             }}
            >
      

      <Button
                onClick={handleCloseNavMenu}
                href="../dashboard"
                sx={{ my: 2, color: '#004AAD', display: 'block'  }}
              >
                Tus Másteres
              </Button>
      <Button
                onClick={handleCloseNavMenu}
                href="https://www.jobsandlaw.com"
                sx={{ my: 2, color: '#004AAD', display: 'block'  }}
              >
                Empleos
              </Button>
    
              <Button
                onClick={handleCloseNavMenu}
                href="https://www.jobsandlaw.com/rankings"
                sx={{ my: 2, color: '#004AAD', display: 'block', marginLeft: "5px"  }}
              >
                Rankings
              </Button>
            
          <Box sx={{ flexGrow: 0, paddingTop: "15px", paddingLeft: "20px" }}>
            <Tooltip title="Opciones">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MenuIcon sx={{marginTop: "6px", marginLeft: "8px", color: "#004AAD"}} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
             
            
             <MenuItem onClick={handleLogOut} sx={{paddingTop: "10px"}} >
             <Button
                onClick={handleLogOut}
               
                sx={{color: '#004AAD', display: 'block'  }}
              >
                Cerrar sesión
              </Button>
             </MenuItem>   
            
            </Menu>
          </Box>       
      </Box>
      </Grid>

      <Grid item xs={1} sm={1} md={1} square>
      </Grid>

    </Grid>







  
          
        </Toolbar>
      </Container>

      <Container width="100%" sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >
        
        <Toolbar disableGutters >



        <Grid container componente="main" spacing={0} sx={{justifyContent: "center", paddingBottom: "10px"}}>
   
        <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                justifyContent: "center",

             }}
            >

      </Box>
      
      </Grid>
      <Grid item xs={6} sm={6} md={6} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "center",

             }}
            >
       

       <Button href="./" variant="text" size="small" style={{display: "flex", flexDirection: "column"}}>
       <Image src="/images/logo.png" style={{width: "130px"}} sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} />
       <Image src="/images/logo.png" style={{width: "130px"}} sx={{paddingTop: "20px", display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} />

    </Button>


      </Box>
      
      </Grid>
      <Grid item xs={3} sm={3} md={3} square>
      <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "flex-end",
                alignContent: "center",
                marginTop: "10px",

             }}
            >
            
          {['top'].map((anchor) => (
        <React.Fragment key={anchor}>
            <IconButton
            size="large"
            edge="start"
           
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      </Box>
      
      </Grid>

 
      
      
      

    </Grid>

             






  
          
        </Toolbar>
      </Container>

    </AppBar>
  );
};
export default HeaderLoggedIn;