import React from 'react'
import { useState } from 'react';
import AuthProvider from '../components/authProvider'
import TextField from '@mui/material/TextField';

import { v4 as uuid } from 'uuid';
import { insertNewPagina, updatePagina } from '../firebase/firebase';
import { getPaginas } from '../firebase/firebase';
import Pagina from '../components/Pagina';
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';
import { CssBaseline, Divider, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../customCKEditor.css";
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import Link from '@mui/material/Link';
import Image from 'mui-image';



function IndiceCreator(getPageId) {


  
  const theme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

    const [title, setTitle] = useState("")
    const [currentUser, setCurrentUser] = useState({})
    const [paginas, setPaginas] = useState([])
    const [numSteps, setNumSteps] = useState(1)
    const [step1, setStep1] = useState("")
    const [step2, setStep2] = useState("")
    const [step3, setStep3] = useState("")
    const steps = [];
    const [texto, setTexto] = useState("")
    const [texto2, setTexto2] = useState("")
    const [texto3, setTexto3] = useState("")
    const [introTestIntro, setIntroTestIntro] = useState("")

    function handleButtonRemoveStep2 () {
      const newNumSteps = numSteps - 1;
      setNumSteps(newNumSteps);
      setStep1(""); 
      setStep2(""); 
      setStep3(""); 

     }



    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [index, setIndex] = useState(1)

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === numSteps - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === numSteps;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    console.log(index)
    setIndex(index+1)
    console.log(index)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIndex(index-1)
    console.log(index)
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };



    async function handleUserLoggedIn(user){
      setCurrentUser(user);  
      const resPaginas = await getPaginas(user.uid);
      setPaginas([...resPaginas]);
      console.log("logged in")
    }
    
    function handleUserNotLoggedIn(user){
        console.log("Not logged in")
    }
    
    function handleUserNotRegistered(user){
        console.log("Not registered")
    }

    function handleSubmit (e) {
       e.preventDefault();
       steps.push(step1)
       steps.push(step2)
       addPagina()
       console.log(steps)

       }

    function handleChange(e) {
        const value = e.target.value;
        if(e.target.name === "title") {
            setTitle(value);
            console.log(title)
        }

        if(e.target.name === "step1") {
          setStep1(value);
          console.log(step1)
          steps.push(step1)

          if (step2 === "") {
            setNumSteps(1);
            steps.push(step1)
          }
        
      }
    

    if(e.target.name === "step2") {
      setStep2(value);
      console.log(step2)
      steps.push(step2)
      console.log(steps)

      if (step2 !== "" && step3 === "") {
        setNumSteps(2);
        steps.push(step2)
      console.log(steps)
      }
    
      if (step2 === "") {
        setNumSteps(1)
        steps.push(step2)
      console.log(steps)
      }
  }

  if(e.target.name === "step3") {
    setStep3(value);
    console.log(step3)
    steps.push(step3)
    console.log(steps)

    if (step3 !== ""  && step2 !== "") {
      setNumSteps(3);
    }
  
    if (step3 === "") {
      setNumSteps(2)
    }
}

}


console.log(step2)
console.log(numSteps)
console.log(step3)

    function addPagina() {
      const newPagina = {
        id: uuid(),
        title: title,
        step1: step1,
        step2: step2,
        step3: step3,
        numSteps: numSteps,
        uid: currentUser.uid,
        texto: texto,
        texto2: texto2,
        texto3: texto3,
      };
      const res = insertNewPagina(newPagina)
      newPagina.docId = res.id;
      setTitle("");
      setStep1("");
      setPaginas([...paginas, newPagina])
      
      console.log(paginas)
    }

function handleDelete(){

}

async function handleUpdate(docId, 
  title,
  step1, 
  step2,
  step3,
  numSteps,
  texto,
  texto2,
  texto3,
){
  
  const pagina = paginas.find((item) => item.docId === docId);
  pagina.title = title;
  pagina.step1 = step1;
  pagina.step2 = step2;
  pagina.step3 = step3;
  pagina.numSteps = numSteps;
  pagina.texto = texto;
  pagina.texto2 = texto2;
  pagina.texto3 = texto3;

  await updatePagina(docId, pagina)
  console.log(pagina, docId, title)
}
    
console.log(paginas)
console.log(steps)

const matches = []


function renderLinks() {
  if (paginas.length > 0) {
    return paginas.map((pagina) => (
      <Pagina
        key={pagina.id}
        docId={pagina.docId}
        title={pagina.title}
        step1={pagina.step1}
        step2={pagina.step2}
        step3={pagina.step3}
        numSteps={pagina.numSteps}
        texto={pagina.texto}
        texto2={pagina.texto2}
        texto3={pagina.texto3}
      
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  }
}

const paginasProcesal = []

const eachjob = paginas.map((doc, index) => {
 
    

    if (doc.uid === currentUser.uid)
    
     {
      paginasProcesal.push(doc)
    }
    
  })
console.log(paginasProcesal)
const ordered = [...paginasProcesal].sort((a,b) => a.paginar - b.paginar);


function indice() {
    
  return ordered.map((pagina, index) => (
    <div>
      {index === 0 && 
      <Link underline="none" href={`/indicecreator/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "20px", textAlign: "center"}}>Tema 1: {pagina.nombreTema} ({pagina.paginar}) </Typography>
      </Link>}
      {index === 2 && 
      <Link underline="none" href={`/indicecreator/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "18px", textAlign: "center"}}>Capítulo 1: {ordered[index].title} ({pagina.paginar})</Typography>
      </Link>}
        {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada") && <Typography> Título {ordered[index].tema}: {ordered[index].nombreTema} ({pagina.paginar})</Typography>}

        <Link underline="none" href={`/indicecreator/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
        {(index !== 0 && index !== 2 && ordered[index].title !== ordered[index - 1].title) && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "20px", fontSize: "18px", textAlign: "center"}} > Capítulo {ordered[index].capitulo}: {ordered[index].title} ({pagina.paginar})</Typography>}
        </Link>

        <Link underline="none" href={`/indicecreator/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
          
    {ordered[index].tipoDiapo !== "portada" && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "14px", textAlign: "center"}} >{pagina.nombreDiapo} ({pagina.paginar})</Typography>}
    </Link>
    
    
    
    </div>
  ));

}


console.log(steps)
console.log(index)

  return (
    
 
     <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
      <CssBaseline>
            <div>

            <Grid paddingTop="20px" paddingBottom={"20px"} sx={{justifyContent: "center"}} container component="main">
              
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
              
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{minHeight: "89vh", bgcolor: "#4a5b82", color: "#0c2b5c", marginRight: "20px", marginLeft: "20px", borderRadius: "5px", padding: "20px"}}>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "35px", fontSize: "28px", textAlign: "center"}}> Índice del máster </Typography>
          {indice()}
          <Stack sx={{justifyContent: "center", alignContent: "center", alignItems: "center", textAlign: "center", marginTop: "40px"}}>
            <Button variant="outlined" sx={{justifyContent: "flex-start", maxWidth: "175px", color: "white", borderColor: "white"}} href="../creator">
              Nueva diapositiva
            </Button>
          </Stack>
          </Box>
          </Grid>
          </Grid>


             
    
    </div>
    </CssBaseline>
    </AuthProvider> 

  )
}

export default IndiceCreator