import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';
import AuthProvider from '../components/authProvider';


function LoginDef() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const auth = getAuth();
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            window.location.href = window.location.href;
            console.log("1")
            window.location.href = window.location.href;
            console.log("2")

            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });
      console.log("3")
    }

    function handleLogOut(){
        signOut(auth).then(()=> {
            console.log("Yeeees")
        }).catch((error) => {
    
        } )
   
    }

  return (
    <div>
        <Box component="form" noValidate onSubmit={handleSignUp} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "30px", marginBottom: "30px", borderRadius: "25px", padding: "20px", maxWidth: "450px" }}>
       <TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
          
                onChange={(e) => setEmail(e.target.value)}
              />

<TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="password"
                label="Contraseña"
                name="password"
                autoComplete="password"
        
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#4a5b82", color: "white"}}

              >Registrarse</Button>
              </Box>


    </div>
  )
}

export default LoginDef
