// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth"
import {getStorage, ref, uploadBytes, getDownloadURL, getBytes} from "firebase/storage";
import {getFirestore, collection, addDoc, getDocs, getDoc, query, where, setDoc, deleteDoc, doc} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTEID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export async function userExists(uid){
    const docRef = doc(db, "users", uid);
    const res = await getDoc(docRef);
    console.log(res)
    return res.exists();
}

export async function registerNewUser(user){
  try {
      const collectionRef = collection(db, "users");
      const docRef = doc(db, "users", user.uid);
      await setDoc(docRef, user);
  } catch (error) {

  }
}

export async function insertNewPagina(pagina){
  try{
    const docRef = collection(db, "paginas");
    const res = await addDoc(docRef, pagina);
    return res;
  } catch(error){
    console.error(error)
  }
}

export async function getPaginas(uid){
  const paginas = [];
  try{
    const collectionRef = collection(db, "paginas");
    const q = query(collectionRef, where ("uid", "==", "VO1j3yVb81R4r8YuuNvSF9LCXry2"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const pagina = {...doc.data()};
      pagina.docId = doc.id;
      paginas.push(pagina);
    })
    return paginas;
  } catch (error) {

  }
}

export async function updatePagina(docId, pagina) {
  try {
    const docRef = doc(db, "paginas", docId);
    const res = await setDoc(docRef, pagina);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function insertNewPaginaPenal(pagina){
  try{
    const docRef = collection(db, "paginaspenal");
    const res = await addDoc(docRef, pagina);
    return res;
  } catch(error){
    console.error(error)
  }
}

export async function getPaginasPenal(uid){
  const paginas = [];
  try{
    const collectionRef = collection(db, "paginaspenal");
    const q = query(collectionRef, where ("uid", "==", "Oxc2hhElEAgYDDIjo5Pl7wbFDxl2"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const pagina = {...doc.data()};
      pagina.docId = doc.id;
      paginas.push(pagina);
    })
    return paginas;
  } catch (error) {

  }
}

export async function updatePaginaPenal(docId, pagina) {
  try {
    const docRef = doc(db, "paginaspenal", docId);
    const res = await setDoc(docRef, pagina);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function getAlumnos(uid){
  const alumnos = [];
  try{
    const collectionRef = collection(db, "alumnosmasterderechoprocesal");
    const q = query(collectionRef, where ("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const alumno = {...doc.data()};
      alumno.docId = doc.id;
      alumnos.push(alumno);
    })
    return alumnos;
  } catch (error) {

  }
}

export async function getAlumnosPenal(uid){
  const alumnos = [];
  try{
    const collectionRef = collection(db, "alumnosmasterwhitecollar");
    const q = query(collectionRef, where ("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const alumno = {...doc.data()};
      alumno.docId = doc.id;
      alumnos.push(alumno);
    })
    return alumnos;
  } catch (error) {

  }
}

export async function updateAlumno(docId2, alumno) {
  try {
    const docRef = doc(db, "alumnosmasterderechoprocesal", docId2);
    const res = await setDoc(docRef, alumno);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function insertNewAlumnoMasterDerechoProcesal(alumno) {
  try {
const docRef = collection(db, "alumnosmasterderechoprocesal")
const res = await addDoc(docRef, alumno);
  } catch(error) {
    console.error(error);
  }
}

export async function insertNewAlumnoMasterDerechoPenal(alumno) {
  try {
const docRef = collection(db, "alumnosmasterwhitecollar")
const res = await addDoc(docRef, alumno);
  } catch(error) {
    console.error(error);
  }
}

export async function insertNewMatricula(pagina){
  try{
    const docRef = collection(db, "matriculas");
    const res = await addDoc(docRef, pagina);
    return res;
  } catch(error){
    console.error(error)
  }
}

export async function insertNewAdmision(pagina){
  try{
    const docRef = collection(db, "admisiones");
    const res = await addDoc(docRef, pagina);
    return res;
  } catch(error){
    console.error(error)
  }
}


