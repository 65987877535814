import React from 'react'
import Header from '../../components/Header'
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function CursoProcesalPagina2() {

    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <div>
       <ThemeProvider theme={theme}>
    <CssBaseline>   
        <Header />
        <Box sx={{ flexGrow: 1, paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", bgcolor: "#f5f5f5" }}>
      <Grid container spacing={2} sx={{height: '100%'}}>
   
        <Grid xs={4}>
          <Item sx={{height: "89vh", bgcolor: "#4a5b82"}}>
            <Stack sx={{bgcolor: "#4a5b82", borderRadius: "3px 3px 0px 0px", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "#d5dbe8", fontFamily: "Lato"}}>
                El recurso de casación civil
                </Typography>
            </Stack>
            <Divider sx={{bgcolor: "white"}}></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "#d5dbe8", fontSize: "13px", fontFamily: "Lato"}}>
                Análisis de la resolución
                </Typography>
            </Stack>
            <Divider sx={{bgcolor: "white"}}></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "#d5dbe8", fontSize: "12px", fontFamily: "Lato"}}>
                    ¿Es recurrible?
                </Typography>
            </Stack>
            <Divider sx={{bgcolor: "white"}}></Divider>

            <Grid container spacing={2} sx={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}} >
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}}>
                  <ListAltIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Programa
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}} >
                  <TextSnippetIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Apuntes
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                <Stack sx={{alignItems: "center"}} >
                  <ForumIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Dudas
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{paddingLeft: "20px", paddingRight: "20px"}} >
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}}>
                  <ListAltIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Programa
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}} >
                  <TextSnippetIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Apuntes
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                <Stack sx={{alignItems: "center"}} >
                  <ForumIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Dudas
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
            </Grid>

            
 
          { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
            <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
                <CardContent>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                    Tus notas
                </Typography>
                </CardContent>
            </Card>
            </Stack>*/}
            </Item>
        </Grid>
        <Grid xs={8}>
          <Item sx={{height: "89vh", bgcolor: "#4a5b82"}}>
            <Box sx={{ width: '100%', padding: "25px" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#d5dbe8', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: '#4a5b82', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() &&
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        }

        {activeStep === 0 && 
        <div>
        
            <Stack sx= {{height: "70vh", bgcolor: "#4a5b82", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600"}}>
                En este periodo inicial la tarea pasa por realizar un análisis pausado y concienzudo de la
resolución, orientado a determinar si es posible formular recurso de casación. En una fase
posterior ya se valorará si, además de posible, es conveniente recurrir la resolución.
Durante este inicial examen debemos dar respuesta a las siguientes preguntas: <br/>
(a) ¿Es una resolución recurrible?
Se trata de una sencilla verificación en la mayoría de los supuestos, pero en ocasiones
concurren particularidades que la hacen compleja.
La resolución será recurrible (art. 477.2 LEC y apartado II del Acuerdo):
(i) Si es una sentencia dictada por una Audiencia Provincial, salvo que:
(1) La sentencia carezca de la condición de sentencia dictada en segunda
instancia por acordar la nulidad y retroacción de las actuaciones o la
absolución en la instancia1
, o por resolver una cuestión incidental.
(2) Sea una sentencia dictada en un juicio verbal tramitado por razón de la
cuantía inferior a 3.000 euros (y que, en consecuencia, no era susceptible de
apelación).
(3) Sea una sentencia que debió adoptar forma de auto.
(4) Sea una sentencia dictada, o que debió dictarse, por un único magistrado2.
(ii) Si se trata de un auto recurrible conforme a reglamentos, tratados o convenios
internacionales o de la Unión Europea (art. 477.2 LEC, en relación con la norma
aplicable en cada caso3). El resto de autos no son recurribles.
Si la resolución es formalmente recurrible, podremos continuar el análisis.


                    </Typography>
                </Stack>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1, color: "#4a5b82" }}>
                Siguiente
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete} sx={{color: "#4a5b82" }}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
      </div>
    
    }


{activeStep === 1 && 
        <div>
        <Typography>
            2
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
      </div>
    
    }


       {/* ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
                    ) */ }


      </div>
    </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
      </CssBaseline>
    </ThemeProvider>
    </div>
  )
}

export default CursoProcesalPagina2