import React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function IndiceComp ({docId, master, status, paginar, diaposCompletadas, activeStep, numSteps, step2, thisIndex, ordered, tipoDiapo}) {
  const [status2, setCurrentStatus2] = useState(status)
  const [currentDiaposCompletadas, setCurrentDiaposCompletadas] = useState(diaposCompletadas)
  const navigate = useNavigate();

console.log(currentDiaposCompletadas)



useEffect(() => {
  setCurrentDiaposCompletadas([...diaposCompletadas, paginar])
}, [])

   

    const nextSlide = () => {
    
      console.log(thisIndex)
      const nextIndex = thisIndex+1
      const chosenOne =  ordered[nextIndex]
      console.log(nextIndex)
      console.log(chosenOne)
      console.log(chosenOne.docId)
      console.log(currentDiaposCompletadas)
      navigate(`../masterderechoprocesal/${chosenOne.docId}`);
      window.location.replace('');
    }


console.log(ordered)

  return (
    ordered.map((pagina, index) => (
      <div >
      {index === 0 && 
      <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} >
      <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "20px"}}>Tema 1: {pagina.nombreTema} {(index === 0 && diaposCompletadas.some((element) => element === pagina.paginar) )&& <CheckCircleIcon sx={{paddingTop: "10px"}} />}</Typography>
      </Link>}
      
     
    
      
        {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada" && ordered[index].tipoDiapo !== "portadacapitulo") && <Typography> Título {ordered[index].tema}: {ordered[index].nombreTema} {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada" && ordered[index].tipoDiapo !== "portadacapitulo" && diaposCompletadas.some((element) => element === pagina.paginar)) && <CheckCircleIcon sx={{paddingTop: "10px"}}/>}</Typography>}

        {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada" && ordered[index].tipoDiapo !== "portadacapitulo" && diaposCompletadas.some((element) => element === pagina.paginar)) && "check"}

        {(index !== 0 && ordered[index].title !== ordered[index - 1].title) &&
        <div> 
        <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} >
        <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "20px", fontSize: "18px"}} > Capítulo {ordered[index].capitulo}: {ordered[index].title} {(index !== 0 && ordered[index].title !== ordered[index - 1].title && diaposCompletadas.some((element) => element === pagina.paginar)) && <CheckCircleIcon sx={{paddingTop: "10px"}}/>} </Typography>
        </Link>
        </div>
        }
        
        


        <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} >
          
    {ordered[index].tipoDiapo !== "portada" && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "14px"}} >{pagina.nombreDiapo} {(ordered[index].tipoDiapo !== "portada" && diaposCompletadas.some((element) => element === pagina.paginar)) && <CheckCircleIcon sx={{paddingTop: "10px"}}/>}</Typography>}
    </Link>
    
    
    
    </div>
    ))
  )
}

export default IndiceComp