import React from 'react'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Header from '../components/Header';
import HeaderLoggedIn from '../components/HeaderLoggedIn';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import Progressbar from '../components/Progressbar';
import Link from '@mui/material/Link';
import { getPaginas } from '../firebase/firebase';
import AuthProvider from '../components/authProvider';
import { v4 as uuid } from 'uuid';
import { insertNewPagina, updatePagina } from '../firebase/firebase';
import Pagina from '../components/Pagina';
import IndiceComp from '../components/IndiceComp';
import { getAlumnos } from '../firebase/firebase';
import ContinuarMaster from '../components/ContinuarMaster';
import Login from '../components/Login';
import LoginDef from '../components/LoginDef';
import InicioSesionDef from '../components/InicioSesionDef';
import CierreSesionDef from '../components/CierreSesionDef';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TusMasteres from '../components/TusMasteres';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { signOut } from 'firebase/auth';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import Footer from '../components/Footer';


const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function Dashboard(getPageId) {
    


  let params = useParams();
  
      const theme = createTheme({
        palette: {
          primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
          },
        },
      });
    
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
       
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    
      const section = {
        height: "100%",
        paddingTop: 5,
        backgroundColor: "#fff"
      };
    
        const [title, setTitle] = useState("")
        const [currentUser, setCurrentUser] = useState({})
        const [paginas, setPaginas] = useState([])
        const [numSteps, setNumSteps] = useState(1)
        const [step1, setStep1] = useState("")
        const [step2, setStep2] = useState("")
        const [step3, setStep3] = useState("")
        const steps = [];
        const [texto, setTexto] = useState("")
        const [texto2, setTexto2] = useState("")
        const [texto3, setTexto3] = useState("")
        const [introTestIntro, setIntroTestIntro] = useState("")
        const [alumnos, setAlumnos] = useState([])
        const [inicioSesión, setInicioSesión] = useState("iniciosesion")
        const [master, setMaster] = useState("")
        const [error, setError] = useState("")
    
        function handleButtonRemoveStep2 () {
          const newNumSteps = numSteps - 1;
          setNumSteps(newNumSteps);
          setStep1(""); 
          setStep2(""); 
          setStep3(""); 
    
         }
    
    
         useEffect(() => {
          getPaginas();
          getAlumnos();
          console.log(params.pageId);
          console.log(alumnos)
          console.log(currentUser) 

        }, [])


      
        

        const [activeStep, setActiveStep] = React.useState(0);
      const [completed, setCompleted] = React.useState({});
      const [index, setIndex] = useState(1)
    
      const totalSteps = () => {
        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
      const isLastStep = () => {
        return activeStep === numSteps - 1;
      };
    
      const allStepsCompleted = () => {
        return completedSteps() === numSteps;
      };
    
      const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
        console.log(index)
        setIndex(index+1)
        console.log(index)
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setIndex(index-1)
        console.log(index)
      };
    
      const handleStep = (step) => () => {
        setActiveStep(step);
      };
    
      const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      };
    
      const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
      };
    


    
    
        async function handleUserLoggedIn(user){
          setCurrentUser(user);  
          const resPaginas = await getPaginas(user.uid);
          setPaginas([...resPaginas]);
          console.log("logged in")

          
        const resAlumno = await getAlumnos(user.uid)
        setAlumnos([...resAlumno])
         console.log(alumnos)
        }
        
        function handleUserNotLoggedIn(user){
            console.log("Not logged in")
        }
        
        function handleUserNotRegistered(user){
            console.log("Not registered")
        }
    
        function handleSubmit (e) {
           e.preventDefault();
           steps.push(step1)
           steps.push(step2)
           addPagina()
           console.log(steps)
    
           }
    
        function handleChange(e) {
            const value = e.target.value;
            if(e.target.name === "title") {
                setTitle(value);
                console.log(title)
            }
    
            if(e.target.name === "step1") {
              setStep1(value);
              console.log(step1)
              steps.push(step1)
    
              if (step2 === "") {
                setNumSteps(1);
                steps.push(step1)
              }
            
          }
        
    
        if(e.target.name === "step2") {
          setStep2(value);
          console.log(step2)
          steps.push(step2)
          console.log(steps)
    
          if (step2 !== "" && step3 === "") {
            setNumSteps(2);
            steps.push(step2)
          console.log(steps)
          }
        
          if (step2 === "") {
            setNumSteps(1)
            steps.push(step2)
          console.log(steps)
          }
      }
    
      if(e.target.name === "step3") {
        setStep3(value);
        console.log(step3)
        steps.push(step3)
        console.log(steps)
    
        if (step3 !== ""  && step2 !== "") {
          setNumSteps(3);
        }
      
        if (step3 === "") {
          setNumSteps(2)
        }
    }
    
    }
    
    
    console.log(step2)
    console.log(numSteps)
    console.log(step3)
    
        function addPagina() {
          const newPagina = {
            id: uuid(),
            title: title,
            step1: step1,
            step2: step2,
            step3: step3,
            numSteps: numSteps,
            uid: currentUser.uid,
            texto: texto,
            texto2: texto2,
            texto3: texto3,
          };
          const res = insertNewPagina(newPagina)
          newPagina.docId = res.id;
          setTitle("");
          setStep1("");
          setPaginas([...paginas, newPagina])
          
          console.log(paginas)
        }
    
    function handleDelete(){
    
    }
    
    async function handleUpdate(docId, 
      title,
      step1, 
      step2,
      step3,
      numSteps,
      texto,
      texto2,
      texto3,
    ){
      
      const pagina = paginas.find((item) => item.docId === docId);
      pagina.title = title;
      pagina.step1 = step1;
      pagina.step2 = step2;
      pagina.step3 = step3;
      pagina.numSteps = numSteps;
      pagina.texto = texto;
      pagina.texto2 = texto2;
      pagina.texto3 = texto3;
    
      await updatePagina(docId, pagina)
      console.log(pagina, docId, title)
    }
        
    console.log(paginas)
    console.log(steps)
    
    const matches = []
    
    
    function renderLinks() {
      if (paginas.length > 0) {
        return paginas.map((pagina) => (
          <Pagina
            key={pagina.id}
            docId={pagina.docId}
            title={pagina.title}
            step1={pagina.step1}
            step2={pagina.step2}
            step3={pagina.step3}
            numSteps={pagina.numSteps}
            texto={pagina.texto}
            texto2={pagina.texto2}
            texto3={pagina.texto3}
          
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        ));
      }
    }
    
    const paginasProcesal = []
    
    const eachjob = paginas.map((doc, index) => {
     
        
    
        if (doc.curso === "procesal")
        
         {
          paginasProcesal.push(doc)
        }
        
      })
    console.log(paginasProcesal)
    const ordered = [...paginasProcesal].sort((a,b) => a.paginar - b.paginar);
    
    function indice() {
        
      return ordered.map((pagina, index) => (
        <div>
          {index === 0 && 
          <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "20px"}}>Tema 1: {pagina.nombreTema} </Typography>
          </Link>}
          {index === 2 && 
          <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "18px"}}>Capítulo 1: {ordered[index].title}</Typography>
          </Link>}
            {(index !== 0 && ordered[index].nombreTema !== ordered[index - 1].nombreTema && ordered[index].tipoDiapo !== "portada") && <Typography> Título {ordered[index].tema}: {ordered[index].nombreTema} </Typography>}
    
            <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
            {(index !== 0 && index !== 2 && ordered[index].title !== ordered[index - 1].title) && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "20px", fontSize: "18px"}} > Capítulo {ordered[index].capitulo}: {ordered[index].title} </Typography>}
            </Link>
    
            <Link underline="none" href={`/masterderechoprocesal/${pagina.docId}`} key={pagina.id} onClick ={(e) => getPageId(pagina.id) }>
              
        {ordered[index].tipoDiapo !== "portada" && <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", fontSize: "14px"}} >{pagina.nombreDiapo}</Typography>}
        </Link>
        
        
        
        </div>
      ));
    
    }
    
    
    console.log(steps)
    console.log(index)
    console.log(paginas)
    console.log(ordered)
    console.log(paginasProcesal)
console.log(currentUser)

    
const handleChangeInicioSesion = (event, newAlignment) => {
  setInicioSesión(newAlignment);
};










  
  console.log(master)



  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault()
   
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          setCurrentUser(user)


          // ...
      })
      .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          if (errorCode === "auth/weak-password"){
            setError("La contraseña debe tener al menos seis caracteres")
          }
          if (errorCode === "auth/email-already-in-use"){
            setError("Ya estás registrado, inicia sesión")
          }
          // ..
      });

  }

  function handleLogOut(){
      signOut(auth).then(()=> {
          console.log("Yeeees")
      }).catch((error) => {
  
      } )
 
  }

  
    return (
      <div>
             <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
          {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}



          

          <Grid xs={12} sx={{}}>


          <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >  
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                    <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  </Item>
                  </Box>




                  <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >
                  <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
                  <Grid container>
 
 <Grid item xs={12} sm={12} md={12}>
   <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>

<Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Law School | Derecho de los negocios


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Formación especializada diseñada para el éxito en la abogacía de élite


</Typography>

</Stack>
   </Grid>
   

</Grid>
</Item>
                    </Box>  












{Object.keys(currentUser).length !== 0 && <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "75px", paddingBottom: "50px", textAlign: "center", paddingLeft: "30px", paddingRight: "30px"}}>
               Tus estudios


                   </Typography>
                  
                   {Object.keys(alumnos).length !== 0 ? (alumnos.map((alumno) => (
        <TusMasteres key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} diaposCompletadas={alumno.diaposCompletadas} activeStep={activeStep} numSteps={numSteps} step2={step2} ordered={ordered} />
        ))) 
        
        
        
        : <Typography  variant="h6" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "0px", paddingBottom: "100px", textAlign: "center", paddingLeft: "30px", paddingRight: "30px"}}>No has iniciado ningún programa. <br/> Elige el programa que mejor se adapte
        a tus objetivos y da un salto <br/> en tu carrera profesional en la abogacía de élite</Typography>
      
   
      
      
      }
       </Grid>}

      

       {Object.keys(currentUser).length === 0 && <Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>
<Stack sx={{alignItems: "center"}}>
       <ToggleButtonGroup
      color="primary"
      value={inicioSesión}
      exclusive
      onChange={handleChangeInicioSesion}
      aria-label="Platform"
      sx={{marginTop: "80px"}}
    >
      <ToggleButton value="iniciosesion">Inicia sesión</ToggleButton>
      <ToggleButton value="registrate">Regístrate</ToggleButton>
      
    </ToggleButtonGroup>
    </Stack>



      <Stack sx={{alignItems: "center"}} >

      
                   {inicioSesión !== "iniciosesion" && 
                   <div>


<div>
        <Box component="form" noValidate onSubmit={handleSignUp} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "30px", marginBottom: "30px", borderRadius: "25px", padding: "20px", maxWidth: "450px" }}>
       <TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
          
                onChange={(e) => setEmail(e.target.value)}
              />

<TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="password"
                label="Contraseña"
                name="password"
                autoComplete="password"
        
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#4a5b82", color: "white"}}

              >Registrarse</Button>
                    <Stack>
                          <Typography sx={{color: "black"}}>{error}</Typography>
                          </Stack>
              </Box>


    </div>

                   </div>
                   
                   }
                   {inicioSesión === "iniciosesion" && <InicioSesionDef/>}
      </Stack>
                   
       </Grid>}

       
                 
            
          </Grid>







{(Object.keys(alumnos).length === 0 && Object.keys(currentUser).length !== 0 ) && 
<div>
<Divider sx={{bgcolor: "white", }}></Divider>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "100px", textAlign: "center", paddingLeft: "30px", paddingRight: "30px"}}>
            Nuestros másteres


                </Typography>

                <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >

      <Grid container spacing={2} sx={{bgcolor: "white", paddingBottom: "30px"}}>


       <Grid xs={2}>
    
  </Grid>

  <Grid xs={2}>
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button href="./presentacionmasterderechoprocesal" variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px"}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
  </Grid>

  <Grid xs={2}>
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "0px", fontSize: "14px"}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 26 febrero 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
  </Grid>
                  <Grid xs={2}>
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "0px", fontSize: "14px"}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 10 enero 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
  </Grid>

  <Grid xs={2}>
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "0px", fontSize: "14px"}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 26 febrero 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
  </Grid>


      </Grid>
      </Box>
      
      <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >




      <Stack sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center"}}>


<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "30px", marginRight: "30px", marginBottom: "30px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>


    <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
    Máster en <br/> Derecho Procesal Civil


        </Typography>
    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
    Defiende a empresas multinacionales en sus litigios más complejos


        </Typography>
        <Button href="./presentacionmasterderechoprocesal" variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px"}}>
          Saber más
        </Button>
        
        
    </Stack>

    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
    Matrícula abierta


        </Typography>






{ /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
<Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
    <CardContent>
    <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
        Tus notas
    </Typography>
    </CardContent>
</Card>
</Stack>*/}
</Item>



<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "30px", marginRight: "30px", marginBottom: "30px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>


    <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
    Máster en <br/> Derecho Concursal


        </Typography>
    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
    Asesora a deudores y acreedores en escenarios de insolvencia


        </Typography>
        <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "0px", fontSize: "14px"}}>
          Saber más
        </Button>
        
        
    </Stack>

    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
    Nuevas plazas limitadas: <br/> 26 febrero 2024


        </Typography>






{ /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
<Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
    <CardContent>
    <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
        Tus notas
    </Typography>
    </CardContent>
</Card>
</Stack>*/}
</Item>

    
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "30px", marginRight: "30px", marginBottom: "30px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>


    <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
    Máster en <br/> Tech Law


        </Typography>
    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
    Domina el derecho de las nuevas tecnologías y asesora a las BigTech


        </Typography>
        <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "0px", fontSize: "14px"}}>
          Saber más
        </Button>
        
        
    </Stack>

    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
    Nuevas plazas limitadas: <br/> 10 enero 2024


        </Typography>






{ /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
<Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
    <CardContent>
    <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
        Tus notas
    </Typography>
    </CardContent>
</Card>
</Stack>*/}
</Item>



<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginLeft: "30px", marginRight: "30px", marginBottom: "30px", maxWidth: "500px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>


    <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
    Máster en <br/> White Collar Law


        </Typography>
    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
    Conviértete en un abogado penalista experto especializado en delitos económicos


        </Typography>
        <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "0px", fontSize: "14px"}}>
          Saber más
        </Button>
        
        
    </Stack>

    <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
    Nuevas plazas limitadas: <br/> 26 febrero 2024


        </Typography>






{ /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
<Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
    <CardContent>
    <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
        Tus notas
    </Typography>
    </CardContent>
</Card>
</Stack>*/}
</Item>

</Stack>


</Box>
      
      </div> }

 







 














          














                
  
    
  
  
          <Grid container spacing={2} sx={{height: '100%'}}>
       
           
            
          </Grid>

<Footer/>
        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default Dashboard