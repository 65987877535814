import React from 'react'
import Header from '../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';

const steps = ['Introducción', 'Ejemplos positivos', 'Ejemplos negativos'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function PlantillaTeoria() {

    const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <div>
       <ThemeProvider theme={theme}>
    <CssBaseline>   
        <Header />
        <Box sx={{ flexGrow: 1, paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", bgcolor: "#edf0f5" }}>
      <Grid container spacing={2} sx={{height: '100%'}}>
   
        <Grid xs={4}>
          <Item sx={{height: "89vh", bgcolor: "white"}}>
            <Stack sx={{bgcolor: "#4a5b82", borderRadius: "3px 3px 0px 0px", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontFamily: "Lato"}}>
                Experto en Derecho Procesal Civil
                </Typography>
            </Stack>
            <Divider></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontSize: "13px", fontFamily: "Lato"}}>
                La jurisdicción civil
                </Typography>
            </Stack>
            <Divider></Divider>
            <Stack sx={{bgcolor: "#4a5b82", paddingTop: "10px", paddingBottom: "10px"}}>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "white", fontSize: "12px", fontFamily: "Lato"}}>
                    Régimen general
                </Typography>
            </Stack>
            <Divider></Divider>

            <Grid container spacing={2} sx={{paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}} >
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}}>
                  <ListAltIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Programa
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}} >
                  <TextSnippetIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Apuntes
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5e2f7", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                <Stack sx={{alignItems: "center"}} >
                  <ForumIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Dudas
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{paddingLeft: "20px", paddingRight: "20px"}} >
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}}>
                  <ListAltIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Programa
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                  <Stack sx={{alignItems: "center"}} >
                  <TextSnippetIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Apuntes
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
              <Grid xs={4} >
                <Item sx={{bgcolor: "#d5dbe8", height: "15vh", paddingTop: "3vh", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                <Stack sx={{alignItems: "center"}} >
                  <ForumIcon sx={{ fontSize: "60px", color: "#4a5b82" }} />
                  </Stack>
                  <Stack>
                  <Typography variant="overline" sx={{fontWeight: "bold", color: "#4a5b82", fontSize: "10px", fontFamily: "Lato"}}>
                    Dudas
                  </Typography>
                  </Stack>
                </Item>
              </Grid>
            </Grid>

            
 
          { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
            <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
                <CardContent>
                <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
                    Tus notas
                </Typography>
                </CardContent>
            </Card>
            </Stack>*/}
            </Item>
        </Grid>
        <Grid xs={8}>
          <Item sx={{height: "89vh", bgcolor: "#d5e2f7"}}>
            <Box sx={{ width: '100%', padding: "25px" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#4a5b82', // circle color (ACTIVE)
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() &&
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        }

        {activeStep === 0 && 
        <div>
        
            <Stack sx= {{height: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>

                {parse("<p>El orden jurisdiccional civil es denominado el orden “común” u “ordinario”, porque en él se examinan los litigios cuyo conocimiento no se atribuye expresamente a otro régimen jurisdiccional.&nbsp;</p><p>La jurisdicción civil conoce de:</p><ul><li>Básicamente, todas las reclamaciones entre particulares (personas físicas o jurídicas) de carácter estrictamente privado.</li></ul><p>Y no conoce de:</p><ul><li>Conductas penales.</li><li>Disputas con la administración (salvo que no actúe como tal).</li><li>Disputas laborales.</li></ul><p>El ámbito de conocimiento de la jurisdicción civil viene fundamentalmente determinado por los artículos 22 y 85 de la Ley Orgánica del Poder Judicial (“<strong>LOPJ</strong>”).</p>")}


                    </Typography>
                </Stack>
        
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} disabled={activeStep === totalSteps() - 1} sx={{ mr: 1, color: "#4a5b82" }}>
              
                Siguiente
              </Button>
              <Button onClick={handleComplete} sx={{color: "#4a5b82" }} disabled={activeStep !== totalSteps() - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>
            </Box>
      </div>
    
    }


{activeStep === 1 && 
               <div>
        
               <Stack sx= {{height: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>
   
                   {parse("<p>Por ejemplo, la jurisdicción civil conoce de asuntos como:</p><ul><li>Una disputa entre un consumidor y una empresa en la que se discute sobre la posible nulidad de una cláusula contractual.</li><li>Un litigio entre dos vecinos sobre la propiedad de una franja de terreno.</li><li>Una reclamación de cantidad de un banco a un deudor que mantiene impagado un préstamo con garantía hipotecaria.</li><li>Un procedimiento de divorcio.</li><li>Una controversia relativa a una herencia.</li><li>Un litigio en el que uno de los socios de una sociedad mercantil defiende que un acuerdo alcanzado por el consejo de administración es nulo.</li></ul>")}
   
   
                       </Typography>
                   </Stack>
           
           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                 <Button
                   color="inherit"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   sx={{ mr: 1 }}
                 >
                   Anterior
                 </Button>
                 <Box sx={{ flex: '1 1 auto' }} />
                 <Button onClick={handleNext} disabled={activeStep === totalSteps() - 1} sx={{ mr: 1, color: "#4a5b82" }}>
                   Siguiente
                 </Button>
                 <Button onClick={handleComplete} sx={{color: "#4a5b82" }} disabled={activeStep !== totalSteps() - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>
               </Box>
         </div>
    
    }

{activeStep === 2 && 
               <div>
        
               <Stack sx= {{height: "70vh", bgcolor: "white", marginTop: "20px", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px"}}>
                   <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600"}}>
   
                   {parse("<p>Por el contrario, la jurisdicción civil no conoce de asuntos como:</p><ul><li>Un litigio en el que se discuta si un despido es o no procedente.</li><li>Una controversia relativa a la validez de un expediente de regulación de empleo.</li><li>Un procedimiento en el que se enjuicie si una conducta es constitutiva de estafa.</li><li>Un procedimiento en el que se juzgue a una empresa por un posible delito fiscal.</li><li>Un procedimiento en el que una persona física o jurídica discuta una liquidación de impuestos practicada por la Agencia Tributaria.</li><li>Un procedimiento en el que una persona física impugne el resultado de unas oposiciones.</li></ul>")}
   
   
                       </Typography>
                   </Stack>
           
           <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                 <Button
                   color="inherit"
                   disabled={activeStep === 0}
                   onClick={handleBack}
                   sx={{ mr: 1 }}
                 >
                   Anterior
                 </Button>
                 <Box sx={{ flex: '1 1 auto' }} />
                 <Button onClick={handleNext} disabled={activeStep === totalSteps() - 1} sx={{ mr: 1, color: "#4a5b82" }}>
                   Siguiente
                 </Button>
                
                     <Button onClick={handleComplete} sx={{color: "#4a5b82" }} disabled={activeStep !== totalSteps() - 1}>
                      
                         ¡Lo tengo claro!
                     </Button>
                   
               </Box>
         </div>
    
    }


       {/* ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
                    ) */ }


      </div>
    </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
      </CssBaseline>
    </ThemeProvider>
    </div>
  )
}

export default PlantillaTeoria
