import React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


function TusMasteres ({docId, master, status, paginar, diaposCompletadas, activeStep, numSteps, step2, thisIndex, ordered, tipoDiapo}) {
  const [status2, setCurrentStatus2] = useState(status)
  const [currentDiaposCompletadas, setCurrentDiaposCompletadas] = useState(diaposCompletadas)
  const navigate = useNavigate();

console.log(currentDiaposCompletadas)



useEffect(() => {
  setCurrentDiaposCompletadas([...diaposCompletadas, paginar])
}, [])

   

    const nextSlide = () => {
    
      console.log(thisIndex)
      const nextIndex = thisIndex+1
      const chosenOne =  ordered[nextIndex]
      console.log(nextIndex)
      console.log(chosenOne)
      console.log(chosenOne.docId)
      console.log(currentDiaposCompletadas)
      navigate(`../masterderechoprocesal/${chosenOne.docId}`);
      window.location.replace('');
    }


console.log(ordered)

  return (
 
      <div>
      <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >
      <Stack sx={{marginLeft: "60px", marginBottom: "40px"}} >
          <Stack sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", width: "400px", marginTop: "30px", marginLeft: "30px", marginBottom: "30px", borderRadius: "5px"}}>
    { <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}} href="../dashboardmasterderechoprocesal" >
                Ir al máster
              </Button>
              
              
          </Stack>}

     
  
  
      </Stack>
      </Stack>
      </Box>

      <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >

      <Stack sx={{marginLeft: "20px", marginRight: "20px", marginBottom: "40px", justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center"}} >
          <Stack sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginTop: "30px", marginBottom: "30px", borderRadius: "5px", maxWidth: "500px"}}>
    { <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}} href="../dashboardmasterderechoprocesal" >
                Ir al máster
              </Button>
              
              
          </Stack>}

     
  
  
      </Stack>
      </Stack>

      </Box>
    
    </div>
    )
  
}

export default TusMasteres