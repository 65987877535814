import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import userEvent from '@testing-library/user-event';
import AuthProvider from '../components/authProvider';
import HeaderLoggedIn from '../components/HeaderLoggedIn';
import Link from '@mui/material/Link';
import Footer from '../components/Footer';


const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function NuestrosMasteres() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({});

    function handleUserLoggedIn(user){
      setCurrentUser(user)
      console.log("logged in")
  }
  
  function handleUserNotLoggedIn(user){
      console.log("Not logged in")
  }
  
  function handleUserNotRegistered(user){

      console.log("Not registered")
  }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
            <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}
         
      <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
         
          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                    <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>

           







<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
               Nuestros másteres


                   </Typography>

       </Grid>
                 
                  </Item>
          </Grid>
          </Box>
 





 
          <Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >
         
         <Grid xs={12} sx={{}}>
           <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
           <Grid container>
 
                     <Grid item xs={12} sm={12} md={12}>
                       <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
  
  <Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
  Law School | Derecho de los negocios


      </Typography>
  <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
  Formación especializada diseñada para el éxito en la abogacía de élite


      </Typography>
     
  </Stack>
                       </Grid>
                       
    
                   </Grid>
           
       







                
                 </Item>
         </Grid>
         </Box> 
          













         <Box sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >

         <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
       
                        
       
 
  

     <Item sx={{marginRight: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "390px", maxHeight: "600px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Derecho Procesal Civil
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
           Defiende a empresas multinacionales en sus litigios más complejos
   
   
               </Typography>
               <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Saber más
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
           Matrícula abierta
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
 
       <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Fiscalidad de las Empresas
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
           Asesora a grandes multinacionales en sus cuestiones fiscales clave
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 2 septiembre 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
  
 
 
     <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Derecho Concursal
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
           Asesora a deudores y acreedores en escenarios de insolvencia
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 3 junio 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
  
   
 
                         </Stack>
                
                         <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
  

     <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> Tech Law
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
           Domina el derecho de las nuevas tecnologías y asesora a las BigTech
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 2 septiembre 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
  
   
 
     <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
     <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
    
     
           <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
           Máster en <br/> White Collar Law
   
   
               </Typography>
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
           Conviértete en un abogado penalista experto especializado en delitos económicos
   
   
               </Typography>
               <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
       bgcolor: '#6b81b5', // theme.palette.primary.main
       color: 'white',
     }}}>
                 Próximamente
               </Button>
               
               
           </Stack>
 
           <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
           Nuevas plazas limitadas: <br/> 2 septiembre 2024
   
   
               </Typography>
   
   
   
   
       
   
     { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
       <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
           <CardContent>
           <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
               Tus notas
           </Typography>
           </CardContent>
       </Card>
       </Stack>*/}
       </Item>
 
   
  
 
 
                         </Stack>
    
  
  
          <Grid container spacing={2} sx={{height: '100%'}}>
       
           
            
          </Grid>

          <Grid xs={12} sx={{bgcolor: "#4a5b82", paddingBottom: "30px", paddingTop: "50px", marginTop: "100px"}}>


<Typography variant="h3" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
        Un método de aprendizaje diseñado por abogados <br></br> de los principales despachos del mundo. 


            </Typography>
            <Grid container spacing={2} sx={{height: '100%', paddingBottom: "100px"}}>
            <Grid xs={2}>
           
            </Grid>
            <Grid xs={4}>
            <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Todos los másteres de Univerlaw están diseñados conjuntamente con abogados senior, counsels o socios de los principales despachos de abogados del mundo, así como con abogados in-house de grandes empresas. Conjuntamente con estos reputados profesionales
           recopilamos, en un solo curso exigente y altamente especializado, todos los conocimientos teóricos y prácticos necesarios para triunfar en cada área de la abogacía de los negocios.



               </Typography>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           
           En los másteres de Univerlaw adquirirás los conocimientos que los abogados de los principales despachos buscan en los candidatos que seleccionan para unirse a sus despachos. Adquirirás los conocimientos que los directores de asesoría jurídica requieren 
           a los abogados externos a los que encomiendan sus asuntos más sensibles. En definitiva, te proporcionaremos todas las herramientas necesarias para convertirte en un auténtico experto en el área de práctica.



               </Typography>
            </Grid>
            <Grid xs={4}>
            <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           Aprenderás de forma 100% online, a través de una plataforma diseñada expresamente para la formación en el derecho de los negocios. Puedes formarte de forma intensiva, si el resto de tus obligaciones te lo permite, o de manera más pausada, dedicando solo algunas horas semanales.
               </Typography>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           La formación es eminentemente práctica, basada en el principio "learning by doing". Desde el primer día comenzarás a enfrentarte a casos reales que cada vez irás resolviendo con mayor pericia.
           Te enfrentarás a los mismos retos a los que se enfrenta un abogado junior / asociado de un gran despacho de abogados, pero centrándote en aquellas cuestiones que verdaderamente te permitirán evolucionar. 
               </Typography>

               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           El objetivo es tan simple como ambicioso: convertirte en un auténtico experto en este área de práctica.
               </Typography>
               
            </Grid>
            <Grid xs={2}>
            </Grid>
            </Grid>

           

</Grid>


<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "0px"}}>
       
          

       <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "95px", paddingBottom: "55px", textAlign: "center"}}>
       El día después del Máster


                   </Typography>


                   <Grid xs={12} sx={{paddingBottom: "30px"}}>



            <Grid container sx={{height: '100%', paddingBottom: "100px"}}>
            <Grid xs={3}>
           
            </Grid>
            <Grid xs={6}>
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "justify"}}>
           La mayoría de los estudiantes de Univerlaw deciden realizar nuestros másteres con una meta profesional clara. Esta meta profesional puede ser consistir en conseguir un primer trabajo en alguno de los despachos de abogados de mayor prestigio,
           conseguir un cambio a un despacho con mejores condiciones, cambiar de área de práctica, o simplemente adquirir conocimientos a nivel de experto que ayuden en (y aceleren) el avance en la carrera profesional dentro del actual despacho.

           En Univerlaw no solo te permitiremos adquirir los conocimientos necesarios para esos propósitos, sino que además ponemos a tu disposición todas las herramientas adicionales necesarias para conseguir tu objetivo.  



               </Typography>





             
               
            </Grid>
           
            <Grid xs={3}>
            </Grid>
            </Grid>

            <Grid container spacing={8} sx={{height: '100%', paddingBottom: "100px"}}>
  
  <Grid xs={3}>

</Grid>

<Grid xs={3}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Portal privado <br></br> de empleo


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos nuestros másteres incluyen un año de acceso gratuito a Jobs&Law, nuestro portal privado de empleo, en el que podrás encontrar todas las vacantes profesionales de calidad
     abiertas en el mercado.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>

 <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginTop: "60px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Recursos para superar <br></br> los procesos de selección


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos nuestros másteres incluyen una sección voluntaria, diseñada por reclutadores de los principales despachos de abogados, en la te daremos todas las claves para superar los procesos de selección más exigentes.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>


 
</Grid>

<Grid xs={3}>
<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Perfil profesional <br></br> preferente, para siempre


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos los alumnos de Univerlaw tienen la posibilidad de contar con un perfil profesional preferente en Jobs&Law, que les permitirá ser encontrados de forma sencilla y confidencial por reclutadores.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>

 <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", marginTop: "60px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Comunidad <br></br> Univerlaw


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Tras completar el máster formarás parte de la Comunidad Univerlaw, conformada por profesores -abogados senior, counsel y socios de despachos de reconocido prestigio-, reclutadores y alumni.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>


     </Stack>


 </Item>

</Grid>




<Grid xs={3}>

</Grid>
</Grid>        

</Grid> 



 
</Grid>

</Box>











<Box sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >


     
<Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "65px", paddingBottom: "50px", textAlign: "center"}}>
              Nuestros másteres


                  </Typography>
       
     
   
  
 
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Proximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

  
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Proximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Proximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

      <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Proximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>


                
  
    
  
  

      
      
<Stack sx={{bgcolor: "#4a5b82", paddingBottom: "30px", paddingTop: "50px", marginTop: "100px"}}>
<Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "20px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
        Un método de aprendizaje diseñado por abogados <br></br> de los principales despachos del mundo. 


            </Typography>
            <Typography sx={{color: "white", fontFamily: "Lato", fontSize: "16px", fontWeight: "400", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
           Todos los másteres de Univerlaw están diseñados conjuntamente con abogados senior, counsels o socios de los principales despachos de abogados del mundo, así como con abogados in-house de grandes empresas. Conjuntamente con estos reputados profesionales
           recopilamos, en un solo curso exigente y altamente especializado, todos los conocimientos teóricos y prácticos necesarios para triunfar en cada área de la abogacía de los negocios.



               </Typography>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", fontSize: "16px", paddingTop: "10px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
           
           En los másteres de Univerlaw adquirirás los conocimientos que los abogados de los principales despachos buscan en los candidatos que seleccionan para unirse a sus despachos. Adquirirás los conocimientos que los directores de asesoría jurídica requieren 
           a los abogados externos a los que encomiendan sus asuntos más sensibles. En definitiva, te proporcionaremos todas las herramientas necesarias para convertirte en un auténtico experto en el área de práctica.



               </Typography>
         
        
            <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", fontSize: "16px", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
           Aprenderás de forma 100% online, a través de una plataforma diseñada expresamente para la formación en el derecho de los negocios. Puedes formarte de forma intensiva, si el resto de tus obligaciones te lo permite, o de manera más pausada, dedicando solo algunas horas semanales.
               </Typography>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", fontSize: "16px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
           La formación es eminentemente práctica, basada en el principio "learning by doing". Desde el primer día comenzarás a enfrentarte a casos reales que cada vez irás resolviendo con mayor pericia.
           Te enfrentarás a los mismos retos a los que se enfrenta un abogado junior / asociado de un gran despacho de abogados, pero centrándote en aquellas cuestiones que verdaderamente te permitirán evolucionar. 
               </Typography>

               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", fontSize: "16px", paddingTop: "10px", paddingBottom: "40px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
           El objetivo es tan simple como ambicioso: convertirte en un auténtico experto en este área de práctica.
               </Typography>
               
          
               </Stack> 

           



<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "0px"}}>
       
          

       <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingRight: "25px", paddingLeft: "25px", paddingTop: "55px", paddingBottom: "15px", textAlign: "center"}}>
       El día después del Máster


                   </Typography>


                   <Grid sx={{paddingBottom: "30px"}}>



            <Grid container sx={{height: '100%', paddingBottom: "50px"}}>
       
         
            <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "0px", paddingLeft: "20px", paddingRight: "20px", textAlign: "center"}}>
           La mayoría de los estudiantes de Univerlaw deciden realizar nuestros másteres con una meta profesional clara. Esta meta profesional puede ser consistir en conseguir un primer trabajo en alguno de los despachos de abogados de mayor prestigio,
           conseguir un cambio a un despacho con mejores condiciones, cambiar de área de práctica, o simplemente adquirir conocimientos a nivel de experto que ayuden en (y aceleren) el avance en la carrera profesional dentro del actual despacho.

           En Univerlaw no solo te permitiremos adquirir los conocimientos necesarios para esos propósitos, sino que además ponemos a tu disposición todas las herramientas adicionales necesarias para conseguir tu objetivo.  



               </Typography>





             
               
        
     
            </Grid>

            <Grid container sx={{height: '100%', paddingBottom: "100px"}}>
  



<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", margin: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Portal privado <br></br> de empleo


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos nuestros másteres incluyen un año de acceso gratuito a Jobs&Law, nuestro portal privado de empleo, en el que podrás encontrar todas las vacantes profesionales de calidad
     abiertas en el mercado.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>

 <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", margin: "20px", paddingBottom: "20px", justifyContent: "center", marginTop: "30px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Recursos para superar <br></br> los procesos de selección


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos nuestros másteres incluyen una sección voluntaria, diseñada por reclutadores de los principales despachos de abogados, en la te daremos todas las claves para superar los procesos de selección más exigentes.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>


 



<Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", margin: "20px", paddingBottom: "20px", justifyContent: "center"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Perfil profesional <br></br> preferente, para siempre


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Todos los alumnos de Univerlaw tienen la posibilidad de contar con un perfil profesional preferente en Jobs&Law, que les permitirá ser encontrados de forma sencilla y confidencial por reclutadores.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>
     </Stack>


 </Item>

 <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", marginTop: "30px"}}>
<Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>

<Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "25px"}}>
     Comunidad <br></br> Univerlaw


         </Typography>
         <Divider sx={{bgcolor: "white", }}></Divider>
     <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px"}}>
     Tras completar el máster formarás parte de la Comunidad Univerlaw, conformada por profesores -abogados senior, counsel y socios de despachos de reconocido prestigio-, reclutadores y alumni.


         </Typography>
         <Box sx={{width: "100px", margin: 'auto'}} >

</Box>


     </Stack>


 </Item>







</Grid>        

</Grid> 



 
</Grid>

</Box>





<Footer/>





        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default NuestrosMasteres