import React from 'react'
import { useParams } from "react-router-dom";
import { getPaginas, updateAlumno } from '../firebase/firebase';
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, query, where, orderBy, getDoc, getDocs  } from 'firebase/firestore';
import { useState, useEffect, useMemo, useRef } from 'react';
import AuthProvider from '../components/authProvider'
import Pagina from '../components/Pagina';
import { v4 as uuid } from 'uuid';
import { insertNewPagina, updatePagina } from '../firebase/firebase';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import parse from 'html-react-parser';
import { CssBaseline, Divider, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../customCKEditor.css";
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import { useNavigate } from 'react-router';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Image from 'mui-image';
import Link from '@mui/material/Link';
import { getAlumnos } from '../firebase/firebase';
import Alumno from '../components/Alumno';
import { Viewer, PageChangeEvent } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {ref, listAll, getDownloadURL} from 'firebase/storage';
import { storage } from '../firebase/firebase';
import JoditEditor from 'jodit-react';
import FormHelperText from '@mui/material/FormHelperText';


function Test({docId, master, status, paginar, diaposCompletadas, respuestas, activeStep, numSteps, step2, thisIndex, ordered, tipoDiapo, pregunta1TestIntro, res1Pre1, res2Pre1, res3Pre1, res4Pre1, 
  pregunta2TestIntro, res1Pre2, res2Pre2, res3Pre2, res4Pre2,pregunta3TestIntro, res1Pre3, res2Pre3, res3Pre3, res4Pre3, pregunta4TestIntro, res1Pre4, res2Pre4, res3Pre4, res4Pre4, 
  pregunta5TestIntro, res1Pre5, res2Pre5, res3Pre5, res4Pre5, pregunta6TestIntro, res1Pre6, res2Pre6, res3Pre6, res4Pre6, pregunta7TestIntro, res1Pre7, res2Pre7, res3Pre7, res4Pre7, 
  pregunta8TestIntro, res1Pre8, res2Pre8, res3Pre8, res4Pre8, pregunta9TestIntro, res1Pre9, res2Pre9, res3Pre9, res4Pre9, pregunta10TestIntro, res1Pre10, res2Pre10, res3Pre10, res4Pre10,
  resPre1, resPre2, resPre3, resPre4, resPre5, resPre6, resPre7, resPre8, resPre9, resPre10, introTestIntro, onDelete, onUpdate}) {
    const [currentUser, setCurrentUser] = useState({})
    const [paginas, setPaginas] = useState([])
    
  
    const [step1, setStep1] = useState("")

    const [step3, setStep3] = useState("")
    const steps = [];
    const [texto, setTexto] = useState("")
    const [texto2, setTexto2] = useState("")
    const [texto3, setTexto3] = useState("")
    const [curso, setCurso] = useState("procesal")

    const [tema, setTema] = useState()
    const [nombreTema, setNombreTema] = useState("")
    const [nombreDiapo, setNombreDiapo] = useState("")
    const [capitulo, setCapitulo] = useState()
    const [textoAlumno, setTextoAlumno] = useState("")
    const [respuestasChecked, setRespuestasChecked] = useState(false)
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Acertaste');
    const [helperText2, setHelperText2] = React.useState('Fallaste');

    const [numId, setNumId] = useState()

    



    const [currentResPre1, setCurrentResPre1] = useState("")
    const [currentResPre2, setCurrentResPre2] = useState("")
    const [currentResPre3, setCurrentResPre3] = useState("")
    const [currentResPre4, setCurrentResPre4] = useState("")
    const [currentResPre5, setCurrentResPre5] = useState("")
    const [currentResPre6, setCurrentResPre6] = useState("")
    const [currentResPre7, setCurrentResPre7] = useState("")
    const [currentResPre8, setCurrentResPre8] = useState("")
    const [currentResPre9, setCurrentResPre9] = useState("")
    const [currentResPre10, setCurrentResPre10] = useState("")


  
    const [documentoPrincipal, setDocumentoPrincipal] = useState("")
    
    const [documento2Principal, setDocumento2Principal] = useState("")
    const [documento3Principal, setDocumento3Principal] = useState("")
    const [documento4Principal, setDocumento4Principal] = useState("")
    const [documento5Principal, setDocumento5Principal] = useState("")
    const [nombredoc1, setnombredoc1] = useState("")
    const [nombredoc2, setnombredoc2] = useState("")
    const [nombredoc3, setnombredoc3] = useState("")
    const [nombredoc4, setnombredoc4] = useState("")
    const [nombredoc5, setnombredoc5] = useState("")

    const [currentDoc, setCurrentDoc] = useState(1)

    const [status2, setCurrentStatus2] = useState(status)
    const [currentDiaposCompletadas, setCurrentDiaposCompletadas] = useState(diaposCompletadas)
    const [currentRespuestas, setCurrentRespuestas] = useState({})
    const [currentResAlumnoPre4, setCurrentResAlumnoPre4] = useState("")




    const [pags, setPags] = useState([]);
    const [title, setTitle] = useState("")
    const [programa, setPrograma] = useState("Holita")
    const [apuntes, setApuntes] = useState(false)
    const [dudas, setDudas] = useState(false)
    const [alumnos, setAlumnos] = useState([])

    const [docList, setDocList] = useState([])


    const [testYaCompletado, setTestYaCompletado] = useState(false)

    const docListRef = ref(storage, "documents/")

 
  const navigate = useNavigate();



console.log(currentDiaposCompletadas)

console.log(ordered)



    const nextSlide = () => {
    
      console.log(thisIndex)
      const nextIndex = thisIndex+1
      const chosenOne =  ordered[nextIndex]
      console.log(nextIndex)
      console.log(chosenOne)
      console.log(chosenOne.docId)
      console.log(currentDiaposCompletadas)
   
    
      navigate(`../masterderechoprocesal/${chosenOne.docId}`);
      window.location.replace('');
    }


    
const handleChangeResPre1 = (event) => {
    setCurrentResPre1(event.target.value);
  console.log(currentResPre1)
  };
  
  const handleChangeEditor = (event) => {
    setTextoAlumno(event.target.value);
    console.log(textoAlumno)
  
  };
  
  const handleChangeResPre2 = (event) => {
    setCurrentResPre2(event.target.value);
  
  };
  
  const handleChangeResPre3 = (event) => {
    setCurrentResPre3(event.target.value);
  
  };
  
  const handleChangeResPre4 = (event) => {
    setCurrentResPre4(event.target.value);
  console.log(resPre4)
  };
  
 
  
  const handleChangeResPre5 = (event) => {
    setCurrentResPre5(event.target.value);
  
  };
  
  const handleChangeResPre6 = (event) => {
    setCurrentResPre6(event.target.value);
  
  };
  
  const handleChangeResPre7 = (event) => {
    setCurrentResPre7(event.target.value);
  
  };
  
  const handleChangeResPre8 = (event) => {
    setCurrentResPre8(event.target.value);
  
  };
  
  const handleChangeResPre9 = (event) => {
    setCurrentResPre9(event.target.value);
  
  };
  
  const handleChangeResPre10 = (event) => {
    setCurrentResPre10(event.target.value);
  
  };

  async function handleUpdate2 (docId2, 
    master,
    status,
    diaposCompletadas,
    respuestas,
  
   
  ){
    
    const alumno = alumnos.find((item) => item.docId === docId2);
    alumno.master = master;
    alumno.status = status;
    alumno.diaposCompletadas = diaposCompletadas;
    alumno.respuestas = respuestas;

  
  
    await updateAlumno(docId2, alumno)
    console.log(alumno, docId2, master, diaposCompletadas, respuestas)
  }


  useEffect(() => {
    setCurrentDiaposCompletadas([...diaposCompletadas, paginar])

    setCurrentRespuestas([...respuestas, paginar + "res1" + currentResPre1, paginar + "res2" + currentResPre2, paginar + "res3" + currentResPre3, paginar + "res4" + currentResPre4, paginar + "res5" + currentResPre5, paginar + "res6" + currentResPre6
    , paginar + "res7" + currentResPre7, paginar + "res8" + currentResPre8, paginar + "res9" + currentResPre9, paginar + "res10" + currentResPre10])
  
  
  }, [currentResPre1, currentResPre2, currentResPre3, currentResPre4, currentResPre5, currentResPre6, currentResPre7, currentResPre8, currentResPre9, currentResPre10])


  useEffect(() => {
    if (respuestas.some (e => e.includes(paginar + "res1" || paginar + "res2")))  {
      setTestYaCompletado(true)
    } else {
      setTestYaCompletado(false)
    }
  })
  
      function handleUpdateLink () {
        
 
        setTimeout(() => {
          nextSlide();
        }, 500);
        
      }

      function handleRespuestas () {
  
        onUpdate(docId, master, status2, currentDiaposCompletadas, currentRespuestas)
 
       setRespuestasChecked(true);
        console.log(respuestasChecked)
      }

      function handleRespuestasBis () {
  
       setRespuestasChecked(true);
        console.log(respuestasChecked)
      }


  return (
    <div>

                
{((tipoDiapo === "testintroductorio") && respuestasChecked === false ) && <Stack> 
             
             <Stack sx= {{minHeight: "78vh",bgcolor: "#4a5b82", marginTop: "0px", paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px"}}>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", paddingTop: "0px", paddingBottom: "20px", textAlign: "justified" }}>
                 {parse(introTestIntro)} 
               </Typography>
               {introTestIntro !== "" && <Divider sx={{bgcolor: "white", marginBottom: "20px"}}></Divider>}
               {pregunta1TestIntro !== "" && <div> 
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "0px", textAlign: "justified", margingTop: "20px" }}>
                1. {parse(pregunta1TestIntro)}
               </Typography></div>}
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre1}
       value={currentResPre1}
       onChange={handleChangeResPre1}
     >
       {res1Pre1 !== "" && <FormControlLabel value={res1Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre1} sx={{paddingRight: "20px"}} />}
       {res2Pre1 !== "" && <FormControlLabel value={res2Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre1} sx={{paddingRight: "20px"}}/>}
       {res3Pre1 !== "" && <FormControlLabel value={res3Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre1} sx={{paddingRight: "20px"}}/>}
       {res4Pre1 !== "" && <FormControlLabel value={res4Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre1}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl>



   {pregunta2TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                2. {parse(pregunta2TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       value={currentResPre2}
       onChange={handleChangeResPre2}
     >
       {res1Pre2 !== "" && <FormControlLabel value={res1Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre2} sx={{paddingRight: "20px"}} />}
       {res2Pre2 !== "" && <FormControlLabel value={res2Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre2} sx={{paddingRight: "20px"}}/>}
       {res3Pre2 !== "" && <FormControlLabel value={res3Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre2} sx={{paddingRight: "20px"}}/>}
       {res4Pre2 !== "" && <FormControlLabel value={res4Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre2}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}




   {pregunta3TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                3. {parse(pregunta3TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre3}
       value={currentResPre3}
       onChange={handleChangeResPre3}
     
     >
       {res1Pre3 !== "" && <FormControlLabel value={res1Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre3} sx={{paddingRight: "20px"}} />}
       {res2Pre3 !== "" && <FormControlLabel value={res2Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre3} sx={{paddingRight: "20px"}}/>}
       {res3Pre3 !== "" && <FormControlLabel value={res3Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre3} sx={{paddingRight: "20px"}}/>}
       {res4Pre3 !== "" && <FormControlLabel value={res4Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre3}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}



   {pregunta4TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                4. {parse(pregunta4TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre4}
       value={currentResPre4}
       onChange={handleChangeResPre4}
     >
       {res1Pre4 !== "" && <FormControlLabel value={res1Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre4} sx={{paddingRight: "20px"}} />}
       {res2Pre4 !== "" && <FormControlLabel value={res2Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre4} sx={{paddingRight: "20px"}}/>}
       {res3Pre4 !== "" && <FormControlLabel value={res3Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre4} sx={{paddingRight: "20px"}}/>}
       {res4Pre4 !== "" && <FormControlLabel value={res4Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre4}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta5TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                5. {parse(pregunta5TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre5}
       value={currentResPre5}
       onChange={handleChangeResPre5}
     >
       {res1Pre5 !== "" && <FormControlLabel value={res1Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre5} sx={{paddingRight: "20px"}} />}
       {res2Pre5 !== "" && <FormControlLabel value={res2Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre5} sx={{paddingRight: "20px"}}/>}
       {res3Pre5 !== "" && <FormControlLabel value={res3Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre5} sx={{paddingRight: "20px"}}/>}
       {res4Pre5 !== "" && <FormControlLabel value={res4Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre5}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta6TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
               6. {parse(pregunta6TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       value={currentResPre6}
       defaultValue={resPre6}
       onChange={handleChangeResPre6}
       
     >
       {res1Pre6 !== "" && <FormControlLabel value={res1Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre6} sx={{paddingRight: "20px"}} />}
       {res2Pre6 !== "" && <FormControlLabel value={res2Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre6} sx={{paddingRight: "20px"}}/>}
       {res3Pre6 !== "" && <FormControlLabel value={res3Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre6} sx={{paddingRight: "20px"}}/>}
       {res4Pre6 !== "" && <FormControlLabel value={res4Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre6}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta7TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                7. {parse(pregunta7TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre7}
       value={currentResPre7}
       onChange={handleChangeResPre7}
     >
       {res1Pre7 !== "" && <FormControlLabel value={res1Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre7} sx={{paddingRight: "20px"}} />}
       {res2Pre7 !== "" && <FormControlLabel value={res2Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre7} sx={{paddingRight: "20px"}}/>}
       {res3Pre7 !== "" && <FormControlLabel value={res3Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre7} sx={{paddingRight: "20px"}}/>}
       {res4Pre7 !== "" && <FormControlLabel value={res4Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre7}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta8TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                8. {parse(pregunta8TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre8}
       value={currentResPre8}
       onChange={handleChangeResPre8}
     >
       {res1Pre8 !== "" && <FormControlLabel value={res1Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre8} sx={{paddingRight: "20px"}} />}
       {res2Pre8 !== "" && <FormControlLabel value={res2Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre8} sx={{paddingRight: "20px"}}/>}
       {res3Pre8 !== "" && <FormControlLabel value={res3Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre8} sx={{paddingRight: "20px"}}/>}
       {res4Pre8 !== "" && <FormControlLabel value={res4Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre8}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta9TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                9. {parse(pregunta9TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre9}
       value={currentResPre9}
       onChange={handleChangeResPre9}
     >
       {res1Pre9 !== "" && <FormControlLabel value={res1Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre9} sx={{paddingRight: "20px"}} />}
       {res2Pre9 !== "" && <FormControlLabel value={res2Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre9} sx={{paddingRight: "20px"}}/>}
       {res3Pre9 !== "" && <FormControlLabel value={res3Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre9} sx={{paddingRight: "20px"}}/>}
       {res4Pre9 !== "" && <FormControlLabel value={res4Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre9}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta10TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                10. {parse(pregunta5TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre10}
       value={currentResPre10}
       onChange={handleChangeResPre10}
     >
       {res1Pre10 !== "" && <FormControlLabel value={res1Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre10} sx={{paddingRight: "20px"}} />}
       {res2Pre10 !== "" && <FormControlLabel value={res2Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre10} sx={{paddingRight: "20px"}}/>}
       {res3Pre10 !== "" && <FormControlLabel value={res3Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre10} sx={{paddingRight: "20px"}}/>}
       {res4Pre10 !== "" && <FormControlLabel value={res4Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre10}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}
       <Stack sx={{paddingBottom: "20px", alignItems: "flex-end", paddingRight: "50px"}}>
       {(alumnos.map((alumno) => (
  <Test key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
   </Stack>


  </Stack>
       
         
         

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "casopractico" && step2 !== "" && tipoDiapo !== "testintroductorio" && <Button onClick={handleUpdateLink} sx={{color: "white" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio" && tipoDiapo !== "casopractico" && step2 === "" && <Button onClick={handleUpdateLink} sx={{color: "white" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "teoria" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio"  && step2 === "" && <Button onClick={handleUpdateLink} variant='contained' sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px", borderColor: "#d5dbe8", bgcolor:"#d5dbe8"}}>
                      
                      Presentar caso práctico
                  </Button>
                  
                  }

                  

                  {(tipoDiapo === "portada" || tipoDiapo === "portadatema" || tipoDiapo === "portadacapitulo" ||  tipoDiapo === "testintroductorio" || tipoDiapo === "respuestaguiada" || tipoDiapo === "preposttest") && 

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                
                <Box sx={{ flex: '1 1 auto' }} />
                
                {(respuestasChecked === false && testYaCompletado !== true) && <Button onClick={handleRespuestas} sx={{color: "white"}}>Enviar respuestas</Button>}
                <Stack direction="row" >{(respuestasChecked === true || testYaCompletado === true) && <div> {respuestasChecked !== true && <div> <Typography sx={{color: "#4a5b82",bgcolor: "white", paddingLeft: "10px", paddingBottom: "10px", paddingTop: "10px", paddingRight: "10px", borderRadius: "15px", marginBottom: "15px"}}> 
                Ya has realizado este test, tus nuevas respuestas no se guardarán</Typography> </div> }
                {respuestasChecked !== true && <Button onClick={handleRespuestasBis} sx={{color: "white"}}>Comprobar respuestas</Button>}
                <Button onClick={handleUpdateLink} sx={{color: "white"}}>Siguiente</Button></div>}
                </Stack>
              </Box> }
     
            </Stack>}


            {((tipoDiapo === "testintroductorio") && respuestasChecked === true ) && <Stack>
             
             <Stack sx= {{minHeight: "79vh",bgcolor: "#4a5b82", marginTop: "0px", paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px"}}>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", paddingTop: "0px", paddingBottom: "20px", textAlign: "justified" }}>
                 {parse(introTestIntro)}
               </Typography>
               {introTestIntro !== "" && <Divider sx={{bgcolor: "white", marginBottom: "20px"}}></Divider>}
               {pregunta1TestIntro !== "" && <div> 
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "0px", textAlign: "justified", margingTop: "20px" }}>
                1. {parse(pregunta1TestIntro)}
               </Typography></div>}
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre1}
      
     >
       {(res1Pre1 !== "" && res1Pre1 === resPre1) && <FormControlLabel value={res1Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre1} sx={{paddingRight: "20px"}} /> }


{(res1Pre1 !== "" && res1Pre1 !== resPre1) && <FormControlLabel value={res1Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre1} sx={{paddingRight: "20px"}} /> }


{(res2Pre1 !== "" && res2Pre1 === resPre1) && <FormControlLabel value={res2Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre1} sx={{paddingRight: "20px"}} /> }


{(res2Pre1 !== "" && res2Pre1 !== resPre1) && <FormControlLabel value={res2Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre1} sx={{paddingRight: "20px"}} /> }


{(res3Pre1 !== "" && res3Pre1 === resPre1) && <FormControlLabel value={res3Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre1} sx={{paddingRight: "20px"}} /> }


{(res3Pre1 !== "" && res3Pre1 !== resPre1) && <FormControlLabel value={res3Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre1} sx={{paddingRight: "20px"}} /> }


{(res4Pre1 !== "" && res4Pre1 === resPre1) && <FormControlLabel value={res4Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre1} sx={{paddingRight: "20px"}} /> }

{(res4Pre1 !== "" && res4Pre1 !== resPre1) && <FormControlLabel value={res4Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre1} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre1 === currentResPre1 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>



   {pregunta2TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                2. {parse(pregunta2TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre2}
      
     >
       {(res1Pre2 !== "" && res1Pre2 === resPre2) && <FormControlLabel value={res1Pre2} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre2} sx={{paddingRight: "20px"}} /> }


{(res1Pre2 !== "" && res1Pre2 !== resPre2) && <FormControlLabel value={res1Pre2} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre2} sx={{paddingRight: "20px"}} /> }


{(res2Pre2 !== "" && res2Pre2 === resPre2) && <FormControlLabel value={res2Pre2} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre2} sx={{paddingRight: "20px"}} /> }


{(res2Pre2 !== "" && res2Pre2 !== resPre2) && <FormControlLabel value={res2Pre2} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre2} sx={{paddingRight: "20px"}} /> }


{(res3Pre2 !== "" && res3Pre2 === resPre2) && <FormControlLabel value={res3Pre2} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre2} sx={{paddingRight: "20px"}} /> }


{(res3Pre2 !== "" && res3Pre2 !== resPre2) && <FormControlLabel value={res3Pre2} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre2} sx={{paddingRight: "20px"}} /> }


{(res4Pre2 !== "" && res4Pre2 === resPre2) && <FormControlLabel value={res4Pre2} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre1} sx={{paddingRight: "20px"}} /> }

{(res4Pre2 !== "" && res4Pre2 !== resPre2) && <FormControlLabel value={res4Pre2} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre2} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre2 === currentResPre2 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
               </div>}




   {pregunta3TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                3. {parse(pregunta3TestIntro)}
               </Typography>
               
               
               
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre3}
      
     >
       {(res1Pre3 !== "" && res1Pre3 === resPre3) && <FormControlLabel value={res1Pre3} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre3} sx={{paddingRight: "20px"}} /> }


{(res1Pre3 !== "" && res1Pre3 !== resPre3) && <FormControlLabel value={res1Pre3} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre3} sx={{paddingRight: "20px"}} /> }


{(res2Pre3 !== "" && res2Pre3 === resPre3) && <FormControlLabel value={res2Pre3} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre3} sx={{paddingRight: "20px"}} /> }


{(res2Pre3 !== "" && res2Pre3 !== resPre3) && <FormControlLabel value={res2Pre3} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre3} sx={{paddingRight: "20px"}} /> }


{(res3Pre3 !== "" && res3Pre3 === resPre3) && <FormControlLabel value={res3Pre3} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre3} sx={{paddingRight: "20px"}} /> }


{(res3Pre3 !== "" && res3Pre3 !== resPre3) && <FormControlLabel value={res3Pre3} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre3} sx={{paddingRight: "20px"}} /> }


{(res4Pre3 !== "" && res4Pre3 === resPre3) && <FormControlLabel value={res4Pre3} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre3} sx={{paddingRight: "20px"}} /> }

{(res4Pre3 !== "" && res4Pre3 !== resPre3) && <FormControlLabel value={res4Pre3} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre3} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre3 === currentResPre3 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   
   </div>}



   {pregunta4TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                4. {parse(pregunta4TestIntro)}
               </Typography>


               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre4}
      
     >
       {(res1Pre4 !== "" && res1Pre4 === resPre4) && <FormControlLabel value={res1Pre4} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre4} sx={{paddingRight: "20px"}} /> }


{(res1Pre4 !== "" && res1Pre4 !== resPre4) && <FormControlLabel value={res1Pre4} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre4} sx={{paddingRight: "20px"}} /> }


{(res2Pre4 !== "" && res2Pre4 === resPre4) && <FormControlLabel value={res2Pre4} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre4} sx={{paddingRight: "20px"}} /> }


{(res2Pre4 !== "" && res2Pre4 !== resPre4) && <FormControlLabel value={res2Pre4} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre4} sx={{paddingRight: "20px"}} /> }


{(res3Pre4 !== "" && res3Pre4 === resPre4) && <FormControlLabel value={res3Pre4} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre4} sx={{paddingRight: "20px"}} /> }


{(res3Pre4 !== "" && res3Pre4 !== resPre4) && <FormControlLabel value={res3Pre4} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre4} sx={{paddingRight: "20px"}} /> }


{(res4Pre4 !== "" && res4Pre4 === resPre4) && <FormControlLabel value={res4Pre4} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre4} sx={{paddingRight: "20px"}} /> }

{(res4Pre4 !== "" && res4Pre4 !== resPre4) && <FormControlLabel value={res4Pre4} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre4} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre4 === currentResPre4 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}


   {pregunta5TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                5. {parse(pregunta5TestIntro)}
               </Typography>


               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre5}
      
     >
       {(res1Pre5 !== "" && res1Pre5 === resPre5) && <FormControlLabel value={res1Pre5} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre5} sx={{paddingRight: "20px"}} /> }


{(res1Pre5 !== "" && res1Pre5 !== resPre5) && <FormControlLabel value={res1Pre5} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre5} sx={{paddingRight: "20px"}} /> }


{(res2Pre5 !== "" && res2Pre5 === resPre5) && <FormControlLabel value={res2Pre5} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre5} sx={{paddingRight: "20px"}} /> }


{(res2Pre5 !== "" && res2Pre5 !== resPre5) && <FormControlLabel value={res2Pre5} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre1} sx={{paddingRight: "20px"}} /> }


{(res3Pre5 !== "" && res3Pre5 === resPre5) && <FormControlLabel value={res3Pre5} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre5} sx={{paddingRight: "20px"}} /> }


{(res3Pre5 !== "" && res3Pre5 !== resPre5) && <FormControlLabel value={res3Pre5} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre5} sx={{paddingRight: "20px"}} /> }


{(res4Pre5 !== "" && res4Pre5 === resPre5) && <FormControlLabel value={res4Pre5} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre5} sx={{paddingRight: "20px"}} /> }

{(res4Pre5 !== "" && res4Pre5 !== resPre5) && <FormControlLabel value={res4Pre5} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre5} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre5 === currentResPre5 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}


   {pregunta6TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
               6. {parse(pregunta6TestIntro)}
               </Typography>

               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre6}
      
     >
       {(res1Pre6 !== "" && res1Pre6 === resPre6) && <FormControlLabel value={res1Pre6} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre6} sx={{paddingRight: "20px"}} /> }


{(res1Pre6 !== "" && res1Pre6 !== resPre6) && <FormControlLabel value={res1Pre6} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre6} sx={{paddingRight: "20px"}} /> }


{(res2Pre6 !== "" && res2Pre6 === resPre6) && <FormControlLabel value={res2Pre6} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre6} sx={{paddingRight: "20px"}} /> }


{(res2Pre6 !== "" && res2Pre6 !== resPre6) && <FormControlLabel value={res2Pre6} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre6} sx={{paddingRight: "20px"}} /> }


{(res3Pre6 !== "" && res3Pre6 === resPre6) && <FormControlLabel value={res3Pre6} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre6} sx={{paddingRight: "20px"}} /> }


{(res3Pre6 !== "" && res3Pre6 !== resPre6) && <FormControlLabel value={res3Pre6} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre6} sx={{paddingRight: "20px"}} /> }


{(res4Pre6 !== "" && res4Pre6 === resPre6) && <FormControlLabel value={res4Pre6} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre1} sx={{paddingRight: "20px"}} /> }

{(res4Pre6 !== "" && res4Pre6 !== resPre6) && <FormControlLabel value={res4Pre6} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre6} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre6 === currentResPre6 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}


   {pregunta7TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                7. {parse(pregunta7TestIntro)}
               </Typography>

               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre7}
      
     >
       {(res1Pre7 !== "" && res1Pre7 === resPre7) && <FormControlLabel value={res1Pre7} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre7} sx={{paddingRight: "20px"}} /> }


{(res1Pre7 !== "" && res1Pre7 !== resPre7) && <FormControlLabel value={res1Pre7} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre7} sx={{paddingRight: "20px"}} /> }


{(res2Pre7 !== "" && res2Pre7 === resPre7) && <FormControlLabel value={res2Pre7} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre7} sx={{paddingRight: "20px"}} /> }


{(res2Pre7 !== "" && res2Pre7 !== resPre7) && <FormControlLabel value={res2Pre7} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre7} sx={{paddingRight: "20px"}} /> }


{(res3Pre7 !== "" && res3Pre7 === resPre7) && <FormControlLabel value={res3Pre7} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre7} sx={{paddingRight: "20px"}} /> }


{(res3Pre7 !== "" && res3Pre7 !== resPre7) && <FormControlLabel value={res3Pre7} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre1} sx={{paddingRight: "20px"}} /> }


{(res4Pre7 !== "" && res4Pre7 === resPre7) && <FormControlLabel value={res4Pre7} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre7} sx={{paddingRight: "20px"}} /> }

{(res4Pre7 !== "" && res4Pre7 !== resPre7) && <FormControlLabel value={res4Pre7} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre7} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre7 === currentResPre7 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}


   {pregunta8TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                8. {parse(pregunta8TestIntro)}
               </Typography>


               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre8}
      
     >
       {(res1Pre8 !== "" && res1Pre8 === resPre8) && <FormControlLabel value={res1Pre8} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre8} sx={{paddingRight: "20px"}} /> }


{(res1Pre8 !== "" && res1Pre8 !== resPre8) && <FormControlLabel value={res1Pre8} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre8} sx={{paddingRight: "20px"}} /> }


{(res2Pre8 !== "" && res2Pre8 === resPre8) && <FormControlLabel value={res2Pre8} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre8} sx={{paddingRight: "20px"}} /> }


{(res2Pre8 !== "" && res2Pre8 !== resPre8) && <FormControlLabel value={res2Pre8} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre8} sx={{paddingRight: "20px"}} /> }


{(res3Pre8 !== "" && res3Pre8 === resPre8) && <FormControlLabel value={res3Pre8} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre8} sx={{paddingRight: "20px"}} /> }


{(res3Pre8 !== "" && res3Pre8 !== resPre8) && <FormControlLabel value={res3Pre8} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre8} sx={{paddingRight: "20px"}} /> }


{(res4Pre8 !== "" && res4Pre8 === resPre8) && <FormControlLabel value={res4Pre8} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre8} sx={{paddingRight: "20px"}} /> }

{(res4Pre8 !== "" && res4Pre8 !== resPre8) && <FormControlLabel value={res4Pre8} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre8} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre8 === currentResPre8 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}


   {pregunta9TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                9. {parse(pregunta9TestIntro)}
               </Typography>

               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre9}
      
     >
       {(res1Pre9 !== "" && res1Pre9 === resPre9) && <FormControlLabel value={res1Pre9} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre9} sx={{paddingRight: "20px"}} /> }


{(res1Pre9 !== "" && res1Pre9 !== resPre9) && <FormControlLabel value={res1Pre9} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre9} sx={{paddingRight: "20px"}} /> }


{(res2Pre9 !== "" && res2Pre9 === resPre9) && <FormControlLabel value={res2Pre9} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre9} sx={{paddingRight: "20px"}} /> }


{(res2Pre9 !== "" && res2Pre9 !== resPre9) && <FormControlLabel value={res2Pre9} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre9} sx={{paddingRight: "20px"}} /> }


{(res3Pre9 !== "" && res3Pre9 === resPre9) && <FormControlLabel value={res3Pre9} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre9} sx={{paddingRight: "20px"}} /> }


{(res3Pre9 !== "" && res3Pre9 !== resPre9) && <FormControlLabel value={res3Pre9} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre9} sx={{paddingRight: "20px"}} /> }


{(res4Pre9 !== "" && res4Pre9 === resPre9) && <FormControlLabel value={res4Pre9} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre9} sx={{paddingRight: "20px"}} /> }

{(res4Pre9 !== "" && res4Pre9 !== resPre9) && <FormControlLabel value={res4Pre9} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre9} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre9 === currentResPre9 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}


   {pregunta10TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                10. {parse(pregunta5TestIntro)}
               </Typography>

               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre10}
      
     >
       {(res1Pre10 !== "" && res1Pre10 === resPre10) && <FormControlLabel value={res1Pre10} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre10} sx={{paddingRight: "20px"}} /> }


{(res1Pre10 !== "" && res1Pre10 !== resPre10) && <FormControlLabel value={res1Pre10} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre10} sx={{paddingRight: "20px"}} /> }


{(res2Pre10 !== "" && res2Pre10 === resPre10) && <FormControlLabel value={res2Pre10} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre10} sx={{paddingRight: "20px"}} /> }


{(res2Pre10 !== "" && res2Pre10 !== resPre10) && <FormControlLabel value={res2Pre10} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre10} sx={{paddingRight: "20px"}} /> }


{(res3Pre10 !== "" && res3Pre10 === resPre10) && <FormControlLabel value={res3Pre10} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre10} sx={{paddingRight: "20px"}} /> }


{(res3Pre10 !== "" && res3Pre10 !== resPre10) && <FormControlLabel value={res3Pre10} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre10} sx={{paddingRight: "20px"}} /> }


{(res4Pre10 !== "" && res4Pre10 === resPre10) && <FormControlLabel value={res4Pre10} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre10} sx={{paddingRight: "20px"}} /> }

{(res4Pre10 !== "" && res4Pre10 !== resPre10) && <FormControlLabel value={res4Pre10} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre10} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre10 === currentResPre10 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>
   
   </div>}
       <Stack sx={{paddingBottom: "20px", alignItems: "flex-end", paddingRight: "50px"}}>
       {(alumnos.map((alumno) => (
  <Test key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
   </Stack>


  </Stack>
       
       

  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "casopractico" && step2 !== "" && tipoDiapo !== "testintroductorio" && <Button onClick={handleUpdateLink} sx={{color: "white" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio" && tipoDiapo !== "casopractico" && step2 === "" && <Button onClick={handleUpdateLink} sx={{color: "white" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "teoria" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio"  && step2 === "" && <Button onClick={handleUpdateLink} variant='contained' sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px", borderColor: "#d5dbe8", bgcolor:"#d5dbe8"}}>
                      
                      Presentar caso práctico
                  </Button>
                  
                  }

                  

                  {(tipoDiapo === "portada" || tipoDiapo === "portadatema" || tipoDiapo === "portadacapitulo" ||  tipoDiapo === "testintroductorio" || tipoDiapo === "respuestaguiada" || tipoDiapo === "preposttest") && 

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                
                <Box sx={{ flex: '1 1 auto' }} />
                {(respuestasChecked === false) && 
                
                <Button onClick={handleRespuestas} sx={{color: "white"}}>Enviar respuestas</Button>}
                  {(respuestasChecked === false && testYaCompletado !== true) && <Button onClick={handleRespuestas} sx={{color: "white"}}>Enviar respuestas</Button>}
                  <Stack direction="row" >{(respuestasChecked === true || testYaCompletado === true) && <div> {respuestasChecked !== true && <div> <Typography sx={{color: "#4a5b82",bgcolor: "white", paddingLeft: "10px", paddingBottom: "10px", paddingTop: "10px", paddingRight: "10px", borderRadius: "15px", marginBottom: "15px"}}> 
                Ya has realizado este test, tus nuevas respuestas no se guardarán</Typography> </div> }
                {respuestasChecked !== true && <Button onClick={handleRespuestasBis} sx={{color: "white"}}>Comprobar respuestas</Button>}
                <Button onClick={handleUpdateLink} sx={{color: "white"}}>Siguiente</Button></div>}
                </Stack>
                
              </Box> }
         
         
     
            </Stack>}








            {((tipoDiapo === "respuestaguiada") && respuestasChecked === false ) && <Stack>
             
             <Stack sx= {{minHeight: "78vh",bgcolor: "#4a5b82", marginTop: "0px", paddingTop: "30px", paddingLeft: "10px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px"}}>
              




            <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", paddingTop: "0px", paddingBottom: "20px", textAlign: "justified" }}>
                 {parse(introTestIntro)}
               </Typography>
               {pregunta1TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                {parse(pregunta1TestIntro)}
               </Typography></div>}
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       value={currentResPre1}
       onChange={handleChangeResPre1}
     >
       {res1Pre1 !== "" && <FormControlLabel value={res1Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre1} sx={{paddingRight: "20px"}} />}
       {res2Pre1 !== "" && <FormControlLabel value={res2Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre1} sx={{paddingRight: "20px"}}/>}
       {res3Pre1 !== "" && <FormControlLabel value={res3Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre1} sx={{paddingRight: "20px"}}/>}
       {res4Pre1 !== "" && <FormControlLabel value={res4Pre1} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre1}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl>



 

  </Stack>
       
         
         

  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "casopractico" && step2 !== "" && tipoDiapo !== "testintroductorio" && <Button onClick={handleUpdateLink} sx={{color: "white" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio" && tipoDiapo !== "casopractico" && step2 === "" && <Button onClick={handleUpdateLink} sx={{color: "white" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "teoria" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio"  && step2 === "" && <Button onClick={handleUpdateLink} variant='contained' sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px", borderColor: "#d5dbe8", bgcolor:"#d5dbe8"}}>
                      
                      Presentar caso práctico
                  </Button>
                  
                  }

                  

                  {(tipoDiapo === "portada" || tipoDiapo === "portadatema" || tipoDiapo === "portadacapitulo" ||  tipoDiapo === "testintroductorio" || tipoDiapo === "respuestaguiada" || tipoDiapo === "preposttest") && 

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                
                <Box sx={{ flex: '1 1 auto' }} />
               
                {(respuestasChecked === false && testYaCompletado !== true) && <Button onClick={handleRespuestas} sx={{color: "white"}}>Enviar respuestas</Button>}
                <Stack direction="row" >
                  {(respuestasChecked === true || testYaCompletado === true) && <div> {respuestasChecked !== true && <div> <Typography sx={{color: "#4a5b82",bgcolor: "white", paddingLeft: "10px", paddingBottom: "10px", paddingTop: "10px", paddingRight: "10px", borderRadius: "15px", marginBottom: "15px"}}> 
                Ya has realizado este test, tus nuevas respuestas no se guardarán</Typography> </div> }
                {respuestasChecked !== true && <Button onClick={handleRespuestasBis} sx={{color: "white"}}>Comprobar respuestas</Button>}
                <Button onClick={handleUpdateLink} sx={{color: "white"}}>Siguiente</Button></div>}
                </Stack>
              </Box> }
     
            </Stack>}



            {((tipoDiapo === "respuestaguiada") && respuestasChecked === true ) && <Stack>
             
             <Stack sx= {{minHeight: "79vh",bgcolor: "#4a5b82", marginTop: "0px", paddingTop: "10px", paddingLeft: "30px", paddingRight: "30px", textAlign: "justify", borderRadius: "5px", marginRight: "20px"}}>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", paddingTop: "0px", paddingBottom: "20px", textAlign: "justified" }}>
                 {parse(introTestIntro)}
               </Typography>
         
               {introTestIntro !== "" && <Divider sx={{bgcolor: "white"}}></Divider>}

               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "400", paddingTop: "10px", paddingBottom: "0px", textAlign: "justified" }}>
                 {parse(pregunta1TestIntro)}
               </Typography>
              
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       
       value={currentResPre1}
      
     >
       {(res1Pre1 !== "" && res1Pre1 === resPre1) && <FormControlLabel value={res1Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res1Pre1} sx={{paddingRight: "20px"}} /> }


{(res1Pre1 !== "" && res1Pre1 !== resPre1) && <FormControlLabel value={res1Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res1Pre1} sx={{paddingRight: "20px"}} /> }


{(res2Pre1 !== "" && res2Pre1 === resPre1) && <FormControlLabel value={res2Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res2Pre1} sx={{paddingRight: "20px"}} /> }


{(res2Pre1 !== "" && res2Pre1 !== resPre1) && <FormControlLabel value={res2Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res2Pre1} sx={{paddingRight: "20px"}} /> }


{(res3Pre1 !== "" && res3Pre1 === resPre1) && <FormControlLabel value={res3Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res3Pre1} sx={{paddingRight: "20px"}} /> }


{(res3Pre1 !== "" && res3Pre1 !== resPre1) && <FormControlLabel value={res3Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res3Pre1} sx={{paddingRight: "20px"}} /> }


{(res4Pre1 !== "" && res4Pre1 === resPre1) && <FormControlLabel value={res4Pre1} control={<Radio  sx={{
         color: "green",
         '&.Mui-checked': {
           color: "green",
         }
       }} />} label={res4Pre1} sx={{paddingRight: "20px"}} /> }

{(res4Pre1 !== "" && res4Pre1 !== resPre1) && <FormControlLabel value={res4Pre1} control={<Radio  sx={{
        color: "white",
        '&.Mui-checked': {
          color: "white",
        }
      }} />} label={res4Pre1} sx={{paddingRight: "20px"}} /> }
       
     </RadioGroup>
     {resPre1 === currentResPre1 ?  <Typography sx={{paddingTop: "15px", color: "#a2f2b8", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es correcta</Typography> : 
     <Typography sx={{paddingTop: "15px", color: "#f2a6a2", fontWeight: "500", fontSize: "18px"}} >Tu respuesta es incorrecta</Typography>}
    
   </FormControl>



   {pregunta2TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                {parse(pregunta2TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       value={currentResPre2}
       onChange={handleChangeResPre2}
     >
       {res1Pre2 !== "" && <FormControlLabel value={res1Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre2} sx={{paddingRight: "20px"}} />}
       {res2Pre2 !== "" && <FormControlLabel value={res2Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre2} sx={{paddingRight: "20px"}}/>}
       {res3Pre2 !== "" && <FormControlLabel value={res3Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre2} sx={{paddingRight: "20px"}}/>}
       {res4Pre2 !== "" && <FormControlLabel value={res4Pre2} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre2}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}




   {pregunta3TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                3. {parse(pregunta3TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre3}
       value={currentResPre3}
       onChange={handleChangeResPre3}
     
     >
       {res1Pre3 !== "" && <FormControlLabel value={res1Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre3} sx={{paddingRight: "20px"}} />}
       {res2Pre3 !== "" && <FormControlLabel value={res2Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre3} sx={{paddingRight: "20px"}}/>}
       {res3Pre3 !== "" && <FormControlLabel value={res3Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre3} sx={{paddingRight: "20px"}}/>}
       {res4Pre3 !== "" && <FormControlLabel value={res4Pre3} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre3}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}



   {pregunta4TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                4. {parse(pregunta4TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre4}
       value={currentResPre4}
       onChange={handleChangeResPre4}
     >
       {res1Pre4 !== "" && <FormControlLabel value={res1Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre4} sx={{paddingRight: "20px"}} />}
       {res2Pre4 !== "" && <FormControlLabel value={res2Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre4} sx={{paddingRight: "20px"}}/>}
       {res3Pre4 !== "" && <FormControlLabel value={res3Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre4} sx={{paddingRight: "20px"}}/>}
       {res4Pre4 !== "" && <FormControlLabel value={res4Pre4} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre4}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta5TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                5. {parse(pregunta5TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre5}
       value={currentResPre5}
       onChange={handleChangeResPre5}
     >
       {res1Pre5 !== "" && <FormControlLabel value={res1Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre5} sx={{paddingRight: "20px"}} />}
       {res2Pre5 !== "" && <FormControlLabel value={res2Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre5} sx={{paddingRight: "20px"}}/>}
       {res3Pre5 !== "" && <FormControlLabel value={res3Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre5} sx={{paddingRight: "20px"}}/>}
       {res4Pre5 !== "" && <FormControlLabel value={res4Pre5} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre5}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta6TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
               6. {parse(pregunta6TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       value={currentResPre6}
       defaultValue={resPre6}
       onChange={handleChangeResPre6}
       
     >
       {res1Pre6 !== "" && <FormControlLabel value={res1Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre6} sx={{paddingRight: "20px"}} />}
       {res2Pre6 !== "" && <FormControlLabel value={res2Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre6} sx={{paddingRight: "20px"}}/>}
       {res3Pre6 !== "" && <FormControlLabel value={res3Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre6} sx={{paddingRight: "20px"}}/>}
       {res4Pre6 !== "" && <FormControlLabel value={res4Pre6} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre6}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta7TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                7. {parse(pregunta7TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre7}
       value={currentResPre7}
       onChange={handleChangeResPre7}
     >
       {res1Pre7 !== "" && <FormControlLabel value={res1Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre7} sx={{paddingRight: "20px"}} />}
       {res2Pre7 !== "" && <FormControlLabel value={res2Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre7} sx={{paddingRight: "20px"}}/>}
       {res3Pre7 !== "" && <FormControlLabel value={res3Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre7} sx={{paddingRight: "20px"}}/>}
       {res4Pre7 !== "" && <FormControlLabel value={res4Pre7} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre7}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta8TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                8. {parse(pregunta8TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre8}
       value={currentResPre8}
       onChange={handleChangeResPre8}
     >
       {res1Pre8 !== "" && <FormControlLabel value={res1Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre8} sx={{paddingRight: "20px"}} />}
       {res2Pre8 !== "" && <FormControlLabel value={res2Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre8} sx={{paddingRight: "20px"}}/>}
       {res3Pre8 !== "" && <FormControlLabel value={res3Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre8} sx={{paddingRight: "20px"}}/>}
       {res4Pre8 !== "" && <FormControlLabel value={res4Pre8} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre8}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta9TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                9. {parse(pregunta9TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre9}
       value={currentResPre9}
       onChange={handleChangeResPre9}
     >
       {res1Pre9 !== "" && <FormControlLabel value={res1Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre9} sx={{paddingRight: "20px"}} />}
       {res2Pre9 !== "" && <FormControlLabel value={res2Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre9} sx={{paddingRight: "20px"}}/>}
       {res3Pre9 !== "" && <FormControlLabel value={res3Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre9} sx={{paddingRight: "20px"}}/>}
       {res4Pre9 !== "" && <FormControlLabel value={res4Pre9} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre9}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}


   {pregunta10TestIntro !== "" && <div> <Divider sx={{bgcolor: "white"}}></Divider>
               <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "500", paddingTop: "30px", textAlign: "justified", margingTop: "20px" }}>
                10. {parse(pregunta5TestIntro)}
               </Typography>
               <FormControl sx={{color: "white", paddingTop: "0px", paddingBottom: "30px", borderColor: "white"}}>
     <RadioGroup
       row
       aria-labelledby="demo-row-radio-buttons-group-label"
       name="row-radio-buttons-group"
       defaultValue={resPre10}
       value={currentResPre10}
       onChange={handleChangeResPre10}
     >
       {res1Pre10 !== "" && <FormControlLabel value={res1Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         }
       }} />} label={res1Pre10} sx={{paddingRight: "20px"}} />}
       {res2Pre10 !== "" && <FormControlLabel value={res2Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res2Pre10} sx={{paddingRight: "20px"}}/>}
       {res3Pre10 !== "" && <FormControlLabel value={res3Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res3Pre10} sx={{paddingRight: "20px"}}/>}
       {res4Pre10 !== "" && <FormControlLabel value={res4Pre10} control={<Radio  sx={{
         color: "white",
         '&.Mui-checked': {
           color: "white",
         },
       }}/>} label={res4Pre10}  sx={{paddingRight: "20px"}}/>}
       
     </RadioGroup>
   </FormControl></div>}
       <Stack sx={{paddingBottom: "20px", alignItems: "flex-end", paddingRight: "50px"}}>
       {(alumnos.map((alumno) => (
  <Test key={alumno.docId} master={alumno.master} status={alumno.status} docId={alumno.docId} paginar={paginar} diaposCompletadas={alumno.diaposCompletadas} respuestas={alumno.respuestas} activeStep={activeStep} numSteps={numSteps} step2={step2} thisIndex={thisIndex} ordered={ordered} tipoDiapo={tipoDiapo} onUpdate={handleUpdate2} />
  )))}
   </Stack>


  </Stack>
       
         
         

  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "casopractico" && step2 !== "" && tipoDiapo !== "testintroductorio" && <Button onClick={handleUpdateLink} sx={{color: "white" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio" && tipoDiapo !== "casopractico" && step2 === "" && <Button onClick={handleUpdateLink} sx={{color: "white" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "teoria" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio"  && step2 === "" && <Button onClick={handleUpdateLink} variant='contained' sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px", borderColor: "#d5dbe8", bgcolor:"#d5dbe8"}}>
                      
                      Presentar caso práctico
                  </Button>
                  
                  }

                  

                  {(tipoDiapo === "portada" || tipoDiapo === "portadatema" || tipoDiapo === "portadacapitulo" ||  tipoDiapo === "testintroductorio" || tipoDiapo === "respuestaguiada" || tipoDiapo === "preposttest") && 

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                
                <Box sx={{ flex: '1 1 auto' }} />
                {respuestasChecked === false && <Button onClick={handleRespuestas} sx={{color: "white"}}>Enviar respuestas</Button>}
                {(respuestasChecked !== true && testYaCompletado !== true) && <Button onClick={handleRespuestas} sx={{color: "white"}}>Enviar respuestas</Button>}
                 <Stack direction="row" >{(respuestasChecked === true || testYaCompletado === true) && <div> {respuestasChecked !== true && <div> <Typography sx={{color: "#4a5b82",bgcolor: "white", paddingLeft: "10px", paddingBottom: "10px", paddingTop: "10px", paddingRight: "10px", borderRadius: "15px", marginBottom: "15px"}}> 
                Ya has realizado este test, tus nuevas respuestas no se guardarán</Typography> </div> }
                {respuestasChecked !== true && <Button onClick={handleRespuestasBis} sx={{color: "white"}}>Comprobar respuestas</Button>}
                <Button onClick={handleUpdateLink} sx={{color: "white"}}>Siguiente</Button></div>}
                </Stack>
                
              </Box> }
     
            </Stack>}

















    </div>
  )
}

export default Test