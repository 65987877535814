import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import Image from 'mui-image';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { signOut } from 'firebase/auth';



const pages = ['Home', 'Jobs', 'SingleJob'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Footer = ({isAuth}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [state, setState] = React.useState({
    top: false,
  });


  const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const auth = getAuth();

    function handleSignUp(e) {
        e.preventDefault();
        console.log("Dale!")

        createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log("Estamos dentro bro")
    console.log(user)
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });

    }

    function handleLogOut(){
        signOut(auth).then(()=> {
            console.log("Yeeees")
        }).catch((error) => {
    
        } )
    }



  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      
          <ListItem  disablePadding>
            <ListItemButton  href="./" >
          
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./alljobs" >
          
              <ListItemText primary="Empleos" />
            </ListItemButton>
          </ListItem>
          <ListItem  disablePadding>
            <ListItemButton  href="./perfilprofesional" >
          
              <ListItemText primary="Perfil Profesional" />
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
      
    </Box>
  );

 
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickMenu = () => {

  };

  
console.log(currentUser)
  return (
    <AppBar position="static" style={{ background: 'white'}}>
      <Container maxWidth="xl" sx={{display: { xl: 'block', l: "block", m: "none", s:"none", xs: 'none' }}} >
        
        <Toolbar disableGutters >



        <Grid container componente="main" spacing={0} sx={{justifyContent: "center"}}>
      <Grid item xs={1} sm={1} md={1} square>
      </Grid>
      <Grid item xs={3} sm={3} md={3} square sx={{justifyContent: "center"}}>
      <Typography sx={{color: '#004AAD', fontSize: "14px", paddingTop: "23px", textAlign: "center"}} >(c) Univerlaw 2024. Todos los derechos reservados.  </Typography>
      </Grid>

      <Grid item xs={3} sm={3} md={3} square sx={{justifyContent: "center"}}>
      <Button
                onClick={handleCloseNavMenu}
                href="./avisolegal"
                sx={{ my: 2, color: '#004AAD', display: 'block', textAlign: "center"}}
              >
                Aviso legal
              </Button>
      </Grid>

      <Grid item xs={3} sm={3} md={3} square>
      <Button
                onClick={handleCloseNavMenu}
                href="./politicadeprivacidad"
                sx={{ my: 2, color: '#004AAD', display: 'block', textAlign: "center"  }}
              >
                Política de privacidad
              </Button>
      </Grid>
      <Grid item xs={1} sm={1} md={1} square>
      </Grid>

      </Grid>


  
          
        </Toolbar>
      </Container>

      <Container maxWidth="xl" sx={{display: { xl: 'none', l: "none", m: "block", s:"block", xs: 'block' }}} >
        
        <Toolbar disableGutters >




        <Stack direction="column" sx={{justifyContent: "center"}} >

      <Typography sx={{color: '#004AAD', fontSize: "14px", paddingTop: "15px", textAlign: "center"}}>(c) Univerlaw 2024. Todos los derechos reservados.  </Typography>
  
     
  



 
     <Stack direction="row" sx={{justifyContent: "center"}}>
   
      <Button
                onClick={handleCloseNavMenu}
                href="./avisolegal"
                sx={{ color: '#004AAD', display: 'block'  }}
              >
                Aviso legal
              </Button>


     
 
     
   
 <Button
           onClick={handleCloseNavMenu}
           href="./politicadeprivacidad"
           sx={{ color: '#004AAD', display: 'block'  }}
         >
           Política de privacidad
         </Button>
         </Stack>
         </Stack>
  
          
        </Toolbar>
      </Container>

    </AppBar>
  );
};
export default Footer;