import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import { CssBaseline, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ForumIcon from '@mui/icons-material/Forum';
import Image from 'mui-image';
import userEvent from '@testing-library/user-event';
import AuthProvider from '../../components/authProvider';
import HeaderLoggedIn from '../../components/HeaderLoggedIn';
import Link from '@mui/material/Link';
import Footer from '../../components/Footer';

const steps = ['Introducción', 'Sentencias recurribles', 'Autos recurribles'];

const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };

function CuandoComienzo() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [currentUser, setCurrentUser] = useState({});

    function handleUserLoggedIn(user){
      setCurrentUser(user)
      console.log("logged in")
  }
  
  function handleUserNotLoggedIn(user){
      console.log("Not logged in")
  }
  
  function handleUserNotRegistered(user){

      console.log("Not registered")
  }
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };
  
    return (
      <div>
            <AuthProvider onUserLoggedIn={handleUserLoggedIn} 
    onUserNotRegistered={handleUserNotRegistered}
    onUserNotLoggedIn={handleUserNotLoggedIn}>
         <ThemeProvider theme={theme}>
      <CssBaseline>   
      {Object.keys(currentUser).length === 0 ? <Header /> : <HeaderLoggedIn />}
         
      <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >
         
          <Grid xs={12} sx={{}}>
            <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center top", backgroundSize: "cover" , justifyContent: "center"}}>
            <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>
   
   <Typography variant="h2" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Law School | Derecho de los negocios


       </Typography>
   <Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
   Formación especializada diseñada para el éxito en la abogacía de élite


       </Typography>
      
   </Stack>
                        </Grid>
                        
                        <Grid item xs={2} sm={2} md={2}>
                        
                        </Grid>
                    </Grid>
            
                  <Stack sx={{minHeight: "70px", opacity: "0.95"}}>
                    <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                    <Grid container sx= {{minHeight: "70px", paddingTop: "1px", paddingBottom: "1px", opacity: "0.95"}}>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./nuestrosmasteres"> Másteres</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./cursos"> Cursos</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="https://www.jobsandlaw.com"> Empleo</Link>
                        </Typography>
                      
                      
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "20px"}}>
                        <Typography variant="button" sx={{color: "white", fontSize: "15px"}} >
                        <Link sx={{color: "white", fontSize: "15px"}} href="./sobrenosotros"> Sobre nosotros</Link>
                        </Typography>
                      
                      
                      </Grid>
                       <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "70px", bgcolor: "#4a5b82", paddingTop: "10px"}}>
                      
                      </Grid>
                      </Grid>


                  </Stack>
                  <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                  <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>
                  <Stack sx= {{minHeight: "525px", textAlign: "justify"}}>

                    <Grid container>
                      <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                      <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", textAlign: "center"}}>
            ¿Cuándo comienzo a buscar mis prácticas del máster?
  
  
                      </Typography>

                      <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "30px", paddingLeft: "0px", paddingRight: "80px", textAlign: "center"}}>
                      En octubre de cada año los estudiantes apenas habéis empezado el máster de acceso a la abogacía y de repente, cuando todavía estáis conociendo a los nuevos colegas, os encontráis en medio de una carrera frenética por cerrar las prácticas del máster. Sí, ¡esas prácticas que empieza un año más tarde!
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "60px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                  Los despachos cada vez adelantan más el inicio de sus procesos de selección, y entre octubre y noviembre la gran mayoría ya están en marcha. Aunque a muchos os pueda parecer demasiado pronto para pensar en las prácticas, lo cierto es que los tiempos los marcan los despachos, y te tienes que adaptar a ellos. Vas a tener que afrontar el tema de las prácticas tarde o temprano, y créenos, cuanto más tarde lo hagas más agobiante será la experiencia y menos opciones tendrás.
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                
                      Lo más recomendable es tener una idea lo más clara posible sobre el tipo de despacho en el que te gustaría hacer tus prácticas del máster de acceso, y estar muy atento a la apertura de los procesos de selección de tus despachos favoritos. Hoy en día lo tienes fácil, solo tienes que estar atento a nuestro portal de prácticas y a nuestras redes sociales (o a Jobs&Law en caso de que no quieres perderte ninguna oportunidad), y allí te informaremos de cuándo abre su proceso de selección cada uno de los principales despachos de abogados. ¡Aplica tan pronto como se abran los procesos!

  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
            
                      Ten en cuenta que en el mes de enero un amplio porcentaje de las posiciones de prácticas ya están cubiertas. A partir de ese momento tu abanico de posibilidades puede quedar reducido a aquellos grandes despachos que ofrecen un mayor número de posiciones de prácticas -y suelen tardar más en completarlas-, a las posiciones para áreas muy concretas que queden por completar en el resto de principales despachos, y a aquellos despachos que retrasan su proceso de selección o que tienen más dificultades para completar sus plazas. Puede que tengas suerte y la plaza perfecta para ti todavía esté libre en febrero, pero no ganas nada arriesgándote.
                                      </Typography>


                                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                      El periodo que transcurre entre que comienzas a buscar despacho para hacer las prácticas del máster de acceso y el momento en el que las consigues cerrar es bastante agobiante para gran parte de los alumnos. El proceso es competitivo, sufrirás irremediablemente algún rechazo, y en general estarás deseando aceptar una buena oferta y olvidarte de los procesos de selección. Si te anticipas y empiezas lo antes posible no eliminarás la presión, pero al menos tendrás mucho más margen y, sobre todo, no habrás dejado escapar oportunidades.
              
                                  </Typography>


                                

                  </Stack>
                        </Grid>
       
                        <Grid item xs={2} sm={2} md={2} sx= {{minHeight: "525px", bgcolor: "white", opacity: "0.9"}}>

</Grid>
                    </Grid>
   
                 
      
   </Stack>

   <Stack sx={{height: "70px", bgcolor: "white", opacity: "0.9"}}></Stack>





<Grid xs={12} sx={{bgcolor: "white", paddingBottom: "30px"}}>


<Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Adquiere los conocimientos necesarios para acceder a los mejores programas de prácticas


                   </Typography>

                   <Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", paddingBottom: "50px", textAlign: "center", paddingLeft: "20%", paddingRight: "20%"}}>
               Nuestros másteres


                   </Typography>

       </Grid>
                 
                  </Item>
          </Grid>
          </Box>
 





 
          <Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >
         
         <Grid xs={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
           <Item sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
 <Grid item xs={12} sm={12} md={12} sx={{bgcolor: "#4a5b82", backgroundImage: `url(${"/images/edificio.jpg"})`, backgroundPosition: "center center", backgroundSize: "cover" , justifyContent: "center"}}>
   <Stack sx= {{height: "220px", textAlign: "left", padding: "20px"}}>

<Typography variant="h5" sx={{color: "white", fontFamily: "Lato", fontWeight: "600", paddingTop: "25px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Law School | Derecho de los negocios


</Typography>
<Typography sx={{color: "white", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "20px", paddingBottom: "20px", textShadow: '4px 8px 8px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)'}}>
Formación especializada diseñada para el éxito en la abogacía de élite


</Typography>

</Stack>
   </Grid>
           
       
                 <Stack sx={{height: "1px", bgcolor: "white"}}></Stack>
                 <Stack sx={{height: "50px", bgcolor: "#4a5b82", opacity: "0.9"}}></Stack>
                 <Stack sx= {{textAlign: "justify"}}>

                   <Grid container>
          
                     <Grid item xs={12} sm={12} md={12}>

                     <Stack sx= {{minHeight: "525px", textAlign: "justify", bgcolor: "white", opacity: "0.9", justifyContent: "center", alignContent: "center", alignItems: "center", paddingLeft: "30px", paddingRight: "30px"}}>
            <Typography variant="h3" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "50px", paddingLeft: "0px", textAlign: "center"}}>
            ¿Cuándo comienzo a buscar mis prácticas del máster?
  
  
                      </Typography>

                      <Typography variant="h5" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "30px", paddingLeft: "0px", paddingRight: "80px", textAlign: "center"}}>
                      En octubre de cada año los estudiantes apenas habéis empezado el máster de acceso a la abogacía y de repente, cuando todavía estáis conociendo a los nuevos colegas, os encontráis en medio de una carrera frenética por cerrar las prácticas del máster. Sí, ¡esas prácticas que empieza un año más tarde!
  
                      </Typography>
                  <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "60px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                  Los despachos cada vez adelantan más el inicio de sus procesos de selección, y entre octubre y noviembre la gran mayoría ya están en marcha. Aunque a muchos os pueda parecer demasiado pronto para pensar en las prácticas, lo cierto es que los tiempos los marcan los despachos, y te tienes que adaptar a ellos. Vas a tener que afrontar el tema de las prácticas tarde o temprano, y créenos, cuanto más tarde lo hagas más agobiante será la experiencia y menos opciones tendrás.
                      </Typography>

                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                
                      Lo más recomendable es tener una idea lo más clara posible sobre el tipo de despacho en el que te gustaría hacer tus prácticas del máster de acceso, y estar muy atento a la apertura de los procesos de selección de tus despachos favoritos. Hoy en día lo tienes fácil, solo tienes que estar atento a nuestro portal de prácticas y a nuestras redes sociales (o a Jobs&Law en caso de que no quieres perderte ninguna oportunidad), y allí te informaremos de cuándo abre su proceso de selección cada uno de los principales despachos de abogados. ¡Aplica tan pronto como se abran los procesos!

  
  
                      </Typography>


                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
            
                      Ten en cuenta que en el mes de enero un amplio porcentaje de las posiciones de prácticas ya están cubiertas. A partir de ese momento tu abanico de posibilidades puede quedar reducido a aquellos grandes despachos que ofrecen un mayor número de posiciones de prácticas -y suelen tardar más en completarlas-, a las posiciones para áreas muy concretas que queden por completar en el resto de principales despachos, y a aquellos despachos que retrasan su proceso de selección o que tienen más dificultades para completar sus plazas. Puede que tengas suerte y la plaza perfecta para ti todavía esté libre en febrero, pero no ganas nada arriesgándote.
                                      </Typography>


                                      <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "5px", paddingBottom: "20px", paddingLeft: "0px", textAlign: "center"}}>
                                      El periodo que transcurre entre que comienzas a buscar despacho para hacer las prácticas del máster de acceso y el momento en el que las consigues cerrar es bastante agobiante para gran parte de los alumnos. El proceso es competitivo, sufrirás irremediablemente algún rechazo, y en general estarás deseando aceptar una buena oferta y olvidarte de los procesos de selección. Si te anticipas y empiezas lo antes posible no eliminarás la presión, pero al menos tendrás mucho más margen y, sobre todo, no habrás dejado escapar oportunidades.
              
                                  </Typography>


                                

                  </Stack>

         


           
   

                       </Grid>
                 
                   </Grid>
  
                
     
  </Stack>







                
                 </Item>
         </Grid>
         </Box> 
          













         <Box sx={{display: { xl: 'block', l: "block", m: "block", sm:"none", xs: 'none' }}} >

<Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
       
                        
       
 
  

    <Item sx={{marginRight: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "390px", maxHeight: "600px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

      <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

                        </Stack>

                        <Stack direction="row" sx={{height: '100%', bgcolor: "white", paddingLeft: "10%", paddingRight: "10%", alignContent: "center", justifyContent: "center"}}>
     
    
  

    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{marginRight: "20px", margin: "20px", bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", justifyContent: "center", maxWidth: "390px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  
 


                        </Stack>

    
  
  
          <Grid container sx={{height: '100%'}}>
       
           
            
          </Grid>






</Box>











<Box sx={{display: { xl: 'none', l: "none", m: "none", sm:"block", xs: 'block' }}} >


     <Stack sx={{justifyContent: "center", alignContent: "center", textAlign: "center", alignItems: "center", paddingBottom: "50px"}} > 
<Typography variant="h4" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "95px", paddingBottom: "50px", textAlign: "center"}}>
              Nuestros másteres


                  </Typography>
       
     
   
  
 
    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Procesal Civil
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "30px"}}>
          Defiende a empresas multinacionales en sus litigios más complejos
  
  
              </Typography>
              <Button variant="contained" href="./presentacionmasterderechoprocesal" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Saber más
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "52px"}}>
          Matrícula abierta
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 


    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Derecho Concursal
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "50px"}}>
          Asesora a deudores y acreedores en escenarios de insolvencia
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 3 junio 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

  
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Tech Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "70px", paddingBottom: "50px"}}>
          Domina el derecho de las nuevas tecnologías y asesora a las BigTech
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>
 
  

    <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> White Collar Law
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Conviértete en un abogado penalista experto especializado en delitos económicos
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>

  

      <Item sx={{bgcolor: "#4a5b82", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", margin: "20px", justifyContent: "center", maxWidth: "500px"}}>
    <Stack sx= {{ bgcolor: "#4a5b82", textAlign: "justify", borderRadius: "5px", padding: "20px", textAlign: "center"}}>
   
    
          <Typography variant="h4" sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "35px"}}>
          Máster en <br/> Fiscalidad de las Empresas
  
  
              </Typography>
          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", fontSize: "20px", paddingTop: "30px", paddingBottom: "20px"}}>
          Asesora a grandes multinacionales en sus cuestiones fiscales clave
  
  
              </Typography>
              <Button variant="contained" sx={{color: "#4a5b82", paddingTop: "15px", paddingBottom: "15px", bgcolor: "white", marginTop: "20px", fontSize: "14px",':hover': {
      bgcolor: '#6b81b5', // theme.palette.primary.main
      color: 'white',
    }}}>
                Próximamente
              </Button>
              
              
          </Stack>

          <Typography sx={{color: "#d5dbe8", fontFamily: "Lato", fontWeight: "600", paddingTop: "15px", fontSize: "18px", paddingBottom: "25px"}}>
          Nuevas plazas limitadas: <br/> 2 septiembre 2024
  
  
              </Typography>
  
  
  
  
      
  
    { /* <Stack sx={{paddingTop: "30px", paddingBottom: "10px", alignItems: "center", bgcolor: "#dcdee3"}}>
      <Card sx={{width: "80%", bgcolor: "#feff9c", height: "400px"}}>
          <CardContent>
          <Typography variant="overline" sx={{fontWeight: "bold", color: "#2d3030", fontSize: "15px", fontFamily: "Lato", bgcolor: "white", padding: "10px", borderRadius: "5px", border: "1px", borderColor: "black"}}>
              Tus notas
          </Typography>
          </CardContent>
      </Card>
      </Stack>*/}
      </Item>


         </Stack>       
  
    
  
  

      
      


           



 



 


</Box>




<Footer/>






        </CssBaseline>
      </ThemeProvider>
      </AuthProvider>
      </div>
    )
}

export default CuandoComienzo