import React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';

function Alumno({docId, master, status, paginar, diaposCompletadas, respuestas, resAlumnoPre4, activeStep, numSteps, step2, thisIndex, ordered, tipoDiapo, textoAlumno, onDelete, onUpdate}) {
  const [status2, setCurrentStatus2] = useState(status)
  const [currentDiaposCompletadas, setCurrentDiaposCompletadas] = useState(diaposCompletadas)
  const [currentRespuestas, setCurrentRespuestas] = useState({})
  const [currentResAlumnoPre4, setCurrentResAlumnoPre4] = useState("")
  
 
  const navigate = useNavigate();

console.log(currentDiaposCompletadas)
console.log(currentResAlumnoPre4)
console.log(resAlumnoPre4)
console.log(ordered)

useEffect(() => {
  if (diaposCompletadas.includes(paginar)) {
    setCurrentDiaposCompletadas([...diaposCompletadas])
  } else {
      setCurrentDiaposCompletadas([...diaposCompletadas, paginar])
  }

  setCurrentRespuestas([...respuestas])


}, [])

    function handleUpdateLink () {

      onUpdate(docId, master, status2, currentDiaposCompletadas, currentRespuestas, resAlumnoPre4, textoAlumno)
      setTimeout(() => {
        nextSlide();
      }, 500);
      
    }

    const nextSlide = () => {
    
      console.log(thisIndex)
      const nextIndex = thisIndex+1
      const chosenOne =  ordered[nextIndex]
      console.log(nextIndex)
      console.log(chosenOne)
      console.log(chosenOne.docId)
      console.log(currentDiaposCompletadas)
      console.log(resAlumnoPre4)
    
      navigate(`../masterderechoprocesal/${chosenOne.docId}`);
      window.location.replace('');
    }



  return (
    <div>

                
                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "casopractico" && step2 !== "" && tipoDiapo !== "testintroductorio" && <Button onClick={handleUpdateLink} sx={{color: "white" }} disabled={activeStep !== numSteps - 1}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio" && tipoDiapo !== "casopractico" && step2 === "" && <Button onClick={handleUpdateLink} sx={{color: "white" }}>
                      
                      ¡Lo tengo claro!
                  </Button>}

                  {tipoDiapo !== "portada" && tipoDiapo !== "portadatema" && tipoDiapo !== "teoria" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "portadacapitulo" && tipoDiapo !== "respuestaguiada" && tipoDiapo !== "preposttest" && tipoDiapo !== "testintroductorio"  && step2 === "" && <Button onClick={handleUpdateLink} variant='contained' sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", fontSize: "16px", paddingTop: "20px", paddingBottom: "20px", lineHeight: "30px", borderColor: "#d5dbe8", bgcolor:"#d5dbe8"}}>
                      
                      Presentar caso práctico
                  </Button>
                  
                  }

                  

                  {(tipoDiapo === "portada" || tipoDiapo === "portadatema" || tipoDiapo === "portadacapitulo" ||  tipoDiapo === "testintroductorio" || tipoDiapo === "respuestaguiada" || tipoDiapo === "preposttest") && 

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleUpdateLink} sx={{color: "white"}}>Siguiente</Button>
                
              </Box> }



    </div>
  )
}

export default Alumno
