import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import GoogleIcon from '@mui/icons-material/Google';
import { Typography } from '@mui/material';

function InicioSesionDef() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const auth = getAuth();

    function handleSignIn(e) {
        e.preventDefault();
        console.log("Dale!")


        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          window.location.href = window.location.href;
          console.log("1")
          window.location.href = window.location.href;
          console.log("2")
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });


    }

    async function handleOnClickGoogle(){
      const googleProvider = new GoogleAuthProvider();
      await singInWithGoogle(googleProvider)
      console.log("clic")
  }

 async  function singInWithGoogle(googleProvider){
      try{
          const res = await signInWithPopup(auth, googleProvider)
          console.log(res)
      } catch (error) {
          console.log(error);
      }
  }

  return (
    <div>
              <Box component="form" sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "30px", marginBottom: "0px", borderRadius: "25px", padding: "20px", maxWidth: "450px", alignItems: "center", textAlign: "center" }}>
             
      <Button variant="contained" startIcon={<GoogleIcon />} onClick={handleOnClickGoogle} sx={{backgroundColor: "#4285F4"}} >Inicia sesión con Google</Button>
      </Box>
        <Box component="form" noValidate onSubmit={handleSignIn} sx={{mt: 1, color: "white", bgcolor: "rgba(228, 237, 246, 0.17)", marginTop: "0px", marginBottom: "30px", borderRadius: "25px", paddingLeft: "20px", paddingRight: "20px", maxWidth: "450px" }}>
        <Typography sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "0px", textAlign: "center"}}>ó</Typography>
       <Typography variant="h6" sx={{color: "#4a5b82", fontFamily: "Lato", fontWeight: "600", paddingTop: "10px", textAlign: "center"}}>Inicia sesión con tu correo electrónico</Typography>
       <TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
          
                onChange={(e) => setEmail(e.target.value)}
              />

<TextField   
                sx={{input: {color: "black", backgroundColor: "whitesmoke", borderRadius: "5px"}, border: "green", color: "green"}}
                margin="normal"
                required
                fullWidth
                id="password"
                label="Contraseña"
                name="password"
                autoComplete="password"
           
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#4a5b82", color: "white"}}

              >Iniciar sesión</Button>
              </Box>
    </div>
  )
}

export default InicioSesionDef